import { defineStyleConfig } from '@chakra-ui/react'

export const Heading = defineStyleConfig({
    variants: {
        primary: {
            color: 'brand.500',
        },
        basic: {
            fontWeight: 800,
            color: 'dark',
        },
        gradient: {
            fontWeight: 900,
            bgGradient:
                'linear-gradient(90deg, rgba(36,166,130,1) 0%, rgba(0,237,145,1) 100%)',
            bgClip: 'text',
        },
    },
    defaultProps: {
        variant: 'basic',
    },
})
