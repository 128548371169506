import React from 'react'
import SettingSwitch from '../form/setting-switch'
import { useSyncSettingsContext } from './sync-settings-context'

export default function NewClientAddressSwitch() {
    const { shouldSyncReminders, setShouldSyncReminders } =
        useSyncSettingsContext()

    return (
        <SettingSwitch
            label="Sync PIMS reminders?"
            isChecked={shouldSyncReminders}
            onChange={e => setShouldSyncReminders(e.target.checked)}
            requiresAdmin
        />
    )
}
