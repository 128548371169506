import React from 'react'
import { Box, BoxProps } from '@chakra-ui/layout'

interface Props extends BoxProps {
    dir?: DIRECTION
}

export default function GradientDivider({ dir = 'center', ...props }: Props) {
    const gradient = getGradientDirection(dir)

    return <Box h="1px" w="full" bgGradient={gradient} {...props} />
}

function getGradientDirection(dir: DIRECTION): string {
    if (dir === 'right') {
        return 'linear(to-r, gray.200, gray.50)'
    }

    if (dir === 'left') {
        return 'linear(to-l, gray.200, gray.50)'
    }

    return 'linear(to-r, gray.50, gray.200, gray.50)'
}

export type DIRECTION = 'right' | 'left' | 'center'
