import React from 'react'
import MultiItemSelect from '~components/shared/form/multi-item-select'
import { NotifyClinicOn } from '~graphql/generated/graphql'
import { getCleanEnum } from '~utils/helpers'

import { useMiscSettingsContext } from './misc-settings-context'

export default function NotifyClinicOnMultiSelect() {
    const { notifyClinicOn, setNotifyClinicOn } = useMiscSettingsContext()

    return (
        <MultiItemSelect
            options={Object.values(NotifyClinicOn)}
            items={notifyClinicOn}
            setItems={setNotifyClinicOn}
            label="Notify clinic via email on:"
            textModification={item => {
                switch (item) {
                    case NotifyClinicOn.ApptConfirmed:
                        return 'Appointment confirmed via portal'
                    case NotifyClinicOn.ChckupNewAppt:
                        return 'New online booking'
                    case NotifyClinicOn.NewMessage:
                        return 'New message from client'
                    case NotifyClinicOn.Reschedule:
                        return 'Reschedule via portal'
                    case NotifyClinicOn.Cancellation:
                        return 'Cancellation via portal'
                    default:
                        return getCleanEnum(item)
                }
            }}
            listContainerProps={{ flexDir: 'column' }}
        />
    )
}
