import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function CowIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M56.25 22.5H27L21.375 16.875C21.375 16.875 22.5 13.5 21.375 10.125C21.375 10.125 16.875 13.5 12.375 13.5L6.75 19.125L2.10938 21.375V24.75C2.10938 27.2353 4.12411 29.25 6.60937 29.25H11.25C15.75 33.75 15.75 39.375 15.75 40.5C15.75 45 20.25 48.375 20.25 48.375V61.875H29.25V51.75L30.375 46.125C30.375 46.125 34.875 47.25 40.5 47.25C46.125 47.25 50.625 46.125 50.625 46.125L52.875 51.75V61.875H61.875V28.125C61.875 25.0185 59.3565 22.5 56.25 22.5Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.25 22.5H60.8906C65.8612 22.5 69.8906 26.5295 69.8906 31.5V38.25"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M53.9738 22.5C53.9738 22.5 54.7958 33.6634 47.2238 31.5C39.3488 29.25 41.5988 33.75 37.0988 33.75C31.4738 33.75 30.3488 22.5 30.3488 22.5"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.9349 46.0415C21.1591 45.5329 23.625 42.7423 23.625 39.375C23.625 34.4602 18.5376 31.214 14.0959 33.2241"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M60.8906 20.3909C56.6481 20.3909 56.2352 20.3902 56.0876 20.3902C56.1593 21.6221 56.3252 27.6725 52.9235 30.4402C51.7728 31.3765 49.7495 32.3065 46.6443 31.4191C44.479 30.8005 43.4139 30.8156 42.9015 30.9371C42.5077 31.0306 42.3187 31.2041 41.8303 31.6925C41.0109 32.5119 39.7725 33.7503 37.0988 33.7503C34.8123 33.7503 31.6593 32.5294 29.6235 26.7127C28.7312 24.1636 28.2306 21.0021 28.2407 20.355L23.7042 16.2207C23.9971 14.7073 24.2563 12.0984 23.3761 9.45744C22.9244 8.10266 21.2693 7.57363 20.1125 8.43468C20.0728 8.46421 16.0979 11.3902 12.375 11.3902C11.8155 11.3902 11.2791 11.6125 10.8835 12.0081L5.50983 17.3817L1.18913 19.4766C0.461813 19.8291 0 20.5663 0 21.3746V22.6402C0 26.2846 2.96494 29.2496 6.60938 29.2496H11.7428C12.2926 29.8777 12.9556 30.6579 13.3616 31.5899C19.0419 29.8911 24.75 34.1755 24.75 40.0784C24.75 43.724 22.5195 46.9824 19.1485 48.3173C19.5701 48.7287 19.9527 49.0756 20.25 49.348V61.8746C20.25 63.0395 21.1944 63.984 22.3594 63.984H29.25C30.4149 63.984 31.3594 63.0395 31.3594 61.8746V51.9584L32.0286 48.6122C33.9616 48.9504 38.4232 49.3588 41.9062 49.3588C45.6085 49.3588 49.5308 48.8974 51.4325 48.5492L52.875 52.1557V61.8744C52.875 63.0394 53.8194 63.9838 54.9844 63.9838H61.875C63.0399 63.9838 63.9844 63.0394 63.9844 61.8744V28.1244C63.9844 27.0385 63.7584 26.0046 63.3525 25.066C65.9395 26.0592 67.7812 28.568 67.7812 31.5003V38.2503C67.7812 39.4152 68.7257 40.3597 69.8906 40.3597C71.0556 40.3597 72 39.4152 72 38.2503V31.5003C72 25.3745 67.0164 20.3909 60.8906 20.3909ZM11.8125 22.078C10.6476 22.078 9.70312 21.1335 9.70312 19.9686C9.70312 18.8037 10.6476 17.8592 11.8125 17.8592C12.9774 17.8592 13.9219 18.8037 13.9219 19.9686C13.9219 21.1335 12.9774 22.078 11.8125 22.078ZM46.3795 42.8894C45.2146 42.8894 44.2702 41.9449 44.2702 40.78C44.2702 39.615 45.2146 38.6706 46.3795 38.6706C47.5445 38.6706 48.4889 39.615 48.4889 40.78C48.4889 41.9451 47.5445 42.8894 46.3795 42.8894Z"
            fill="currentColor"
        />
        <path
            d="M20.5312 40.0784C20.5312 36.7529 17.1162 34.5015 14.0626 35.8149C14.681 40.0092 15.6599 42.842 16.5381 44.679C16.566 44.6733 16.5933 44.6659 16.6217 44.6614C18.8872 44.3039 20.5312 42.3766 20.5312 40.0784Z"
            fill="currentColor"
        />
        <path
            d="M37.0988 29.5315C38.0251 29.5315 38.2323 29.3244 38.8474 28.7093C40.4481 27.1086 42.3375 25.801 47.8034 27.3627C49.4432 27.8312 50.0567 27.3334 50.2584 27.1699C51.7424 25.9668 51.9848 22.185 51.8698 20.5421C51.8663 20.491 51.8667 20.4405 51.8669 20.3902H32.4707C32.8206 23.4029 34.3963 29.5315 37.0988 29.5315Z"
            fill="currentColor"
        />
    </Icon>
)
