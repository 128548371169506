import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function DonkeyIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M52.875 30.375H33.75L24.4687 16.875C24.4687 16.875 25.5937 12.9791 25.5937 11.25C25.5937 7.52204 23.579 4.50001 21.0937 4.50001C18.6084 4.50001 16.5937 7.52204 16.5937 11.25V14.625L10.9687 21.375H6.60934C4.0996 21.375 2.07488 23.4281 2.10976 25.9374L2.12537 27.0624C2.15954 29.5231 4.16401 31.5 6.62495 31.5H11.25L19.4062 33.75L24.4687 48.375V67.5H32.625V57.375L34.875 51.75C34.875 51.75 38.25 52.875 42.75 52.875C47.25 52.875 51.75 51.75 51.75 51.75L54 57.375V67.5H61.875V39.375C61.875 34.4043 57.8455 30.375 52.875 30.375Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M61.875 54C68.625 54 69.8906 49.5 69.8906 49.5C69.8906 49.5 68.625 47.25 68.625 45C68.625 42.75 69.8906 41.625 69.8906 39.375C69.8906 34.4043 65.8612 30.375 60.8906 30.375H52.875"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.25 31.5L10.9688 21.375"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M5.62643 19.2614C2.51178 19.2614 -0.0102675 21.7917 7.25937e-07 24.9065L0.0104096 25.9508C0.0528891 29.0021 2.67157 31.4989 5.72334 31.4989C5.6336 18.6785 5.64387 19.2614 5.62643 19.2614Z"
            fill="currentColor"
        />
        <path
            d="M51.4625 28.2637H32.7392L25.4533 17.2966C25.4533 17.2966 26.2843 13.936 26.2843 11.2437C26.2843 6.2746 24.0836 2.3821 20.3765 2.3821C16.6694 2.3821 14.4688 6.2746 14.4688 11.2437V13.8556C9.65438 19.4995 9.8717 19.2614 9.8575 19.2614C9.94991 32.4383 9.92881 31.4989 9.96932 31.4989L17.7609 35.4883L22.3456 48.7329V67.508C22.3456 68.6732 23.2903 69.6179 24.4555 69.6179H30.504C31.6692 69.6179 32.6139 68.6732 32.6139 67.508V57.7868L34.0722 54.1409C40.5065 55.2303 42.8083 55.328 51.1126 54.099L52.5877 57.7868V67.508C52.5877 68.6732 53.5324 69.6179 54.6976 69.6179H60.4647C61.6299 69.6179 62.5746 68.6732 62.5746 67.508V39.3759C62.5747 33.2485 57.5897 28.2637 51.4625 28.2637ZM17.4227 22.9193C16.2574 22.9193 15.3127 21.9746 15.3127 20.8094C15.3127 19.6441 16.2574 18.6994 17.4227 18.6994C18.5879 18.6994 19.5326 19.6441 19.5326 20.8094C19.5326 21.9746 18.5879 22.9193 17.4227 22.9193Z"
            fill="currentColor"
        />
        <path
            d="M71.7313 48.4749C71.4537 47.9735 70.7329 46.3719 70.7329 45.0023C70.7329 43.3546 71.9989 42.0272 71.9989 39.3759C71.9989 33.6528 67.6496 28.9279 62.0831 28.3291C64.9854 31.1205 66.7944 35.0407 66.7944 39.3759V55.2532C70.8646 53.6299 71.8675 50.2616 71.9201 50.0748C72.0719 49.5351 72.0062 48.9634 71.7313 48.4749Z"
            fill="currentColor"
        />
    </Icon>
)
