import React, { forwardRef } from 'react'
import { IconProps, IconButtonProps } from '@chakra-ui/react'
import IconButtonWithTooltip from '../icon-button-with-tooltip'

export interface ActionButtonProps extends Omit<IconButtonProps, 'aria-label'> {
    icon: React.ReactElement<IconProps>
    tooltipLabel: string
    onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ActionButton: React.ForwardRefRenderFunction<
    HTMLButtonElement,
    ActionButtonProps
> = ({ icon, tooltipLabel, onClick, ...props }, ref) => {
    return (
        <IconButtonWithTooltip
            label={tooltipLabel}
            icon={icon}
            ref={ref}
            onClick={onClick}
            variant="ghost"
            {...props}
        />
    )
}

export default forwardRef<HTMLButtonElement, ActionButtonProps>(ActionButton)
