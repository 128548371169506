import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function AmphibianIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M14.9062 14.7656L17.0156 6.32812"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M54.9844 6.32812L57.0938 14.7656"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M65.5312 14.7656L69.8906 6.32812"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M69.8906 65.6719L61.3125 57.2344"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.4969 14.2262C24.1375 18.5975 23.3438 23.8635 23.3438 29.5313C23.3438 32.4547 23.5548 35.5527 23.9459 38.1948L10.7869 41.2019C8.43681 41.7389 7.01383 44.1325 7.66464 46.4535L10.6875 57.2344H19.125L17.0156 49.077C17.0156 49.077 28.368 53.0156 36 53.0156C43.632 53.0156 54.9844 49.077 54.9844 49.077L52.875 57.2344H61.3125L64.3354 46.4535C64.9862 44.1325 63.5632 41.7389 61.2131 41.2019L48.0541 38.1948C48.445 35.5527 48.6563 32.4547 48.6563 29.5313C48.6563 23.8635 47.8626 18.5975 46.5032 14.2262"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M42.9431 6.60046C40.9496 3.76125 38.5638 2.10932 35.9999 2.10932C33.4362 2.10932 31.0503 3.76125 29.0568 6.60046"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.6875 57.2344L2.10938 65.6719"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M57.0938 69.8906L52.875 57.2344"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.10938 6.32812L6.46875 14.7656"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.6875 6.32812V14.7656"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M61.3125 6.32812V14.7656"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.52344 69.8906L15.9609 57.2344"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M64.4766 69.8906L56.0391 57.2344"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M23.3458 27.9189L18.68 30.2523C16.0433 31.5664 12.9802 31.4306 10.4712 29.8836C7.96424 28.3344 6.46869 25.6503 6.46869 22.7046V14.7657H14.9062V22.7046L24.0917 18.1127"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47.9083 18.1127L57.0938 22.7047V14.7656H65.5313V22.7047C65.5313 25.6504 64.0358 28.3345 61.5288 29.8835C60.1672 30.7261 58.6367 31.1503 57.1 31.1503C55.8126 31.1503 54.5209 30.8517 53.32 30.2522L48.6542 27.9189"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.9062 69.8906L19.125 57.2344"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M48.6562 10.5469C48.6562 8.21686 46.7674 6.32812 44.4375 6.32812C42.1076 6.32812 40.2188 8.21686 40.2188 10.5469C40.2188 12.8769 42.1076 14.7656 44.4375 14.7656C46.7674 14.7656 48.6562 12.8769 48.6562 10.5469Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.7812 10.5469C31.7812 8.21686 29.8924 6.32812 27.5625 6.32812C25.2326 6.32812 23.3438 8.21686 23.3438 10.5469C23.3438 12.8769 25.2326 14.7656 27.5625 14.7656C29.8924 14.7656 31.7812 12.8769 31.7812 10.5469Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M25.3125 14.7625C27.6425 14.7625 29.5312 12.8737 29.5312 10.5438C29.5312 8.21384 27.6425 6.32504 25.3125 6.32504C22.9825 6.32504 21.0938 8.21384 21.0938 10.5438C21.0938 12.8737 22.9825 14.7625 25.3125 14.7625Z"
            fill="currentColor"
        />
        <path
            d="M46.6875 14.7625C49.0175 14.7625 50.9062 12.8737 50.9062 10.5438C50.9062 8.21384 49.0175 6.32504 46.6875 6.32504C44.3575 6.32504 42.4688 8.21384 42.4688 10.5438C42.4688 12.8737 44.3575 14.7625 46.6875 14.7625Z"
            fill="currentColor"
        />
        <path
            d="M63.5625 4.21565C62.3966 4.21565 61.4531 5.15911 61.4531 6.32503V14.7625H61.4132C61.4132 14.5927 61.4327 14.4242 61.3893 14.2516L59.2799 5.81414C58.9977 4.68112 57.842 3.987 56.7235 4.2795C55.5926 4.56173 54.9045 5.70698 55.1888 6.83592L59.3438 21.3543C59.344 22.4859 59.3443 22.6789 59.3445 22.7015L52.0754 19.068C52.7041 22.9477 52.9712 26.4862 53.0037 28.9661L55.5698 30.2491C61.1821 33.0504 67.7811 28.9751 67.7811 22.7015L71.7321 7.06654C72.1399 5.97473 71.588 4.75945 70.4983 4.34953C69.4086 3.94368 68.1912 4.48959 67.7813 5.58337L65.8058 14.0209C65.7143 14.2656 65.6868 14.516 65.6871 14.7624H65.6719V6.32489C65.6719 5.15911 64.7284 4.21565 63.5625 4.21565Z"
            fill="currentColor"
        />
        <path
            d="M0.13444 7.06655L4.08544 22.7015C4.08544 28.9751 10.6844 33.0504 16.2968 30.2491L18.9994 28.8979C19.0313 25.577 19.3192 22.2515 19.8378 19.045L12.5149 22.7017C12.5152 22.679 12.5155 22.4861 12.5156 21.3545L16.6777 6.83607C16.9619 5.70727 16.274 4.56188 15.1431 4.27965C14.0245 3.98715 12.8689 4.68127 12.5866 5.81429L10.4759 14.2518C10.4324 14.4243 10.452 14.5927 10.452 14.7627H10.412V6.32518C10.412 5.15926 9.46997 4.2158 8.30405 4.2158C7.13813 4.2158 6.19467 5.15926 6.19467 6.32518V14.7627H6.17949C6.17977 14.5163 6.15221 14.2657 6.0608 14.0212L4.0853 5.58366C3.67538 4.48988 2.45799 3.94398 1.36828 4.34982C0.278581 4.75946 -0.273513 5.97488 0.13444 7.06655Z"
            fill="currentColor"
        />
        <path
            d="M64.216 57.0113L66.5844 48.5568C67.2313 46.2531 65.8526 43.8555 63.4079 43.2957L48.2036 40.247C48.6135 37.4166 48.7969 32.1689 48.7969 29.528C48.7969 27.0019 48.483 23.0608 47.7463 18.8739C47.3955 18.9185 47.05 18.9811 46.6875 18.9811C42.0341 18.9811 38.25 15.197 38.25 10.5436C38.25 7.56641 39.8081 4.95881 42.1425 3.45665C40.5103 1.33729 38.3864 0.00289917 35.9917 0.00289917C33.9272 0.00289917 31.802 1.30916 30.0389 3.5586C32.2767 5.07735 33.75 7.6408 33.75 10.5437C33.75 15.1971 29.9659 18.9812 25.3125 18.9812C24.9097 18.9812 24.5243 18.9171 24.1366 18.8623C23.543 22.2082 23.2031 25.8097 23.2031 29.5281C23.2031 32.1689 23.3865 37.4167 23.7964 40.2471L8.53635 43.3081C6.2052 43.8377 4.76225 46.2123 5.4156 48.5589L7.784 57.0113L0.618033 64.1773C-0.205889 65.0012 -0.205889 66.3361 0.618033 67.1601C1.44195 67.984 2.77691 67.984 3.60083 67.1601L6.13531 64.6256L4.36217 68.7175C3.7153 69.6876 3.977 70.9957 4.94717 71.6426C5.93408 72.297 7.23655 72.0126 7.87231 71.0576L12.6562 60.0176V69.8875C12.6562 71.0524 13.6007 71.9968 14.7656 71.9968C15.9305 71.9968 16.875 71.0524 16.875 69.8875V57.2312C16.875 56.8064 14.9342 51.6068 14.7656 51.1832L14.7801 51.1894C14.8954 51.2285 28.4071 55.1219 36 55.1219C43.5455 55.1219 57.0243 51.2513 57.2344 51.1853C57.0658 51.6092 55.125 56.8071 55.125 57.2312V69.8875C55.125 71.0524 56.0694 71.9968 57.2344 71.9968C58.3993 71.9968 59.3437 71.0524 59.3437 69.8875V60.0176L64.1277 71.0575C64.7634 72.0127 66.0663 72.2962 67.0528 71.6425C68.023 70.9956 68.2847 69.6877 67.6378 68.7173L65.8647 64.6256L68.3991 67.1601C69.2231 67.984 70.558 67.984 71.3819 67.1601C72.2059 66.3361 72.2059 65.0012 71.3819 64.1773L64.216 57.0113Z"
            fill="currentColor"
        />
    </Icon>
)
