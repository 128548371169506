import React from 'react'
import { Tag, TagLabel, TagProps } from '@chakra-ui/tag'

interface Props extends TagProps {
    colorScheme?: string
}

export default function NewTag({ colorScheme = 'pink', ...props }: Props) {
    return (
        <Tag size="xs" colorScheme={colorScheme} rounded="full" {...props}>
            <TagLabel>New</TagLabel>
        </Tag>
    )
}
