import React from 'react'
import { useReviewSettingsContext } from './review-settings-context'
import SaveActionsBtn from '../save-changes-btn'

export default function ReviewSaveActions() {
    const { canSave, isUpdateLoading, handleUpdateSettings } =
        useReviewSettingsContext()

    return (
        <SaveActionsBtn
            canSave={canSave}
            isUpdateLoading={isUpdateLoading}
            handleUpdateSettings={handleUpdateSettings}
        />
    )
}
