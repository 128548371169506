import React from 'react'
import { IconButton } from '@chakra-ui/button'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    ModalOverlayProps,
} from '@chakra-ui/modal'

import { PatientFieldsFragment } from '~graphql/generated/graphql'
import DetailedPatientInfo from './detailed-patient-info'
import { ModalProps } from '@chakra-ui/react'
import LoadingView from '~components/shared/loading-view'

interface Props extends Omit<ModalProps, 'children'> {
    patient?: PatientFieldsFragment
    loading?: boolean
    overlayOverrides?: ModalOverlayProps
}

export default function PatientDetailsDrawer({
    patient,
    isOpen,
    onClose,
    loading,
    overlayOverrides,
    ...props
}: Props) {
    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            placement="right"
            size="lg"
            blockScrollOnMount={false}
            {...props}
        >
            <DrawerOverlay {...overlayOverrides} />
            <DrawerContent>
                <DrawerHeader
                    pb={4}
                    shadow="blue.md"
                    display="flex"
                    justifyContent="space-between"
                >
                    <IconButton
                        size="sm"
                        icon={<ChevronLeftIcon fontSize="xl" />}
                        variant="outline"
                        rounded="full"
                        aria-label="Close Modal"
                        onClick={onClose}
                    />
                </DrawerHeader>
                <DrawerBody px={{ base: 2, md: 6 }} py={{ base: 4, md: 6 }}>
                    {loading ? (
                        <LoadingView />
                    ) : (
                        <DetailedPatientInfo patient={patient} />
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
