import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useRef,
    useCallback,
} from 'react'
import { useClinic } from '~contexts/clinic-context'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text,
    keyframes,
    Box,
    Flex,
    Progress,
} from '@chakra-ui/react'

import { useUserContext } from './user-context'
import { MdAccessTime } from 'react-icons/md'

const DEFAULT_TIMEOUT_MINUTES = -1 // Infinity
const COUNTDOWN_START_MILLIS = 2 * 60 * 1000 // 2 minutes

interface InactivityContextType {
    resetInactivityTimer: () => void
}

const InactivityContext = createContext<InactivityContextType | null>(null)

export const useInactivityContext = () => {
    const context = useContext(InactivityContext)
    if (!context) {
        throw new Error(
            'useInactivityContext must be used within an InactivityProvider',
        )
    }
    return context
}

interface InactivityProviderProps {
    children: React.ReactNode
}

export const InactivityProvider: React.FC<InactivityProviderProps> = ({
    children,
}) => {
    const { clinic } = useClinic()
    const { user, signOut } = useUserContext()
    const [isOpen, setIsOpen] = useState(false)

    const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null)
    const countdownTimerRef = useRef<NodeJS.Timeout | null>(null)
    const logoutTimerRef = useRef<NodeJS.Timeout | null>(null)
    const timeout =
        clinic?.settings?.max_inactivity_duration || DEFAULT_TIMEOUT_MINUTES
    const countdownStartMillis = Math.floor(
        Math.min(timeout / 2, COUNTDOWN_START_MILLIS),
    )

    const [countdown, setCountdown] = useState(countdownStartMillis / 1000)

    const clearTimers = useCallback(() => {
        if (inactivityTimerRef.current) clearTimeout(inactivityTimerRef.current)
        if (countdownTimerRef.current) clearInterval(countdownTimerRef.current)
        if (logoutTimerRef.current) clearTimeout(logoutTimerRef.current)
    }, [])

    const handleSignOut = useCallback(() => {
        clearTimers()
        setIsOpen(false)
        signOut()
    }, [clearTimers, signOut])

    const resetInactivityTimer = useCallback(() => {
        clearTimers()
        setIsOpen(false)

        if (timeout === -1 || !user) return

        inactivityTimerRef.current = setTimeout(() => {
            setIsOpen(true)
            setCountdown(countdownStartMillis / 1000)

            countdownTimerRef.current = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        if (countdownTimerRef.current)
                            clearInterval(countdownTimerRef.current)
                        return 0
                    }
                    return prev - 1
                })
            }, 1000)

            logoutTimerRef.current = setTimeout(
                handleSignOut,
                countdownStartMillis,
            )
        }, timeout - countdownStartMillis)
    }, [user, timeout, handleSignOut, clearTimers])

    useEffect(() => {
        if (!user || timeout === -1) return

        resetInactivityTimer()

        const handleUserActivity = () => resetInactivityTimer()

        window.addEventListener('mousemove', handleUserActivity)
        window.addEventListener('keydown', handleUserActivity)
        window.addEventListener('mousedown', handleUserActivity)

        return () => {
            clearTimers()
            window.removeEventListener('mousemove', handleUserActivity)
            window.removeEventListener('keydown', handleUserActivity)
            window.removeEventListener('mousedown', handleUserActivity)
        }
    }, [resetInactivityTimer, clearTimers])

    const fadeIn = keyframes`
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
  `

    return (
        <InactivityContext.Provider value={{ resetInactivityTimer }}>
            {children}
            <Modal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                isCentered
                size="md"
            >
                <ModalOverlay
                    bg="rgba(0, 0, 0, 0.4)"
                    backdropFilter="blur(5px)"
                />
                <ModalContent
                    bg="white"
                    borderRadius="2xl"
                    boxShadow="xl"
                    p={6}
                    m={4}
                    animation={`${fadeIn} 0.3s ease-out`}
                >
                    <ModalBody>
                        <Flex direction="column" align="center">
                            <Box bg="blue.50" p={4} borderRadius="full" mb={6}>
                                <MdAccessTime size="40" color="#3182CE" />
                            </Box>
                            <Text
                                fontSize="2xl"
                                fontWeight="bold"
                                color="gray.800"
                                mb={4}
                                textAlign="center"
                            >
                                Session Expiring Soon
                            </Text>
                            <Text
                                fontSize="md"
                                color="gray.600"
                                textAlign="center"
                                mb={6}
                            >
                                Your session will end in{' '}
                                <Text
                                    as="span"
                                    fontWeight="semibold"
                                    color="blue.500"
                                >
                                    {countdown} seconds
                                </Text>{' '}
                                due to inactivity.
                            </Text>
                            <Progress
                                value={
                                    (countdown /
                                        (countdownStartMillis / 1000)) *
                                    100
                                }
                                isAnimated
                                transition="0.1s"
                                size="sm"
                                colorScheme="blue"
                                width="100%"
                                borderRadius="full"
                                mb={6}
                            />
                            <Text
                                fontSize="sm"
                                color="gray.500"
                                textAlign="center"
                            >
                                Move your cursor or press any key to remain
                                active.
                            </Text>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </InactivityContext.Provider>
    )
}
