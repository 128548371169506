import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function RabbitIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M17.4799 69.8906C16.2292 69.8906 15.0816 69.2025 14.4919 68.1008C13.901 66.9988 13.9667 65.6616 14.659 64.6215C15.2443 63.744 15.6657 63.1125 15.6657 63.1125V49.5563L11.1469 45.0375V38.2593H8.7948C7.02123 38.2593 5.32108 37.555 4.06713 36.3014C2.81317 35.0476 2.10934 33.347 2.10934 31.5738V31.5717C2.10934 30.0864 2.78491 28.6817 3.94394 27.7539C7.14442 25.1942 13.4062 20.1843 13.4062 20.1843C13.4062 20.1843 12.254 10.9626 11.5953 5.69265C11.4813 4.78533 11.7625 3.8731 12.3679 3.18755C12.9736 2.50201 13.8435 2.10938 14.7584 2.10938H14.7619C17.7567 2.10938 20.1843 4.53713 20.1843 7.53188V20.1843V20.1845C23.0775 21.6311 25.2782 24.1675 26.3005 27.2364C26.9297 29.1222 27.6324 31.233 28.1916 32.9102C28.8073 34.7553 30.5334 36 32.4787 36H36.0024C48.4796 36 58.5938 46.1146 58.5938 58.5914V60.8531V65.3718C58.5938 66.5705 58.1182 67.7197 57.2698 68.5671C56.4225 69.4146 55.2736 69.8906 54.0749 69.8906H17.4799Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.7032 56.3343V69.8906V67.6313C24.7032 63.8878 27.7374 60.8532 31.4811 60.8532V60.8508C31.4811 57.8553 32.6708 54.9825 34.7891 52.8643C36.9072 50.7463 39.78 49.5563 42.7759 49.5563H42.7781"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.6575 8.45014C11.6575 8.45014 11.0362 8.10224 10.1561 7.60949C8.77788 6.83774 7.1185 6.75238 5.66908 7.37872C4.21853 8.00493 3.14303 9.27111 2.75997 10.8035C2.75997 10.8042 2.75997 10.805 2.75898 10.8058C2.58953 11.4878 2.70245 12.2095 3.07413 12.8066C3.44467 13.4035 4.04233 13.8243 4.729 13.9728C7.82444 14.642 12.559 15.6658 12.559 15.6658"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M69.8906 55.2047C69.8906 58.3243 67.3613 60.8531 64.2421 60.8531H58.5938V55.2047C58.5938 52.0852 61.1231 49.5563 64.2421 49.5563C67.3613 49.5563 69.8906 52.0852 69.8906 55.2047Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M64.242 47.4467C63.7306 47.4467 63.2307 47.4978 62.7468 47.5928C64.147 50.9854 64.9215 54.6993 64.9215 58.5912V62.9308C68.8821 62.5853 72 59.2531 72 55.2047C72 50.9271 68.5201 47.4467 64.242 47.4467Z"
            fill="currentColor"
        />
        <path
            d="M4.28357 16.0345L6.57202 16.5295L5.31629 6.47754C5.26575 6.07819 5.24488 5.67828 5.24543 5.28003C5.10645 5.32947 4.96912 5.3833 4.83289 5.44208C2.80921 6.31549 1.27826 8.10571 0.727298 10.2382C0.721805 10.2579 0.716861 10.2772 0.711917 10.2969C0.070865 12.8776 1.69519 15.4742 4.28357 16.0345Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.4789 33.8906H36.0022C49.6225 33.8906 60.7033 44.9714 60.7033 58.5917V65.3719C60.7033 69.0018 57.77 72 54.0753 72H26.8127V67.6313C26.8127 65.0572 28.9072 62.9626 31.4813 62.9626C32.6464 62.9626 33.5907 62.0156 33.5907 60.8511C33.5907 55.7765 37.6946 51.6654 42.7786 51.6654C43.9431 51.6654 44.8868 50.7211 44.8868 49.556C44.8868 48.3915 43.9409 47.4467 42.7764 47.4467C35.9214 47.4467 30.4014 52.5432 29.5032 58.9658C28.5375 59.1861 27.6306 59.564 26.8127 60.0716V56.3346C26.8127 55.1695 25.8684 54.2252 24.7033 54.2252C23.5382 54.2252 22.5939 55.1695 22.5939 56.3346V72H17.4803C15.4484 72 13.5906 70.8876 12.6321 69.0963C11.6735 67.3072 11.7768 65.1445 12.9034 63.4526L13.5566 62.4738V50.43L9.65533 46.5287C9.25983 46.1338 9.0379 45.5971 9.0379 45.0374V40.3687H8.7951C6.44568 40.3687 4.23743 39.4541 2.57574 37.793C0.914612 36.1318 0 33.9236 0 31.5736C0 29.4346 0.956909 27.4427 2.62573 26.1074C5.07239 24.1507 9.30652 20.7631 11.1671 19.2744L9.50208 5.95459C9.10492 2.79822 11.5714 0 14.7585 0C18.9152 0 22.2935 3.37885 22.2935 7.53168V18.9487C25.1257 20.7054 27.2357 23.3696 28.3019 26.5693L30.1926 32.2427C30.5217 33.2281 31.4401 33.8906 32.4789 33.8906ZM13.1094 31.2188C14.2743 31.2188 15.2188 30.2743 15.2188 29.1094C15.2188 27.9444 14.2743 27 13.1094 27C11.9444 27 11 27.9444 11 29.1094C11 30.2743 11.9444 31.2188 13.1094 31.2188Z"
            fill="currentColor"
        />
    </Icon>
)
