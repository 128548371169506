import React, { useRef } from 'react'
import { useToast, UseToastOptions } from '@chakra-ui/toast'
import type { ToastId } from '@chakra-ui/toast'
import { Spinner } from '@chakra-ui/spinner'
import {
    ArrowForwardIcon,
    CheckCircleIcon,
    CloseIcon,
    Icon,
    WarningIcon,
} from '@chakra-ui/icons'
import { Button, IconButton } from '@chakra-ui/button'
import { Box, Text, Flex, HStack, Spacer } from '@chakra-ui/layout'

import Card from '~components/shared/card'
import {
    BiArrowFromLeft,
    BiArrowToRight,
    BiRightArrow,
    BiX,
} from 'react-icons/bi'
import { FiArrowRight } from 'react-icons/fi'

type ProgressToastInput = {
    entity: string
    action?: string
}

type ProgressToastReturnType = {
    onBegin(input: ProgressToastInput): void
    onSuccess(
        input: ProgressToastInput & {
            successDescription?: string
            successAction?: () => void
        }
    ): void
    onError(input: ProgressToastInput): void
}

export default function useProgressToast(): ProgressToastReturnType {
    const toast = useToast()

    const activeToast = useRef<ToastId | null>(null)

    function onCloseActiveToast() {
        if (activeToast.current) {
            toast.close(activeToast.current)
            activeToast.current = null
        }
    }

    const sharedToastOptions: UseToastOptions = {
        position: 'top-right',
        styleConfig: {
            top: '64px',
        },
    }

    function onBegin({ entity, action = 'load' }: ProgressToastInput) {
        activeToast.current = toast({
            ...sharedToastOptions,
            render: () => (
                <LoadingToast
                    title={`${action}ing ${entity}`}
                    description={`We'll notify you when ${action}ing is complete. You can dismiss this message.`}
                    onClose={onCloseActiveToast}
                />
            ),
            duration: null,
        })
    }

    function onSuccess({
        entity,
        action = 'load',
        successDescription,
        successAction,
    }: ProgressToastInput & {
        successDescription?: string
        successAction?: () => void
    }) {
        onCloseActiveToast()
        activeToast.current = toast({
            ...sharedToastOptions,
            render: () => (
                <ResponseToast
                    status="success"
                    title={`${entity} ${action}ed!`}
                    description={successDescription}
                    successAction={successAction}
                    onClose={onCloseActiveToast}
                />
            ),
            duration: 10000,
        })
    }

    function onError({ entity, action = 'load' }: ProgressToastInput) {
        onCloseActiveToast()
        activeToast.current = toast({
            ...sharedToastOptions,
            render: () => (
                <ResponseToast
                    status="error"
                    title={`Error ${action}ing ${entity}`}
                    description={`Contact support for more information.`}
                    onClose={onCloseActiveToast}
                />
            ),
        })
    }

    return {
        onBegin,
        onSuccess,
        onError,
    }
}

interface LoadingToastProps {
    title: string
    description: string
    onClose: () => void
}

function LoadingToast({ title, description, onClose }: LoadingToastProps) {
    return (
        <Card py={3} w="fit-content" rounded="md" bg="white" shadow="blue.md">
            <Flex align="center">
                <HStack spacing={4}>
                    <Box>
                        <Spinner
                            size="lg"
                            emptyColor="gray.200"
                            color="brand.500"
                            thickness="4px"
                            speed="0.8s"
                        />
                    </Box>
                    <Box maxW="300px">
                        <Text
                            textTransform="capitalize"
                            fontSize="sm"
                            fontWeight="bold"
                        >
                            {title}
                        </Text>
                        <Text fontSize="xs" color="gray.500">
                            {description}
                        </Text>
                    </Box>
                </HStack>
                <Spacer />
                <IconButton
                    size="xs"
                    variant="ghost"
                    icon={<CloseIcon />}
                    aria-label="close"
                    onClick={onClose}
                />
            </Flex>
        </Card>
    )
}

interface Props {
    status: 'success' | 'error'
    title: string
    description?: string
    successAction?: () => void
    onClose: () => void
}

function ResponseToast({
    status,
    title,
    description,
    successAction,
    onClose,
}: Props) {
    const sharedIconProps = {
        w: 8,
        h: 8,
    }

    const icon =
        status === 'success' ? (
            <CheckCircleIcon {...sharedIconProps} color="brand.500" />
        ) : (
            <WarningIcon {...sharedIconProps} color="red.500" />
        )

    return (
        <Card py={3} rounded="md" bg="white" shadow="blue.md">
            <Flex align="center">
                <HStack spacing={4}>
                    <Box>{icon}</Box>
                    <Box>
                        <Text
                            fontSize="sm"
                            color={
                                status === 'success' ? 'brand.700' : 'red.500'
                            }
                            textTransform="capitalize"
                            fontWeight="bold"
                        >
                            {title}
                        </Text>
                        {description && (
                            <Text fontSize="xs" color="gray.500">
                                {description}
                            </Text>
                        )}
                    </Box>
                </HStack>
                <Spacer minW={6} />
                <Flex gap={2} flexDir="column">
                    {successAction ? (
                        <>
                            <Button
                                size="xs"
                                colorScheme="brand"
                                boxShadow="sm"
                                leftIcon={<Icon as={FiArrowRight} />}
                                onClick={() => {
                                    successAction()
                                    onClose()
                                }}
                            >
                                Show me
                            </Button>
                            <Button
                                size="xs"
                                bg="white"
                                boxShadow="sm"
                                border="1px solid"
                                borderColor="gray.200"
                                leftIcon={<Icon as={BiX} />}
                                onClick={onClose}
                            >
                                Dismiss
                            </Button>
                        </>
                    ) : (
                        <IconButton
                            size="xs"
                            variant="ghost"
                            icon={<CloseIcon />}
                            aria-label="close"
                            onClick={onClose}
                        />
                    )}
                </Flex>
            </Flex>
        </Card>
    )
}
