import { getUrlQueryParamsObj } from '~utils/helpers'

export type UrlQueryParamInput = {
    newSearch?: string
    newClient?: string
    newPatient?: string
    newAppointment?: string
    newPage?: number
    newPageSize?: number
}

export function getCleanedQueryParams(): {
    search?: string
    client?: string
    patient?: string
    appointment?: string
    page?: number
    pageSize?: number
    dataChat?: string
} {
    const {
        search: parsedSearch,
        client: parsedClient,
        patient: parsedPatient,
        appointment: parsedAppointment,
        page: parsedPage,
        pageSize: parsedPageSize,
        dataChat: parsedDataChat,
    } = getUrlQueryParamsObj()

    const search = typeof parsedSearch === 'string' ? parsedSearch : undefined
    const client = typeof parsedClient === 'string' ? parsedClient : undefined
    const patient =
        typeof parsedPatient === 'string' ? parsedPatient : undefined
    const appointment =
        typeof parsedAppointment === 'string' ? parsedAppointment : undefined
    const page =
        typeof parsedPage === 'string' ? parseInt(parsedPage) : undefined
    const pageSize =
        typeof parsedPageSize === 'string'
            ? parseInt(parsedPageSize)
            : undefined
    const dataChat =
        typeof parsedDataChat === 'string' ? parsedDataChat : undefined

    return {
        search,
        client,
        patient,
        appointment,
        page,
        pageSize,
        dataChat,
    }
}
