import React from 'react'
import { Icon, type IconProps } from '@chakra-ui/icons'
import { FaCircleExclamation } from 'react-icons/fa6'

export default function UrgentIcon(props: IconProps) {
    return (
        <Icon
            as={FaCircleExclamation}
            color="pink.500"
            fontSize="sm"
            {...props}
        />
    )
}
