import React, {
    createContext,
    useContext,
    useMemo,
    useRef,
    useState,
    useEffect,
} from 'react'
import { useClinic } from '~contexts/clinic-context'
import {
    ActionItemFieldsFragment,
    ActionItemStatus,
    ActionItemType,
    AppointmentStatus,
    FilterOp,
    QueryInput,
    RescheduleStatus,
    useQueryActionItemsQuery,
    useQueryAppointmentActionItemsQuery,
    useQueryRescheduleActionItemsQuery,
} from '~graphql/generated/graphql'

type ActionItemProviderType = {
    actionItems: ActionItemFieldsFragment[]
    isLoading: boolean
    refetch: () => void
    filterTypes: ActionItemType[]
    setFilterTypes: React.Dispatch<React.SetStateAction<ActionItemType[]>>
    assigneeIds: string[]
    setAssigneeIds: React.Dispatch<React.SetStateAction<string[]>>
}

const ActionItemContext = createContext<ActionItemProviderType>({
    actionItems: [],
    isLoading: false,
    refetch: () => {},
    filterTypes: [],
    setFilterTypes: () => {},
    assigneeIds: [],
    setAssigneeIds: () => {},
})

export const ActionItemProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { clinic } = useClinic()
    const [filterTypes, setFilterTypes] = useState<ActionItemType[]>([])
    const [assigneeIds, setAssigneeIds] = useState<string[]>([])
    const dateNow = useRef(new Date())

    const clinicFilter = {
        column: 'clinic',
        filterOp: FilterOp.Eq,
        value: { string: clinic?.id || '' },
    }

    const {
        data: appointmentData,
        loading: appointmentLoading,
        refetch: refetchAppointments,
    } = useQueryAppointmentActionItemsQuery({
        variables: {
            where: [
                clinicFilter,
                {
                    column: 'status',
                    filterOp: FilterOp.Eq,
                    value: { string: AppointmentStatus.Pending },
                },
                {
                    column: 'clinic_approved_at',
                    filterOp: FilterOp.Is,
                    value: { string: 'null' },
                },
                {
                    column: 'action_items.type',
                    filterOp: FilterOp.Eq,
                    value: { string: ActionItemType.PendingAppointment },
                },
            ],
        },
        skip: !clinic?.id,
        pollInterval: clinic?.id ? 120000 : 0,
    })

    const {
        data: rescheduleData,
        loading: rescheduleLoading,
        refetch: refetchReschedules,
    } = useQueryRescheduleActionItemsQuery({
        variables: {
            where: [
                clinicFilter,
                {
                    column: 'status',
                    filterOp: FilterOp.Eq,
                    value: {
                        string: RescheduleStatus.AwaitingClinicApproval,
                    },
                },
                {
                    column: 'appointment.status', // TODO Find a way to or this with clinic_appt_status
                    filterOp: FilterOp.In,
                    value: {
                        stringArray: [
                            AppointmentStatus.Booked,
                            AppointmentStatus.Confirmed,
                        ],
                    },
                },
                {
                    column: 'requested_start_time',
                    filterOp: FilterOp.Gte,
                    value: { float: dateNow.current.getTime() },
                },
                {
                    column: 'confirmed_at',
                    filterOp: FilterOp.Is,
                    value: { string: 'null' },
                },
            ],
        },
        skip: !clinic?.id,
        pollInterval: clinic?.id ? 120000 : 0,
    })

    const appointmentActionItems: ActionItemFieldsFragment[] = useMemo(
        () =>
            (appointmentData?.queryAppointments || [])
                .map(a => a.action_items)
                .filter(a => !!a)
                .flat() as ActionItemFieldsFragment[],
        [appointmentData]
    )

    const rescheduleActionItems: ActionItemFieldsFragment[] = useMemo(
        () =>
            (rescheduleData?.queryReschedules || [])
                .map(r => r.action_items)
                .filter(a => !!a)
                .flat() as ActionItemFieldsFragment[],
        [rescheduleData]
    )

    const queryInput: QueryInput[] = useMemo(() => {
        const tempInput: QueryInput[] = [
            clinicFilter,
            {
                column: 'status',
                filterOp: FilterOp.Eq,
                value: { string: ActionItemStatus.Pending },
            },
            {
                column: 'type',
                filterOp: FilterOp.In,
                value: {
                    stringArray: [
                        ActionItemType.General,
                        ActionItemType.OfficeTask,
                        ActionItemType.MissingClient,
                        ActionItemType.MissingPatient,
                        ActionItemType.FormSubmitted,
                    ],
                },
            },
        ]

        return tempInput
    }, [clinic?.id, filterTypes])

    const {
        data: itemData,
        loading,
        refetch: refetchActionItems,
    } = useQueryActionItemsQuery({
        variables: {
            where: queryInput,
            page: 0,
            pageSize: 100,
            orderBy: {
                column: 'created_at',
                isAscending: false,
            },
        },
        skip: !clinic?.id,
        pollInterval: clinic?.id ? 120000 : 0,
    })

    const refetch = () => {
        if (clinic?.id) {
            refetchAppointments()
            refetchReschedules()
            refetchActionItems()
        }
    }

    const otherActionItems = itemData?.queryActionItems || []

    const actionItems = [
        ...appointmentActionItems,
        ...rescheduleActionItems,
        ...otherActionItems,
    ]

    return (
        <ActionItemContext.Provider
            value={{
                actionItems,
                isLoading: loading || appointmentLoading || rescheduleLoading,
                refetch,
                filterTypes,
                setFilterTypes,
                assigneeIds,
                setAssigneeIds,
            }}
        >
            {children}
        </ActionItemContext.Provider>
    )
}

export const useActionItems = () => {
    return useContext(ActionItemContext)
}
