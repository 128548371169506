import { stringIfExists } from '~utils/helpers'

export const AdminRoutes = {
    Users: '/admin/users',
    NewUser: '/admin/users/new',
    EditUser: (id: string) => `/admin/users/${id}`,
    Clinics: '/admin/clinics',
    NewClinic: '/admin/clinics/new',
    EditClinic: (id: string) => `/admin/clinics/${id}`,
    Organizations: '/admin/organizations',
    EditOrganization: (id: string) => `/admin/organizations/${id}`,
    Usage: '/admin/usage',
    UsageForClinic: (id: string) => `/admin/usage/${id}`,
}

// Broken out by sidebar
export const UserRoutes = {
    // Home
    Home: '/',

    // Daily Huddle
    DailyHuddle: '/daily-huddle',

    // Pending Actions
    PendingActions: '/pending-actions',

    // Messages
    Chats: '/chats',
    ChatConversation: (id: string) => `/chats/${id}`,

    // Appointments
    AllAppointments: '/appointments',
    AppointmentDetails: (id: string) => `/appointments/${id}`,

    // Schedule
    Schedule: '/schedule',
    ScheduleDay: (date: string) => `/schedule/${date}`,

    // Analytics\
    DataChat: '/analytics/data-chat',
    DataChatWithId: (id: string) => `/analytics/data-chat?dataChat=${id}`,
    ClinicReporting: '/analytics/reporting',
    ClinicTrends: '/analytics/trends',
    ClinicInitiatives: '/analytics/initiatives',
    ViewClinicReport: (id: string) => `/analytics/reporting/${id}`,
    NewClinicTemplate: '/analytics/reporting/new',
    EditClinicTemplate: (id: string) => `/analytics/reporting/${id}/edit`,
    Goals: '/analytics/goals',
    NewGoal: '/analytics/goals/new',
    EditGoal: (id: string) => `/analytics/goals/${id}`,

    // Booking
    AppointmentStatuses: '/booking/appointment-statuses',
    Branding: '/booking/branding',
    SchedulingRules: '/booking/scheduling-rules',
    NewSchedulingRule: '/booking/scheduling-rules/new',
    NewWidgetSchedulingRule: (id: string) =>
        `/booking/scheduling-rules/new/${id}`,
    EditSchedulingRule: (id: string) => `/booking/scheduling-rules/${id}`,
    Widgets: '/booking/widgets',
    NewWidget: '/booking/widgets/new',
    EditWidget: (id: string) => `/booking/widgets/${id}`,
    WidgetInstructions: (id: string) => `/booking/widgets/${id}/instructions`,
    WidgetPreview: (id: string, type: string) =>
        `/booking/widgets/${id}/preview/${type}`,
    Clinicians: '/booking/clinicians',
    Rooms: '/booking/rooms',
    Payments: '/booking/payments',
    Services: '/booking/services',
    EditService: (id: string) => `/booking/services/${id}/edit`,
    NewService: '/booking/services/new',
    Species: '/booking/species',
    Sexes: '/booking/sexes',

    // Engagement
    Campaigns: '/engagement/campaigns',
    EditCampaign: (id: string) => `/engagement/campaigns/edit/${id}`,
    ClientSegments: '/engagement/clients',
    ClientSegmentDetails: (id: string) => `/engagement/clients/${id}`,
    NewSegment: '/engagement/segments/new',
    Forms: '/engagement/forms',
    EditForm: (id: string) => `/engagement/forms/edit/${id}`,
    FormResponses: (id?: string) =>
        id
            ? `/engagement/forms/responses?formId=${id}`
            : '/engagement/forms/responses',
    ViewFormResponse: (id: string) => `/engagement/forms/responses/${id}`,
    Notifications: '/engagement/notifications',
    EditNotification: (id: string) => `/engagement/notifications/edit/${id}`,
    NotificationLogs: '/engagement/notification-logs',
    MassCommunication: '/engagement/mass-communication',
    EditScheduledBulkMessage: (id: string) =>
        `/engagement/mass-communication/edit/${id}`,
    Loyalty: '/engagement/loyalty',
    Reviews: '/engagement/reviews',
    MessageTemplates: '/engagement/message-templates',
    ReminderTypes: '/engagement/reminder-types',

    // Clinic
    ClinicInfo: '/clinic/info',
    ClinicUsers: '/clinic/users',
    DataSync: '/clinic/data-sync',

    // Settings
    Settings: '/settings',
    SettingsTab: (tab: string) => `/settings?feature=${tab}`,

    // Help
    Help: '/help',
    HelpSearch: '/help/search',

    // Auth/Misc
    404: '/404',
    Auth: '/auth',
    AuthWithRedirect: (redirect: string) => `/auth?redirect=${redirect}`,
    AuthWithRegister: '/auth?register=true',
    MFARegistration: '/auth/register-mfa',
    ForgotPassword: '/auth/forgot-password',
    SelectClinic: '/select-clinic',
    Profile: '/profile',
    RhapsodyWidget: '/rhapsody-widget',

    // Enterprise
    Overview: '/enterprise/',
    Reports: '/enterprise/reports',
    KpiDashboard: '/enterprise/kpis',
    ReportsList: '/enterprise/reports/list',
    OrganizationBranding: '/enterprise/branding',
    NewReportTemplate: '/enterprise/reports/templates/new',
    ReportTemplate: (id: string) => `/enterprise/reports/templates/${id}`,
    RealTimeReport: (id: string) => `/enterprise/reports/realtime/${id}`,
    Trends: (templateId?: string, metricId?: string) =>
        templateId || metricId
            ? `/enterprise/trends?${stringIfExists(templateId, {
                  pretext: 'templateId=',
              })}${stringIfExists(metricId, {
                  pretext: `${templateId ? '&' : ''}metricId=`,
              })}`
            : `/enterprise/trends`,
    Initiatives: '/enterprise/initiatives',
    OrganizationWidgets: '/enterprise/widgets',
    NewOrganizationWidget: '/enterprise/widgets/new',
    EditOrganizationWidget: (id: string) => `/enterprise/widgets/${id}`,
}
