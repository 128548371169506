import React from 'react'
import { FilterOp, useCountChatsQuery } from '~graphql/generated/graphql'
import { useClinic } from './clinic-context'
import { useUserContext } from '~config/user-context'

type UnreadMessagesContextType = {
    unreadMessages: number
    refreshUnreadMessages: () => void
}

const UnreadMessagesContext = React.createContext<UnreadMessagesContextType>({
    unreadMessages: 0,
    refreshUnreadMessages: () => {},
})

export function useUnreadMessages() {
    return React.useContext(UnreadMessagesContext)
}

export default function UnreadMessagesProvider({
    children,
}: React.PropsWithChildren) {
    return (
        <UnreadMessagesContext.Provider value={useUnreadMessagesValues()}>
            {children}
        </UnreadMessagesContext.Provider>
    )
}

function useUnreadMessagesValues(): UnreadMessagesContextType {
    const { user } = useUserContext()
    const { clinic } = useClinic()

    const { data, refetch: refreshUnreadMessages } = useCountChatsQuery({
        variables: {
            where: [
                {
                    column: 'clinic',
                    filterOp: FilterOp.Eq,
                    value: {
                        string: clinic?.id || '',
                    },
                },
                {
                    column: 'clinic_unread_count',
                    filterOp: FilterOp.Gt,
                    value: {
                        float: 0,
                    },
                },
            ],
        },
        pollInterval: 600000,
        skip: !clinic || !user,
    })

    const unreadMessages = data?.countChats || 0

    return {
        unreadMessages,
        refreshUnreadMessages,
    }
}
