import React, {
    useState,
    useEffect,
    useContext,
    createContext,
    PropsWithChildren,
} from 'react'

import {
    ClinicFieldsFragment,
    useUpdateClinicMutation,
} from '~graphql/generated/graphql'
import { SetStateFn } from '~config/constants'
import { handleMergeClinicSettings } from '../helpers'
import { useToastFeedback } from '~utils/hooks/use-toast-feedback'

export default function SyncSettingsProvider({
    children,
    clinic,
}: PropsWithChildren<{ clinic: ClinicFieldsFragment }>) {
    return (
        <SyncSettingsContext.Provider
            value={{ ...useSyncSettingsValues(clinic) }}
        >
            {children}
        </SyncSettingsContext.Provider>
    )
}

export function useSyncSettingsContext() {
    return useContext(SyncSettingsContext)
}

function useSyncSettingsValues(
    clinic: ClinicFieldsFragment
): SyncSettingsContextType {
    const [syncIntervalMinutes, setSyncIntervalMinutes] = useState<number>(10)
    const [longSyncIntervalHours, setLongSyncIntervalHours] =
        useState<number>(6)
    const [shouldSyncReminders, setShouldSyncReminders] =
        useState<boolean>(false)

    useEffect(() => {
        if (!clinic.settings) return
        const { settings } = clinic

        setSyncIntervalMinutes(settings.sync_interval_minutes ?? 10)
        setLongSyncIntervalHours(settings.long_sync_interval_hours ?? 6)
        setShouldSyncReminders(settings.should_sync_reminders ?? false)
    }, [clinic])

    const settings = clinic.settings

    const canSave = settings
        ? syncIntervalMinutes !== settings.sync_interval_minutes ||
          longSyncIntervalHours !== settings.long_sync_interval_hours ||
          shouldSyncReminders !== settings.should_sync_reminders
        : false

    const toasts = useToastFeedback(
        'Successfully updated settings',
        'Error updating settings'
    )

    const [handleUpdate, { loading }] = useUpdateClinicMutation({
        variables: {
            id: clinic?.id || '',
            data: {
                settings: {
                    sync_interval_minutes: syncIntervalMinutes,
                    long_sync_interval_hours: longSyncIntervalHours,
                    should_sync_reminders: shouldSyncReminders,
                },
            },
        },
        update: (cache, res) => {
            handleMergeClinicSettings(clinic, cache, res)
        },
        ...toasts,
    })

    return {
        // Context Values
        canSave,
        isUpdateLoading: loading,

        // Actions
        handleUpdateSettings: handleUpdate,

        // Setting Values
        syncIntervalMinutes,
        setSyncIntervalMinutes,
        longSyncIntervalHours,
        setLongSyncIntervalHours,
        shouldSyncReminders,
        setShouldSyncReminders,
    }
}

type SyncSettingsContextType = {
    // Context Values
    canSave: boolean
    isUpdateLoading: boolean

    // Actions
    handleUpdateSettings: () => Promise<any>

    // Setting Values
    syncIntervalMinutes: number
    setSyncIntervalMinutes: SetStateFn<number>
    longSyncIntervalHours: number
    setLongSyncIntervalHours: SetStateFn<number>
    shouldSyncReminders: boolean
    setShouldSyncReminders: SetStateFn<boolean>
}

const SyncSettingsContext = createContext<SyncSettingsContextType>(
    {} as SyncSettingsContextType
)
