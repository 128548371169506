import React from 'react'
import { TabPanel } from '@chakra-ui/tabs'
import { VStack } from '@chakra-ui/layout'

import GradientDivider from '~components/shared/gradient-divider'
import SaveActions from './save-actions'
import MiscSettingsProvider from './misc-settings-context'
import { ClinicFieldsFragment } from '~graphql/generated/graphql'
import NotifyClinicOnMultiSelect from './notify-clinic-on'
import EditReminderSendTime from './edit-reminder-send-time'
import EditInactiveTimoutDuration from './edit-inactive-timeout-duration'
import MessagePrioritySwitch from './message-priority-switch'
import AutoReplySettings from './auto-reply-settings'

export default function MiscSettingsPanel({
    clinic,
}: {
    clinic: ClinicFieldsFragment
}) {
    return (
        <TabPanel pb={0}>
            <MiscSettingsProvider clinic={clinic}>
                <VStack
                    w="full"
                    align="flex-start"
                    spacing={8}
                    pb={8}
                    pt={4}
                    divider={<GradientDivider />}
                    minH="100dvh"
                >
                    <NotifyClinicOnMultiSelect />
                    <EditReminderSendTime />
                    <EditInactiveTimoutDuration />
                    <MessagePrioritySwitch />
                    <AutoReplySettings />
                </VStack>
                <SaveActions />
            </MiscSettingsProvider>
        </TabPanel>
    )
}
