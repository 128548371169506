import React from 'react'
import { Box } from '@chakra-ui/layout'
import type { BoxProps } from '@chakra-ui/layout'

export default function Card({ children, ...rest }: BoxProps) {
    return (
        <Box
            border="1px solid"
            borderColor="gray.200"
            rounded="lg"
            p={4}
            boxShadow="sm"
            bg="gray.50"
            {...rest}
        >
            {children}
        </Box>
    )
}
