import { Box, Flex, Icon, Spacer, Text, Tooltip } from '@chakra-ui/react'
import { useLocation } from '@reach/router'
import React, { useState } from 'react'
import { IconType } from 'react-icons'
import { RiArrowDownSLine, RiArrowRightSLine } from 'react-icons/ri'
import { SidebarItem } from './tabs'
import SidebarTab from './sidebar-tab'

interface Props {
    icon?: IconType
    label: string
    pages: SidebarItem[]
    isSidebarOpen: boolean
    submenu: string
    setSubmenu: (value: React.SetStateAction<string>) => void
}

const SidebarDrawer: React.FC<Props> = ({
    icon,
    label,
    pages,
    isSidebarOpen,
    submenu,
    setSubmenu,
}) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
    const { pathname } = useLocation()
    const activePage = pages.find(
        ({ to }) => to && (to === '/' ? pathname === to : pathname.includes(to))
    )
    const isActive = pages.some(({ to }) => to && pathname.includes(to))

    const hasNewPages = pages.some(({ isNew }) => isNew)

    return (
        <>
            <Tooltip isDisabled={isSidebarOpen} label={label} placement="right">
                <Flex
                    as="button"
                    w="100%"
                    onClick={() =>
                        isSidebarOpen
                            ? setIsCollapsed(!isCollapsed)
                            : submenu === label
                            ? setSubmenu('none')
                            : setSubmenu(label)
                    }
                    px={3}
                    py={2}
                    align="center"
                    cursor="pointer"
                    pl={2}
                    position="relative"
                    borderLeftWidth={4}
                    borderLeftColor={isActive ? 'brand.500' : 'transparent'}
                    transition="0.2s"
                >
                    <Box
                        opacity={isActive ? 1 : 0}
                        position="absolute"
                        w={16}
                        left={0}
                        top={0}
                        bottom={0}
                        bgGradient="linear(to-br, rgba(19, 216, 121, 0.2), rgba(19, 216, 121, 0.05), rgba(19, 216, 121, 0), rgba(19, 216, 121, 0), rgba(19, 216, 121, 0))"
                    />
                    <Flex
                        // bg={isActive ? 'brand.500' : 'gray.200'}
                        ml={isSidebarOpen ? 1 : 0}
                        mr={isSidebarOpen ? 4 : 0}
                        minW={8}
                        boxSize={8}
                        borderRadius="xl"
                        justify="center"
                        align="center"
                        transition="0.2s"
                        position="relative"
                    >
                        <Icon
                            as={
                                isActive && isCollapsed && isSidebarOpen
                                    ? activePage?.icon || icon
                                    : icon
                            }
                            boxSize="20px"
                            color={
                                isActive
                                    ? 'brand.500'
                                    : submenu === label
                                    ? 'white'
                                    : 'gray.400'
                            }
                        />
                        {!!hasNewPages && (
                            <Box
                                position="absolute"
                                bg="brand.300"
                                rounded="full"
                                boxSize="8px"
                                top="3px"
                                right="3px"
                            />
                        )}
                    </Flex>
                    <Box>
                        {isActive && isCollapsed && isSidebarOpen ? (
                            <Text
                                fontSize="11px"
                                color="gray.400"
                                lineHeight="14px"
                                textAlign="left"
                            >
                                {label} /
                            </Text>
                        ) : null}
                        <Text
                            opacity={isSidebarOpen ? 1 : 0}
                            transition="0.2s"
                            fontSize="15px"
                            lineHeight="17px"
                            textTransform={'capitalize'}
                            whiteSpace="nowrap"
                            textAlign="left"
                            textOverflow={'ellipsis'}
                            color={
                                isActive && isSidebarOpen ? 'white' : 'gray.400'
                            }
                            overflow="hidden"
                        >
                            {isActive && isCollapsed
                                ? `${activePage?.label}`
                                : label}
                        </Text>
                    </Box>
                    <Spacer />
                    <Icon
                        opacity={isSidebarOpen ? 1 : 0}
                        mr={2}
                        as={isCollapsed ? RiArrowRightSLine : RiArrowDownSLine}
                        boxSize="18px"
                        color={'gray.500'}
                    />
                </Flex>
            </Tooltip>
            <Box
                overflow="hidden"
                maxH={
                    isCollapsed || !isSidebarOpen
                        ? '0px'
                        : `${pages.length * 57}px`
                }
                transition="0.3s"
            >
                {pages.map((page, index) => (
                    <SidebarTab
                        key={index}
                        {...page}
                        isOpen={isSidebarOpen}
                        isSubPage
                        setSubmenu={setSubmenu}
                    />
                ))}
            </Box>
        </>
    )
}

export default SidebarDrawer
