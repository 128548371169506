import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export default function SharedHeader({ children, ...props }: TextProps) {
    return (
        <Text fontSize="xl" fontWeight="semibold" {...props}>
            {children}
        </Text>
    )
}
