import { FilterOp, QueryInput } from '~graphql/generated/graphql'

export const eqStringFilter = (
    column: string,
    value: string | null | undefined,
    not: boolean = false
): QueryInput => ({
    column,
    value: { string: value || '' },
    filterOp: FilterOp.Eq,
    not,
})

export const inStringArrFilter = (
    column: string,
    value: string[] | null | undefined,
    not: boolean = false
): QueryInput => ({
    column,
    value: { stringArray: value || [] },
    filterOp: FilterOp.In,
    not,
})

export const eqBoolFilter = (
    column: string,
    value: boolean | null | undefined,
    not: boolean = false
): QueryInput => ({
    column,
    value: { boolean: value || false },
    filterOp: FilterOp.Eq,
    not,
})

export const ltMillisFilter = (
    column: string,
    value: number | null | undefined,
    not: boolean = false
): QueryInput => ({
    column,
    value: { millisFromNow: value || 0 },
    filterOp: FilterOp.Lt,
    not,
})

export const gtFloatFilter = (
    column: string,
    value: number | null | undefined,
    not: boolean = false
): QueryInput => ({
    column,
    value: { float: value || 0 },
    filterOp: FilterOp.Gt,
    not,
})

export const gteFloatFilter = (
    column: string,
    value: number | null | undefined,
    not: boolean = false
): QueryInput => ({
    column,
    value: { float: value || 0 },
    filterOp: FilterOp.Gte,
    not,
})

export const ltFloatFilter = (
    column: string,
    value: number | null | undefined,
    not: boolean = false
): QueryInput => ({
    column,
    value: { float: value || 0 },
    filterOp: FilterOp.Lt,
    not,
})

export const lteFloatFilter = (
    column: string,
    value: number | null | undefined,
    not: boolean = false
): QueryInput => ({
    column,
    value: { float: value || 0 },
    filterOp: FilterOp.Lte,
    not,
})
