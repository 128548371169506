import React from 'react'
import SettingSelect from '../form/setting-select'
import { useMiscSettingsContext } from './misc-settings-context'

const SEND_TIME_MINUTES_OPTIONS = [
    300, // 5 AM
    360, // 6 AM
    420, // 7 AM
    480, // 8 AM
    540, // 9 AM
    600, // 10 AM
    660, // 11 AM
    720, // 12 PM
    780, // 1 PM
    840, // 2 PM
    900, // 3 PM
    960, // 4 PM
    1020, // 5 PM
    1080, // 6 PM
    1140, // 7 PM
    1200, // 8 PM
    1260, // 9 PM
]

export default function EditReminderSendTime() {
    const { reminderSendTimeMinutes, setReminderSendTimeMinutes } =
        useMiscSettingsContext()

    return (
        <SettingSelect
            label="Send daily reminders to clients at:"
            options={SEND_TIME_MINUTES_OPTIONS.map(o => String(o))}
            value={String(reminderSendTimeMinutes)}
            setValue={v => setReminderSendTimeMinutes(parseInt(v))}
            textModification={v =>
                `${(parseInt(v) / 60) % 12 || 12}:00 ${
                    parseInt(v) / 60 >= 12 ? 'PM' : 'AM'
                }`
            }
        />
    )
}
