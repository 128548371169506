import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'
import SharedInput, {
    SharedInputProps,
} from '~components/shared/form/shared-input'
import SharedLabel from '~components/shared/form/shared-label'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { useUserContext } from '~config/user-context'
import SettingAdminTag from './setting-admin-tag'

interface Props extends SharedInputProps {
    requiresAdmin?: boolean
}

export default function SettingInput({
    requiresAdmin,
    label,
    labelProps,
    moreInfo,
    ...sharedInputProps
}: Props) {
    const { isAdmin } = useUserContext()

    const SHARED_PROPS: BoxProps = {
        w: 'full',
    }

    if (requiresAdmin && !isAdmin) return null

    return (
        <SharedStack spacing={2}>
            <Box>
                <SharedStack
                    direction={{ base: 'column', md: 'row' }}
                    align="center"
                >
                    {requiresAdmin && <SettingAdminTag mb={0} />}
                    {label && (
                        <SharedLabel fontSize="md" {...labelProps}>
                            {label}
                        </SharedLabel>
                    )}
                </SharedStack>
                {!!moreInfo && moreInfo}
            </Box>
            <SharedInput
                size="md"
                labelProps={{ fontSize: 'md' }}
                containerProps={SHARED_PROPS}
                {...sharedInputProps}
            />
        </SharedStack>
    )
}
