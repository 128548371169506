import React from 'react'
import { Box, Text } from '@chakra-ui/layout'

import DetailedPatientHeader from './patient-header'
import EmptyState from '~components/shared/empty-state'
import PatientAppointments from './patient-appointments'
import GradientDivider from '~components/shared/gradient-divider'
import { PatientFieldsFragment } from '~graphql/generated/graphql'
import SharedStack from '~components/shared/wrappers/shared-stack'
import PatientVaccines from './patient-vaccines'

interface Props {
    patient?: PatientFieldsFragment
}

export default function DetailedPatientInfo({ patient }: Props) {
    if (!patient) return <EmptyState title="Patient Not Found" />

    return (
        <>
            <Box px={2}>
                <DetailedPatientHeader patient={patient} />
            </Box>
            <GradientDivider my={8} />
            <Text fontWeight="semibold" mb={3}>
                Appointments
            </Text>
            <SharedStack spacing={4}>
                <PatientAppointments patient={patient} />
            </SharedStack>
            <GradientDivider my={8} />
            <Text fontWeight="semibold" mb={3}>
                Vaccines
            </Text>
            <SharedStack spacing={4}>
                <PatientVaccines patient={patient} />
            </SharedStack>
        </>
    )
}
