import React from 'react'
import {
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Image,
    Box,
    Flex,
    Circle,
} from '@chakra-ui/react'
import { useLocation } from '@reach/router'
import { LocalStorageKeys } from '~config/constants'

type TutorialType = {
    path: string
    title: string
    content: TutorialContentType[]
}

type TutorialContentType = {
    subtitle: string
    description?: string
    imageUrl?: string
    customComponent?: React.ReactNode
}

const tutorialPages: TutorialType[] = [
    // {
    //     path: 'chats',
    //     title: 'New feature - Chats',
    //     content: [
    //         {
    //             subtitle: 'Introducing Chckup Chats!',
    //             description:
    //                 'Chckup Chats is a new feature that allows you to communicate with your clients in a secure and centralized location. You can now send and receive messages, images, and files from your clients.',
    //             imageUrl:
    //                 'https://firebasestorage.googleapis.com/v0/b/chckup-server.appspot.com/o/chckup-assets%2Fmessaging-proto-4.PNG?alt=media&token=6f1777a4-0146-4753-8dd9-904514426a09',
    //         },
    //         {
    //             subtitle: 'Getting Started',
    //             description:
    //                 'To start receiving Chckup Chats, you will need to create a Chat Widget. Head to the "Widgets" tab and select "New Widget", and then for Widget type, select the "Chat" option!',
    //             imageUrl:
    //                 'https://firebasestorage.googleapis.com/v0/b/chckup-server.appspot.com/o/chckup-assets%2Fchat-widget-creation.PNG?alt=media&token=4d63e738-8fc6-4d25-8c6b-723aa355a74c',
    //         },
    //         {
    //             subtitle: 'One Last Step',
    //             description:
    //                 'You will need to add a small amount of code to your website to allow the widget to be visible to your clients. The instructions can be found on the widget page.',
    //             imageUrl:
    //                 'https://firebasestorage.googleapis.com/v0/b/chckup-server.appspot.com/o/chckup-assets%2Fchckup-widget-instructions.PNG?alt=media&token=1a9fc0d6-5322-4537-9717-8a18e5b6d262',
    //         },
    //         {
    //             subtitle: 'Here to Help',
    //             description:
    //                 'If you have any questions or concerns, please reach out to us! We are here to help.',
    //             imageUrl:
    //                 'https://firebasestorage.googleapis.com/v0/b/chckup-server.appspot.com/o/chckup-assets%2Fchat-widget.PNG?alt=media&token=ad31c9da-ba3c-4da0-b3f1-310a3eae0498',
    //         },
    //     ],
    // },
]

const TutorialModal: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [tutorialContent, setTutorialContent] = React.useState<TutorialType>()

    const [tutorialPage, setTutorialPage] = React.useState<number>(0)

    const { pathname } = useLocation()

    const getLocalStorageKey = (path: string): string => {
        return `${LocalStorageKeys.hasSeenTutorialPage}-${pathname.replaceAll(
            '/',
            '',
        )}`
    }

    React.useEffect(() => {
        const hasSeenTutorial =
            localStorage.getItem(getLocalStorageKey(pathname)) === 'true'

        const tmpTutorialContent = tutorialPages.find(
            page => page.path === pathname.replaceAll('/', ''),
        )

        if (tmpTutorialContent && !hasSeenTutorial) {
            setTutorialContent(tmpTutorialContent)
            setIsOpen(true)
        }

        localStorage.setItem(getLocalStorageKey(pathname), 'true')
    }, [pathname])

    return (
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{tutorialContent?.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {tutorialContent?.content
                        .slice(tutorialPage, tutorialPage + 1)
                        .map((content, index) => (
                            <Flex
                                flex={1}
                                flexDir="column"
                                key={index + 'content' + content.subtitle}
                            >
                                <Text size="lg" fontWeight={'bold'}>
                                    {content.subtitle}
                                </Text>
                                <Text>{content.description}</Text>
                                {content.imageUrl && (
                                    <Image
                                        border="2px solid #E2E8F0"
                                        mt={4}
                                        src={content.imageUrl}
                                        w="100%"
                                        h="225px"
                                        borderRadius="2xl"
                                        objectFit="cover"
                                    />
                                )}
                                {content.customComponent}
                            </Flex>
                        ))}
                    <Flex w="100%" justify="center" mt={3}>
                        {tutorialContent?.content.map((_, index) => (
                            <Button
                                key={index}
                                variant="ghost"
                                onClick={() => {
                                    setTutorialPage(index)
                                }}
                                boxSize={10}
                                borderRadius="2xl"
                            >
                                <Circle
                                    maxW={3}
                                    minW={3}
                                    maxH={3}
                                    minH={3}
                                    bg={
                                        tutorialPage === index
                                            ? 'brand.200'
                                            : 'gray.200'
                                    }
                                />
                            </Button>
                        ))}
                    </Flex>
                </ModalBody>

                <ModalFooter>
                    <Button
                        onClick={
                            tutorialPage ===
                            (tutorialContent?.content.length
                                ? tutorialContent?.content.length - 1
                                : 0)
                                ? () => setIsOpen(false)
                                : () => setTutorialPage(tutorialPage + 1)
                        }
                    >
                        {tutorialPage ===
                        (tutorialContent?.content.length
                            ? tutorialContent?.content.length - 1
                            : 0)
                            ? 'Finish'
                            : 'Next'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default TutorialModal
