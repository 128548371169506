import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/button'
import { BsStripe } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'

import { StripeAccountInfoFieldsFragment } from '~graphql/generated/graphql'

interface Props {
    accountInfo: StripeAccountInfoFieldsFragment
}

export default function StripeDashboardActionButton({ accountInfo }: Props) {
    const {
        onboarding_link: onboardingLink,
        login_link: loginLink,
        is_onboarded: isOnboarded,
        requirements,
    } = accountInfo

    const link = isOnboarded ? loginLink : onboardingLink

    return (
        <a
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
        >
            <Button
                bg="white"
                color="gray.900"
                borderWidth={1}
                borderColor="gray.200"
                shadow="blue.lg"
                pl={2}
                transition="0.2s"
                _hover={{ bg: 'gray.50', shadow: 'blue.xl' }}
                leftIcon={
                    <Icon as={BsStripe} color="#008cdd" fontSize="2xl" mr={2} />
                }
                rightIcon={<Icon as={FiExternalLink} ml={2} color="gray.900" />}
            >
                {getButtonText({ isOnboarded, requirements })}
            </Button>
        </a>
    )
}

function getButtonText({
    isOnboarded,
    requirements,
}: {
    isOnboarded: boolean
    requirements: StripeAccountInfoFieldsFragment['requirements']
}) {
    const { pending_verification, currently_due } = requirements || {}

    if (!isOnboarded) {
        if (pending_verification && pending_verification.length > 0) {
            if (currently_due && currently_due.length > 0) {
                return 'Finish setting up your account'
            }
            return 'Pending verification from Stripe'
        }

        return 'Get set up with Stripe'
    }

    return 'Go to your Stripe dashboard'
}
