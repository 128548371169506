import React from 'react'
import SharedInput from '~components/shared/form/shared-input'
import { useBookingSettingsContext } from './booking-settings-context'

export default function DefaultTriggerWordsInput() {
    const { defaultTriggerWords, setDefaultTriggerWords } =
        useBookingSettingsContext()

    return (
        <SharedInput
            label="Trigger words to alert clients that they may need to call the clinic for an emergency (comma separated)"
            value={defaultTriggerWords}
            onChange={e => setDefaultTriggerWords(e.target.value)}
            placeholder={'bloody, vomiting, diarrhea, seizure, collapse'}
            containerProps={{ width: '100%' }}
            labelProps={{
                fontSize: 'md',
            }}
        />
    )
}
