import React from 'react'
import { Text } from '@chakra-ui/layout'
import { useBookingSettingsContext } from './booking-settings-context'
import SettingSelect from '../form/setting-select'
import { getBookingEntityValue } from '~utils/helpers'

export default function BookingEntitySelect() {
    const {
        doesUseRoomsToSchedule,
        doesUseCliniciansToSchedule,
        setDoesUseRoomsToSchedule,
        setDoesUseCliniciansToSchedule,
    } = useBookingSettingsContext()

    return (
        <SettingSelect
            label="What does your clinic use to schedule appointments?"
            moreInfo={
                <>
                    <Text fontSize="sm" color="gray.500">
                        This selection determines how open slots on your
                        calendar are calculated.
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                        - <strong>"Clinician"</strong> will pull your
                        clinician's set hours and any existing appointments to
                        calculate open slots.
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                        - <strong>"Room"</strong> will pull any room's set hours
                        and any existing appointments to calculate open slots.
                    </Text>
                </>
            }
            options={['Clinician', 'Room', 'Clinician & Room']}
            value={getBookingEntityValue(
                doesUseRoomsToSchedule,
                doesUseCliniciansToSchedule
            )}
            setValue={(val: string) => {
                setBookingEntityValue(
                    val,
                    setDoesUseRoomsToSchedule,
                    setDoesUseCliniciansToSchedule
                )
            }}
        />
    )
}

function setBookingEntityValue(
    value: string,
    setDoesUseRoomsToSchedule: (value: boolean) => void,
    setDoesUseCliniciansToSchedule: (value: boolean) => void
) {
    if (value === 'Clinician & Room') {
        setDoesUseRoomsToSchedule(true)
        setDoesUseCliniciansToSchedule(true)
    } else if (value === 'Room') {
        setDoesUseRoomsToSchedule(true)
        setDoesUseCliniciansToSchedule(false)
    } else if (value === 'Clinician') {
        setDoesUseRoomsToSchedule(false)
        setDoesUseCliniciansToSchedule(true)
    }
}
