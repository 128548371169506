import { BoxProps } from '@chakra-ui/react'
import React from 'react'
import MenuSelect, {
    MenuSelectProps,
} from '~components/shared/form/menu-select'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { useUserContext } from '~config/user-context'
import SettingAdminTag from './setting-admin-tag'

interface Props extends MenuSelectProps {
    requiresAdmin?: boolean
}

export default function SettingSelect({
    requiresAdmin,
    ...sharedSelectProps
}: Props) {
    const { isAdmin } = useUserContext()

    const SHARED_PROPS: BoxProps = {
        display: 'flex',
        flexDir: 'row',
        w: 'full',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    }

    if (requiresAdmin && !isAdmin) return null

    return (
        <SharedStack direction={{ base: 'column', md: 'row' }} align="center">
            {requiresAdmin && <SettingAdminTag mb={4} />}
            <MenuSelect
                size="md"
                labelProps={{ fontSize: 'md' }}
                containerProps={SHARED_PROPS}
                {...sharedSelectProps}
            />
        </SharedStack>
    )
}
