import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export default function SharedInfoText({ children, ...props }: TextProps) {
    return (
        <Text color="gray.600" fontSize="14px" {...props}>
            {children}
        </Text>
    )
}
