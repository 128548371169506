import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function PawIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M48.1475 38.1588C44.9077 33.1045 39.3898 30.0866 33.3869 30.0866C27.3845 30.0866 21.8661 33.1045 18.6267 38.1588L10.8198 50.3377C9.52456 52.3586 8.9269 54.6927 9.09115 57.0871C9.2554 59.4822 10.1667 61.7124 11.7262 63.5378C13.2868 65.3626 15.349 66.6095 17.6896 67.1446C20.0302 67.6791 22.4297 67.4511 24.628 66.4848L24.7714 66.4211C30.3036 64.0365 36.6267 64.058 42.1452 66.4848C43.5684 67.1105 45.0763 67.4269 46.5979 67.4269C47.4252 67.4269 48.2574 67.333 49.0819 67.1451C51.4226 66.6106 53.4847 65.3637 55.0459 63.5389C56.6059 61.714 57.5178 59.4833 57.6826 57.0882C57.8474 54.6927 57.2497 52.3586 55.9544 50.3371L48.1475 38.1588ZM51.8384 60.7956C49.8526 63.117 46.6402 63.8509 43.8442 62.6215C40.5208 61.1598 36.9536 60.4292 33.3852 60.4292C29.8202 60.4292 26.2534 61.1592 22.9323 62.6193L22.8378 62.6611C20.0632 63.8344 16.898 63.0912 14.9342 60.7956C12.9506 58.4736 12.7249 55.1865 14.3728 52.6152L22.1803 40.4363C24.6401 36.5987 28.8292 34.3075 33.3869 34.3075C37.9445 34.3075 42.1342 36.5987 44.5946 40.4363L52.4009 52.6146C54.0494 55.1871 53.8231 58.4747 51.8384 60.7956Z"
            fill="currentColor"
        />
        <path
            d="M12.9227 38.1429C15.2452 37.2508 17.0096 35.3793 17.8912 32.8728C18.7295 30.4876 18.6608 27.7949 17.6968 25.29C16.7322 22.7868 14.9777 20.7433 12.7568 19.5354C10.4227 18.267 7.85907 18.0604 5.5404 18.9542C0.875608 20.7477 -1.26508 26.5122 0.769041 31.8071C2.39392 36.0214 6.18365 38.6818 10.0151 38.6818C10.9935 38.6818 11.9745 38.5082 12.9227 38.1429ZM4.70764 30.291C3.50903 27.1709 4.56207 23.8519 7.05652 22.8928C7.50696 22.7192 7.98321 22.633 8.46936 22.633C9.21972 22.633 9.99481 22.8379 10.7408 23.2438C12.0822 23.9727 13.1539 25.2384 13.7582 26.8067C14.3619 28.3761 14.4157 30.0328 13.9092 31.4726C13.4451 32.792 12.5568 33.7621 11.4088 34.2032L11.4066 34.2043C8.9154 35.1645 5.91009 33.4083 4.70764 30.291Z"
            fill="currentColor"
        />
        <path
            d="M28.0706 28.6013C33.9022 28.6013 38.6466 23.196 38.6466 16.552C38.6466 9.90637 33.9022 4.5 28.0706 4.5C22.2396 4.5 17.4957 9.90637 17.4957 16.552C17.4957 23.196 22.2396 28.6013 28.0706 28.6013ZM28.0706 8.7204C31.5747 8.7204 34.4262 12.2338 34.4262 16.552C34.4262 20.8685 31.5747 24.3809 28.0706 24.3809C24.5665 24.3809 21.7161 20.8685 21.7161 16.552C21.7161 12.2338 24.5665 8.7204 28.0706 8.7204Z"
            fill="currentColor"
        />
        <path
            d="M46.3354 31.5615H46.3359C47.233 31.8593 48.1481 32.0015 49.0594 32.0015C53.3123 32.0015 57.4568 28.9061 59.0383 24.1491C59.9491 21.4107 59.8892 18.5153 58.8697 15.9967C57.8029 13.3605 55.8259 11.4467 53.3024 10.6073C50.7788 9.76905 48.0482 10.1184 45.6152 11.5917C43.2911 12.9996 41.5102 15.2825 40.6011 18.0209C38.6812 23.7975 41.2537 29.8718 46.3354 31.5615ZM44.6061 19.3519C45.1999 17.5639 46.3348 16.0906 47.8021 15.2018C49.1594 14.3795 50.6404 14.1702 51.9714 14.6124C53.3018 15.0551 54.362 16.1087 54.9575 17.5798C55.6007 19.1695 55.6282 21.0295 55.0333 22.8175C53.8473 26.3859 50.5426 28.5117 47.6669 27.5565C44.7935 26.6007 43.4202 22.9202 44.6061 19.3519Z"
            fill="currentColor"
        />
        <path
            d="M68.6074 30.1553L68.6057 30.1536C64.5842 27.1829 58.5697 28.4705 55.1975 33.0254C51.8285 37.5826 52.3553 43.7091 56.3708 46.682C57.8353 47.7669 59.5656 48.2855 61.3366 48.2855C64.4233 48.2855 67.6346 46.71 69.7807 43.8129C73.1497 39.2558 72.6234 33.1293 68.6074 30.1553ZM66.3887 41.302C64.3974 43.9898 61.0307 44.8813 58.8823 43.29C56.7356 41.7008 56.6048 38.2214 58.5906 35.5353C59.9106 33.7522 61.8387 32.7601 63.6158 32.7601C64.5144 32.7601 65.3747 33.0139 66.0976 33.5484C68.2427 35.1392 68.3728 38.6175 66.3887 41.302Z"
            fill="currentColor"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M48.1476 38.1588C44.9077 33.1046 39.3898 30.0866 33.3869 30.0866C27.384 30.0866 21.8661 33.1046 18.6268 38.1588L10.8199 50.3377C9.5246 52.3581 8.92694 54.6927 9.09119 57.0872C9.25543 59.4822 10.1667 61.7124 11.7263 63.5383C13.2869 65.3626 15.349 66.6096 17.6896 67.1446C20.0303 67.6796 22.4297 67.4511 24.6281 66.4849L24.7753 66.4195C30.3069 64.0366 36.6279 64.0585 42.1452 66.4849C43.5685 67.1105 45.0764 67.4269 46.598 67.4269C47.4252 67.4269 48.2574 67.333 49.082 67.1451C51.4226 66.6107 53.4847 65.3637 55.0459 63.5389C56.606 61.7146 57.5178 59.4838 57.6826 57.0883C57.8474 54.6927 57.2497 52.3587 55.9545 50.3372L48.1476 38.1588Z"
            fill="currentColor"
        />
        <path
            d="M12.9227 38.1429C15.2452 37.2508 17.0096 35.3793 17.8907 32.8727C18.7295 30.4876 18.6608 27.7949 17.6968 25.29C16.7322 22.7867 14.9777 20.7433 12.7562 19.5353C10.4227 18.267 7.85907 18.0604 5.5404 18.9542C0.875608 20.7477 -1.26508 26.5122 0.76904 31.8071C2.39392 36.0214 6.18365 38.6818 10.0151 38.6818C10.9935 38.6818 11.9745 38.5082 12.9227 38.1429Z"
            fill="currentColor"
        />
        <path
            d="M28.0706 28.6012C33.9022 28.6012 38.6466 23.196 38.6466 16.552C38.6466 9.90637 33.9022 4.5 28.0706 4.5C22.2396 4.5 17.4957 9.90637 17.4957 16.552C17.4957 23.196 22.2396 28.6012 28.0706 28.6012Z"
            fill="currentColor"
        />
        <path
            d="M46.3354 31.5615H46.3359C47.233 31.8593 48.1481 32.0015 49.0594 32.0015C53.3122 32.0015 57.4568 28.9061 59.0383 24.1491C59.9491 21.4107 59.8892 18.5153 58.8697 15.9967C57.8029 13.3599 55.8259 11.4461 53.3024 10.6073C50.7783 9.7685 48.0487 10.1184 45.6158 11.5917C43.2916 12.999 41.5107 15.2825 40.6011 18.0209C38.6812 23.7975 41.2537 29.8718 46.3354 31.5615Z"
            fill="currentColor"
        />
        <path
            d="M68.6074 30.1553L68.6057 30.1536C64.5842 27.1829 58.5697 28.4705 55.1975 33.0254C51.8285 37.5826 52.3553 43.7091 56.3708 46.682C57.8353 47.7669 59.5656 48.2854 61.3366 48.2854C64.4232 48.2854 67.6346 46.71 69.7813 43.8129C73.1497 39.2558 72.6234 33.1293 68.6074 30.1553Z"
            fill="currentColor"
        />
    </Icon>
)
