import React, { ForwardRefRenderFunction } from 'react'
import { Box, InputProps, Input, TextProps, BoxProps } from '@chakra-ui/react'
import SharedLabel from './shared-label'
import SharedHelpText from './shared-help-text'

export interface SharedInputProps extends InputProps {
    label?: string
    labelProps?: TextProps
    moreInfo?: React.ReactElement
    containerProps?: BoxProps
    error?: string
}

const SharedInput: ForwardRefRenderFunction<
    HTMLInputElement,
    SharedInputProps
> = ({ label, labelProps, moreInfo, containerProps, error, ...props }, ref) => {
    return (
        <Box {...containerProps}>
            {(label || moreInfo) && (
                <Box>
                    {label && (
                        <SharedLabel mb={1} {...labelProps}>
                            {label}
                        </SharedLabel>
                    )}
                    {!!moreInfo && moreInfo}
                </Box>
            )}
            <Input
                ref={ref}
                focusBorderColor="brand.600"
                isInvalid={!!error}
                {...props}
            />
            {error && (
                <SharedHelpText mt={1} color="red.500">
                    {error}
                </SharedHelpText>
            )}
        </Box>
    )
}

export default React.forwardRef(SharedInput)
