import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function BirdIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M52.875 18.9844H69.8906C69.8906 11.9945 64.2243 6.32812 57.2344 6.32812H49.7599"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M52.875 27.4219H57.2344C61.8943 27.4219 65.6719 23.6443 65.6719 18.9844"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.786 9.36564L15.5457 37.5515"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.4692 10.0528C30.3384 5.39696 34.8946 2.10943 40.2188 2.10943C47.2087 2.10943 52.8751 7.77577 52.8751 14.7657V23.2032C52.8751 46.4907 33.9749 65.6719 10.6876 65.6719H2.10944L6.25787 57.2344"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M52.6664 27.4219H48.6562C41.6663 27.4219 35.9999 21.7555 35.9999 14.7656C35.9999 9.25496 39.5218 4.56694 44.4374 2.82952"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M27.2645 27.4219C36.8605 27.4219 42.0654 39.1451 35.5913 46.2282C33.8836 48.0966 27.0089 57.2344 6.25781 57.2344L17.2115 33.7885C19.0496 29.9011 22.9643 27.4219 27.2645 27.4219Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.2731 48.7432C13.8177 48.546 17.3151 47.8073 20.6432 46.5494C24.0606 45.2579 26.8334 43.4081 28.9406 41.0123"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.7812 67.9938V60.0222"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.2188 67.9938V53.6034"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M27.5625 69.8906C31.3125 67.0781 40.6875 67.0781 44.4375 69.8906"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M55.0547 14.7656C55.0547 10.7492 53.432 7.11085 50.8205 4.44615C48.743 4.04705 46.6661 4.20287 44.6953 5.01596C40.7361 6.65565 38.1797 10.483 38.1797 14.7656C38.1797 20.5809 42.9113 25.3125 48.7266 25.3125H54.9478C54.9813 24.6085 55.0547 23.9151 55.0547 23.2031V14.7656ZM44.5078 16.875C43.3429 16.875 42.3984 15.9306 42.3984 14.7656C42.3984 13.6004 43.3429 12.6563 44.5078 12.6563C45.6727 12.6563 46.6172 13.6004 46.6172 14.7656C46.6172 15.9306 45.6727 16.875 44.5078 16.875Z"
            fill="currentColor"
        />
        <path
            d="M29.5445 44.9087C29.7613 44.4797 34.0426 40.9295 31.4169 34.9737C30.2571 32.343 27.468 29.5312 23.1154 29.5312C19.6485 29.5312 16.4514 31.5561 14.9704 34.6913L9.54491 45.8681C11.6072 45.5158 13.7848 45.038 15.749 44.2948C18.8286 43.131 21.3397 41.4623 23.208 39.3386C23.9826 38.463 25.3153 38.3787 26.1846 39.147C27.0602 39.9175 27.1446 41.2502 26.3762 42.1237C24.0464 44.7727 20.9729 46.9713 17.2403 48.3823C14.1246 49.5598 10.7198 50.2318 7.4046 50.5351L5.34163 54.8979C16.0595 54.3355 24.2855 50.8887 29.5445 44.9087Z"
            fill="currentColor"
        />
        <path
            d="M0.274216 64.7696C-0.0368461 65.4227 0.0105445 66.189 0.397826 66.8007C0.783138 67.4104 1.45673 67.7812 2.17969 67.7812H10.6172C17.4147 67.7812 23.9867 66.2244 29.7422 63.4742V66.6048C28.4597 67.0134 27.2769 67.521 26.368 68.2035C25.4348 68.9019 25.2454 70.2243 25.9457 71.1554C26.6441 72.0886 27.9624 72.2739 28.8976 71.5777C31.9175 69.3159 40.2231 69.3159 43.243 71.5777C44.1775 72.2783 45.4992 72.0865 46.1949 71.1554C46.8952 70.2243 46.7058 68.9019 45.7726 68.2035C44.8636 67.521 43.6809 67.0134 42.3984 66.6048V54.2544C48.791 47.6927 53.1814 39.0268 54.5493 29.5312H48.7266C40.5856 29.5312 33.9609 22.9065 33.9609 14.7656C33.9609 8.76909 37.5411 3.41325 43.0803 1.11853C43.5345 0.930516 44.0017 0.815203 44.4644 0.674859C43.1315 0.278297 41.7487 0 40.2891 0C34.5233 0 29.2582 3.38442 26.8892 8.59205C25.9934 10.4795 22.619 17.603 18.5652 26.1617C20.0056 25.629 21.535 25.3125 23.1154 25.3125C28.4527 25.3125 33.1144 28.3632 35.2772 33.2722C38.9732 41.654 32.9193 47.4141 32.7126 47.8364C26.228 55.2092 16.0786 59.0036 2.85862 59.1709C1.26872 62.5283 0.274216 64.7696 0.274216 64.7696ZM38.1797 58.0298V65.7728C36.782 65.6492 35.3586 65.6492 33.9609 65.7728V61.028C35.432 60.1162 36.8297 59.1024 38.1797 58.0298Z"
            fill="currentColor"
        />
        <path
            d="M67.7109 21.0938H59.2734V23.2031C59.2734 25.3527 59.0844 27.4548 58.8121 29.5312H59.2734C63.9333 29.5312 67.7109 25.7536 67.7109 21.0938Z"
            fill="currentColor"
        />
        <path
            d="M61.3828 4.21875H56.0597C58.0854 7.23811 59.2734 10.8644 59.2734 14.7656V16.875H69.8203C70.9852 16.875 71.9297 15.9306 71.9297 14.7656C71.9297 8.94066 67.2078 4.21875 61.3828 4.21875Z"
            fill="currentColor"
        />
    </Icon>
)
