import React, {
    useMemo,
    useState,
    useEffect,
    useContext,
    createContext,
    PropsWithChildren,
} from 'react'

import {
    ClinicFieldsFragment,
    useUpdateClinicMutation,
} from '~graphql/generated/graphql'
import { SetStateFn } from '~config/constants'
import { handleMergeClinicSettings } from '../helpers'
import { useToastFeedback } from '~utils/hooks/use-toast-feedback'

export default function ReviewSettingsProvider({
    children,
    clinic,
}: PropsWithChildren<{ clinic: ClinicFieldsFragment }>) {
    return (
        <ReviewSettingsContext.Provider
            value={{ ...useReviewSettingsValues(clinic) }}
        >
            {children}
        </ReviewSettingsContext.Provider>
    )
}

export function useReviewSettingsContext() {
    return useContext(ReviewSettingsContext)
}

function useReviewSettingsValues(
    clinic: ClinicFieldsFragment
): ReviewSettingsContextType {
    const [maxReviewRating, setMaxReviewRating] = useState<number>(4)
    const [googleReviewLink, setGoogleReviewLink] = useState<
        string | null | undefined
    >()
    const [facebookReviewLink, setFacebookReviewLink] = useState<
        string | null | undefined
    >()
    const [shouldUsePrivateReviews, setShouldUsePrivateReviews] =
        useState<boolean>(true)

    useEffect(() => {
        if (!clinic.settings) return
        const { settings } = clinic

        setMaxReviewRating(settings.max_review_rating_for_notif ?? 4)
        setGoogleReviewLink(settings.google_review_link ?? undefined)
        setFacebookReviewLink(settings.facebook_review_link ?? undefined)
        setShouldUsePrivateReviews(settings.should_use_private_reviews || false)
    }, [clinic])

    const canSave = useMemo(() => {
        if (!clinic.settings) return false
        const { settings } = clinic

        return (
            settings.max_review_rating_for_notif !== maxReviewRating ||
            (settings.google_review_link ?? undefined) !== googleReviewLink ||
            (settings.facebook_review_link ?? undefined) !==
                facebookReviewLink ||
            (settings.should_use_private_reviews ?? true) !==
                shouldUsePrivateReviews
        )
    }, [
        clinic,
        maxReviewRating,
        googleReviewLink,
        facebookReviewLink,
        shouldUsePrivateReviews,
    ])

    const toasts = useToastFeedback(
        'Successfully updated review settings',
        'Error updating review settings'
    )

    const [handleUpdate, { loading }] = useUpdateClinicMutation({
        variables: {
            id: clinic?.id || '',
            data: {
                settings: {
                    max_review_rating_for_notif: maxReviewRating,
                    google_review_link: googleReviewLink ?? null,
                    facebook_review_link: facebookReviewLink ?? null,
                    should_use_private_reviews: shouldUsePrivateReviews,
                },
            },
        },
        update: (cache, res) => {
            handleMergeClinicSettings(clinic, cache, res)
        },
        ...toasts,
    })

    return {
        // Context Values
        canSave,
        isUpdateLoading: loading,

        // Actions
        handleUpdateSettings: handleUpdate,

        // Setting Values
        maxReviewRating,
        setMaxReviewRating,
        googleReviewLink,
        setGoogleReviewLink,
        facebookReviewLink,
        setFacebookReviewLink,
        shouldUsePrivateReviews,
        setShouldUsePrivateReviews,
    }
}

type ReviewSettingsContextType = {
    // Context Values
    canSave: boolean
    isUpdateLoading: boolean

    // Actions
    handleUpdateSettings: () => Promise<any>

    // Setting Values
    maxReviewRating: number
    setMaxReviewRating: SetStateFn<number>
    googleReviewLink: string | null | undefined
    setGoogleReviewLink: SetStateFn<string | null | undefined>
    facebookReviewLink: string | null | undefined
    setFacebookReviewLink: SetStateFn<string | null | undefined>
    shouldUsePrivateReviews: boolean
    setShouldUsePrivateReviews: SetStateFn<boolean>
}

const ReviewSettingsContext = createContext<ReviewSettingsContextType>(
    {} as ReviewSettingsContextType
)
