import React from 'react'
import { format, formatDistanceToNow } from 'date-fns'
import { Icon } from '@chakra-ui/icon'
import { FaPaw } from 'react-icons/fa'
import { Box, Flex, Text, HStack } from '@chakra-ui/layout'

import { speciesIconMap } from '~config/constants'
import GradientDivider from '~components/shared/gradient-divider'
import { getPatientNameFromAppointment } from '~utils/appointment-helpers'
import {
    AnimalGender,
    AppointmentFieldsFragment,
    FixedStatus,
    Species,
} from '~graphql/generated/graphql'
import SharedStack from '~components/shared/wrappers/shared-stack'
import InfoItem from '~components/shared/info-item'
import PatientStatusTag from '~components/shared/patient/status-tag'
import NewTag from '~components/shared/tags/new-tag'
import { FiExternalLink } from 'react-icons/fi'
import { navigate } from 'gatsby'
import IconButtonWithTooltip from '~components/shared/icon-button-with-tooltip'
import { getCleanedQueryParams } from '~utils/get-cleaned-query-params'

interface Props {
    appointment: AppointmentFieldsFragment
}

const AppointmentPatientSection: React.FC<Props> = ({ appointment }) => {
    const { patient: patientId } = getCleanedQueryParams()

    const patient = appointment.patient
    const species = patient?.clinic_species?.species

    return (
        <Box flex={1}>
            <Flex alignItems="center" justifyContent="space-between">
                <HStack spacing={4} py={1} px={2}>
                    <Icon as={FaPaw} color="gray.900" />
                    <Text fontWeight="semibold">Patient</Text>
                    {appointment.is_new_patient && <NewTag />}
                </HStack>
                {!patientId && (
                    <IconButtonWithTooltip
                        label="View patient details"
                        icon={<Icon as={FiExternalLink} />}
                        size="sm"
                        variant="subtle"
                        colorScheme="brand"
                        onClick={() => {
                            navigate(`?patient=${patient?.id}`)
                        }}
                    />
                )}
            </Flex>
            <GradientDivider my={2} />
            <SharedStack w="full" p={2} spacing={3}>
                <Flex
                    flexDir="row"
                    w="full"
                    justifyContent="space-between"
                    alignItems="center"
                    gridGap={4}
                    pb={1}
                >
                    <Box>
                        <HStack>
                            <Text fontWeight="bold">
                                {getPatientNameFromAppointment(appointment) ||
                                    'Unknown Name'}
                            </Text>
                            <PatientStatusTag patient={appointment.patient} />
                        </HStack>
                    </Box>
                    <Icon
                        as={speciesIconMap[species || Species.Other]}
                        boxSize={8}
                    />
                </Flex>
                <InfoItem
                    label="Species"
                    info={<Text fontSize="sm">{species}</Text>}
                    isShorter
                />
                <InfoItem
                    label="Breed"
                    info={
                        <Text fontSize="sm">
                            {patient?.clinic_breed?.description ||
                                patient?.breed}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Gender"
                    info={
                        <Text
                            fontSize="sm"
                            fontStyle={
                                patient?.gender !== AnimalGender.Unknown
                                    ? 'normal'
                                    : 'italic'
                            }
                        >
                            {patient?.gender || 'Unknown'}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Fixed Status"
                    info={
                        <Text
                            fontSize="sm"
                            fontStyle={
                                patient?.fixed_status !== FixedStatus.Unknown
                                    ? 'normal'
                                    : 'italic'
                            }
                        >
                            {patient?.fixed_status || 'Unknown'}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Birthdate"
                    info={
                        <Text fontSize="sm">
                            {patient?.birthday
                                ? format(
                                      new Date(patient?.birthday),
                                      'MMM dd, y'
                                  )
                                : '-'}
                        </Text>
                    }
                    isShorter
                />
                <InfoItem
                    label="Age"
                    info={
                        <Text fontSize="sm">
                            {patient?.birthday
                                ? formatDistanceToNow(
                                      new Date(patient?.birthday),
                                      {
                                          addSuffix: false,
                                      }
                                  )
                                : '-'}
                        </Text>
                    }
                    isShorter
                />
            </SharedStack>
        </Box>
    )
}

export default AppointmentPatientSection
