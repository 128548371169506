import React, { useRef, useState } from 'react'
import { Text, TextProps, Tooltip, useToast } from '@chakra-ui/react'

const ClickToCopyText: React.FC<
    TextProps & { copyOverride?: string; isDisabled?: boolean }
> = ({ children, copyOverride, isDisabled, ...props }) => {
    // const [isCopied, setIsCopied] = useState(false)
    const textRef = useRef<HTMLParagraphElement | null>(null)
    const { onCopy } = useClipboard()

    const toast = useToast({
        title: 'Copied',
        duration: 600,
        position: 'top',
    })

    return (
        <Tooltip
            isDisabled={isDisabled}
            label="Click to copy"
            openDelay={500}
            placement="right"
        >
            <Text
                ref={textRef}
                display="inline-block"
                onClick={
                    !isDisabled
                        ? () => {
                              onCopy(
                                  copyOverride ||
                                      textRef.current?.innerText ||
                                      ''
                              )
                              // setIsCopied(true)
                              toast()
                          }
                        : () => {}
                }
                _hover={
                    !isDisabled
                        ? { cursor: 'pointer', textDecoration: 'underline' }
                        : {}
                }
                // color={'initial'}
                {...props}
            >
                {children}
            </Text>
        </Tooltip>
    )
}

export const useClipboard = () => {
    const [isCopied, setIsCopied] = useState(false)

    const onCopy = (text: string) => {
        navigator.clipboard.writeText(text)
        setIsCopied(true)
    }

    return { isCopied, onCopy }
}

export default ClickToCopyText
