import React from 'react'
import { navigate } from 'gatsby'
import { formatDistanceStrict } from 'date-fns'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Text, Flex, HStack } from '@chakra-ui/layout'

import Clickable from '~components/shared/form/clickable'
import OutlineBox from '~components/shared/wrappers/outline-box'
import SpeciesIcon from '~components/shared/patient/species-icon'
import { PatientFieldsFragment, Species } from '~graphql/generated/graphql'
import { constructQueryString } from '~utils/construct-query-string'
import PatientStatusTag from '~components/shared/patient/status-tag'

interface Props {
    patient: PatientFieldsFragment
}

export default function ClientPatientItem({ patient }: Props) {
    function handleClick() {
        const queryString = constructQueryString({
            newPatient: patient?.id,
        })

        navigate(queryString)
    }

    return (
        <Clickable w="full" rounded="xl" onClick={handleClick}>
            <OutlineBox w="full" py={3} bg="transparent" shadow="none">
                <Flex
                    flexDir="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <HStack spacing={4}>
                        <SpeciesIcon patient={patient} />
                        <Box>
                            <HStack>
                                <Text fontWeight="semibold" noOfLines={1}>
                                    {patient.name || 'Unknown Name'}
                                </Text>
                                <PatientStatusTag patient={patient} />
                            </HStack>
                            <Text fontSize="sm" color="gray.500">
                                {patient.clinic_breed?.description ||
                                    patient?.breed ||
                                    'Unknown Breed'}{' '}
                                |{' '}
                                {patient.clinic_species?.species ||
                                    Species.Other}
                                {patient.gender ? ` | ${patient.gender}` : ''}
                                {patient.birthday
                                    ? ` | ${formatDistanceStrict(
                                          new Date(patient?.birthday || ''),
                                          new Date()
                                      )} old`
                                    : ''}
                            </Text>
                        </Box>
                    </HStack>
                    <ChevronRightIcon fontSize="xl" color="gray.500" />
                </Flex>
            </OutlineBox>
        </Clickable>
    )
}
