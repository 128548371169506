import { Tag, TagProps } from '@chakra-ui/react'
import React from 'react'

export default function SettingAdminTag(props: TagProps) {
    return (
        <Tag size="sm" color="gray.500" mb={2} {...props}>
            ADMIN
        </Tag>
    )
}
