import { ClientPreviewFieldsFragment } from '~graphql/generated/graphql'

export type SimpleClient = Pick<
    ClientPreviewFieldsFragment,
    'id' | 'first_name' | 'last_name' | 'email' | 'phone'
>

export enum DateFormat {
    Date = 'M/d/yyyy',
    Time = 'h:mm a',
    DateTime = 'M/d/yyyy h:mm a',
}
