import { ApolloCache } from '@apollo/client'
import { useUserContext } from '~config/user-context'
import { useClinic } from '~contexts/clinic-context'
import {
    ClinicFieldsFragment,
    GetClinicDocument,
    GetClinicQuery,
    UpdateClinicMutationResult,
} from '~graphql/generated/graphql'
import BookingSettingsPanel from './booking-settings'
// import ChatSettingsPanel from './chat-settings'
import LoyaltySettingsPanel from './loyalty-settings'
import MiscSettingsPanel from './misc-settings'
import PaymentSettingsPanel from './payment-settings'
import ReviewSettingsPanel from './review-settings'
import SyncSettingsPanel from './sync-settings'

export function handleMergeClinicSettings(
    clinic: ClinicFieldsFragment,
    cache: ApolloCache<any>,
    res: Pick<UpdateClinicMutationResult, 'data'>,
) {
    const { data } = res

    if (!data) return
    const { updateClinic } = data
    const oldClinic = cache.readQuery<GetClinicQuery>({
        query: GetClinicDocument,
        variables: {
            id: clinic?.id || '',
        },
    })
    if (oldClinic?.clinic) {
        cache.writeQuery({
            query: GetClinicDocument,
            variables: {
                id: clinic?.id || '',
            },
            data: { clinic: updateClinic },
        })
    }
}

export enum SettingsTab {
    Booking = 'booking',
    // Chat = 'chat',
    Reviews = 'reviews',
    Payments = 'payments',
    Loyalty = 'loyalty',
    Misc = 'misc',
    Sync = 'sync',
}

type Tab = {
    label: string
    value: SettingsTab
    component: React.FC<{ clinic: ClinicFieldsFragment }>
}

export function useSettingsTabs(): { tabs: Tab[] } {
    const { isAdmin } = useUserContext()
    const { clinic } = useClinic()
    const { features } = clinic || {}

    let tabs = []

    if (features?.scheduling_enabled) {
        tabs.push({
            label: 'Booking',
            value: SettingsTab.Booking,
            component: BookingSettingsPanel,
        })
    }

    // if (features?.messaging_enabled) {
    //     tabs.push({
    //         label: 'Chat',
    //         value: SettingsTab.Chat,
    //         component: ChatSettingsPanel,
    //     })
    // }

    if (features?.reviews_enabled) {
        tabs.push({
            label: 'Reviews',
            value: SettingsTab.Reviews,
            component: ReviewSettingsPanel,
        })
    }

    if (features?.payments_enabled) {
        tabs.push({
            label: 'Payments',
            value: SettingsTab.Payments,
            component: PaymentSettingsPanel,
        })
    }

    if (features?.loyalty_enabled) {
        tabs.push({
            label: 'Loyalty',
            value: SettingsTab.Loyalty,
            component: LoyaltySettingsPanel,
        })
    }

    tabs.push({
        label: 'Misc',
        value: SettingsTab.Misc,
        component: MiscSettingsPanel,
    })

    if (isAdmin) {
        tabs.push({
            label: 'Sync',
            value: SettingsTab.Sync,
            component: SyncSettingsPanel,
        })
    }

    return { tabs }
}
