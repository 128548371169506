import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

export default function OutlineBox({ children, ...props }: BoxProps) {
    return (
        <Box
            borderStyle="solid"
            borderWidth="1px"
            borderColor="gray.200"
            borderRadius="xl"
            px={4}
            py={2}
            boxShadow="blue.lg"
            overflow="hidden"
            {...props}
        >
            {children}
        </Box>
    )
}
