import React from 'react'
import DisableWrapper from '~components/shared/wrappers/disable-wrapper'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SettingInput from '../form/setting-input'
import SettingSwitch from '../form/setting-switch'
import { useLoyaltySettingsContext } from './loyalty-settings-context'

export default function InvoiceLoyaltySettings() {
    const {
        shouldAwardLoyaltyForInvoices,
        setShouldAwardLoyaltyForInvoices,
        invoiceLoyaltyPointsPerDollar,
        setInvoiceLoyaltyPointsPerDollar,
    } = useLoyaltySettingsContext()

    return (
        <SharedStack spacing={4}>
            <SettingSwitch
                label="Should award loyalty for invoices?"
                isChecked={shouldAwardLoyaltyForInvoices}
                onChange={e =>
                    setShouldAwardLoyaltyForInvoices(e.target.checked)
                }
            />
            <DisableWrapper isDisabled={!shouldAwardLoyaltyForInvoices}>
                <SettingInput
                    label="Points awarded per dollar"
                    type="number"
                    value={String(invoiceLoyaltyPointsPerDollar)}
                    onChange={v =>
                        setInvoiceLoyaltyPointsPerDollar(+v.target.value)
                    }
                    min={0}
                    maxW="168px"
                />
            </DisableWrapper>
        </SharedStack>
    )
}
