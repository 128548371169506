import React, { useState, createContext, useContext } from 'react'

type LoadingProviderType = {
    loading: boolean
    setLoading: (e: boolean) => void
}

export const LoadingContext = createContext<LoadingProviderType>({
    loading: false,
    setLoading: () => {},
})

export const LoadingProvider = ({
    children,
}: {
    children: React.ReactNode
}) => (
    <LoadingContext.Provider value={{ ...getLoading() }}>
        {children}
    </LoadingContext.Provider>
)

export const useLoading = () => {
    const context = useContext(LoadingContext)
    if (context === undefined) {
        throw new Error('useLoading must be used within a LoadingProvider')
    }
    return context
}

function getLoading(): LoadingProviderType {
    const [loading, setLoading] = useState<boolean>(false)

    return {
        loading,
        setLoading,
    }
}
