import React from 'react'
import { Box, Text, Flex, TextProps, FlexProps } from '@chakra-ui/layout'
import SharedLabel from './form/shared-label'

const InfoItem: React.FC<
    {
        label: string
        info: React.ReactNode
        size?: 'sm' | 'md' | 'lg'
        isShorter?: boolean
    } & FlexProps
> = ({ label, info, size = 'sm', isShorter, ...props }) => {
    return (
        <Flex
            flexDir={{ base: 'column', md: 'row' }}
            w="full"
            gridGap={{ base: 1, md: 4 }}
            {...props}
        >
            <Box>
                <SharedLabel fontSize={size}>{label}</SharedLabel>
            </Box>
            <Flex
                justifyContent={isShorter ? 'flex-end' : 'flex-start'}
                flex={isShorter ? 1 : 2}
            >
                {info}
            </Flex>
        </Flex>
    )
}

export const InfoText: React.FC<TextProps> = ({ children, ...props }) => (
    <Text
        fontSize="sm"
        color={!children ? 'gray.500' : 'gray.900'}
        fontStyle={!children ? 'italic' : 'regular'}
        {...props}
    >
        {children || 'Unknown'}
    </Text>
)

export default InfoItem
