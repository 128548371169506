import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function WolfIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M61.875 41.625C61.875 44.7317 64.3933 47.25 67.5 47.25H69.8906V36C69.8906 31.0293 65.8613 25.875 60.8906 25.875H51.863"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.875 25.875C34.875 25.875 32.625 18 24.75 15.75C24.75 15.75 25.875 11.25 24.75 7.875C24.75 7.875 19.125 7.875 15.75 12.375C15.75 12.375 11.25 13.5 7.875 18L2.10938 20.25V23.625C2.10938 26.1103 4.26473 28.125 6.75 28.125H14.625C14.625 28.125 12.375 31.5 12.375 36H15.75C15.75 36 14.625 42.75 19.125 45C27 33.75 34.875 34.875 34.875 25.875Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.125 45V57.375C19.125 57.375 15.75 59.625 15.75 64.125H28.125V52.875L30.375 48.375H33.75C42.75 48.375 42.75 45 49.5 45L52.875 50.625V57.375C52.875 57.375 49.5 60.75 49.5 64.125H61.875V35.8869C61.875 33.7984 61.0453 31.7953 59.5685 30.3185L57.4315 28.1815C55.9547 26.7047 53.9516 25.875 51.8631 25.875H34.875"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M31.5 20.25L27 23.625"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M36.7533 23.7298C36.7533 23.7298 36.2403 22.2479 34.858 20.3683C34.865 20.3823 35.0821 20.2002 28.2658 25.3126C27.3278 26.016 26.0072 25.8169 25.3126 24.8908C24.6136 23.9588 24.8026 22.6367 25.7345 21.9376C32.0958 17.1667 31.9438 17.2592 31.9544 17.2734V17.2727C30.6747 16.1973 29.0905 15.1694 27.1462 14.3575C27.3994 12.5183 27.5694 9.66248 26.7512 7.2083C26.4639 6.34669 25.6579 5.76562 24.75 5.76562C24.487 5.76562 18.4816 5.82173 14.5 10.5584C12.9918 11.088 9.47447 12.6311 6.56662 16.2463L1.34255 18.2849C0.532969 18.6007 0 19.3809 0 20.25V22.2188C0 25.8638 3.08686 28.8281 6.75 28.8281H11.3006C10.7497 30.3681 10.2656 33.7638 10.2656 36C10.2656 37.1649 11.2101 38.1094 12.375 38.1094H13.5201C13.555 40.0087 14.4142 42.7762 16.284 44.9165C24.2692 34.5226 36.7533 30.9666 36.7533 23.7298ZM12.9375 20.9534C11.7726 20.9534 10.8281 20.009 10.8281 18.844C10.8281 17.6791 11.7726 16.7347 12.9375 16.7347C14.1024 16.7347 15.0469 17.6791 15.0469 18.844C15.0469 20.009 14.1024 20.9534 12.9375 20.9534Z"
            fill="currentColor"
        />
        <path
            d="M57.5169 26.6901C55.6311 24.8043 53.1238 23.7656 50.4568 23.7656C47.3162 23.7656 44.1477 23.7656 40.9715 23.7656C40.9715 34.0968 26.9228 37.3854 19.125 48.1693V56.3905C17.8536 57.5834 15.75 60.1571 15.75 64.125C15.75 65.2899 16.6944 66.2344 17.8594 66.2344H28.125C29.2899 66.2344 30.2344 65.2899 30.2344 64.125V53.373L31.6787 50.4844C35.3468 50.4844 38.284 50.5579 42.9185 49.1053C43.5528 48.9064 44.1537 48.7017 44.7377 48.5027C46.6099 47.8647 46.8582 47.3008 49.0328 47.1493L51.4688 51.2092V56.5535C50.327 57.8713 48.0938 60.8653 48.0938 64.125C48.0938 65.2899 49.0382 66.2344 50.2031 66.2344H60.4688C61.6337 66.2344 62.5781 65.2899 62.5781 64.125V35.8868C62.5781 33.2198 61.5395 30.7126 59.6537 28.8269L57.5169 26.6901Z"
            fill="currentColor"
        />
        <path
            d="M60.8906 23.7656H60.5586L62.6368 25.8438C65.3195 28.5263 66.7969 32.0932 66.7969 35.8868V48.7117C67.745 49.1272 68.7909 49.3594 69.8906 49.3594C71.0556 49.3594 72 48.4149 72 47.25V36C72 29.9541 67.1338 23.7656 60.8906 23.7656Z"
            fill="currentColor"
        />
    </Icon>
)
