import React from 'react'
import { Tag, TagLabel, TagProps } from '@chakra-ui/tag'
import { PatientFieldsFragment } from '~graphql/generated/graphql'

interface Props extends TagProps {
    patient?: Pick<PatientFieldsFragment, 'is_active' | 'is_deceased'> | null
}

export default function PatientStatusTag({ patient, ...rest }: Props) {
    return (
        <Tag
            size="sm"
            rounded="md"
            colorScheme={patient?.is_active ? 'brand' : 'gray'}
            {...rest}
        >
            <TagLabel>
                {patient ? getStatusLabel(patient) : 'Inactive'}
            </TagLabel>
        </Tag>
    )
}

function getStatusLabel(
    patient: Pick<PatientFieldsFragment, 'is_active' | 'is_deceased'>
) {
    if (patient.is_deceased) {
        return 'Deceased'
    }

    if (patient.is_active) {
        return 'Active'
    }

    return 'Inactive'
}
