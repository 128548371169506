import React from 'react'
import { useMiscSettingsContext } from './misc-settings-context'
import SaveActionsBtn from '../save-changes-btn'

export default function MiscSaveActions() {
    const { canSave, isUpdateLoading, handleUpdateSettings } =
        useMiscSettingsContext()

    return (
        <SaveActionsBtn
            canSave={canSave}
            isUpdateLoading={isUpdateLoading}
            handleUpdateSettings={handleUpdateSettings}
        />
    )
}
