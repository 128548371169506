import { defineStyleConfig } from '@chakra-ui/react'

export const Text = defineStyleConfig({
    variants: {
        sectionHeading: {
            fontWeight: 800,
            color: 'dark',
            fontSize: 'lg',
        },
    },
})
