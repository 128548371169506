import React from 'react'
import { Box, Text } from '@chakra-ui/layout'

import SharedInput from '~components/shared/form/shared-input'
import SharedSwitch from '~components/shared/form/shared-switch'
import { useReviewSettingsContext } from './review-settings-context'

export default function ReviewNotificationSettings() {
    const { maxReviewRating, setMaxReviewRating } = useReviewSettingsContext()

    return (
        <Box w="full">
            <SharedSwitch
                label="Send review notifications?"
                labelProps={{ fontSize: 'md' }}
                moreInfo={
                    <>
                        <Text fontSize="sm" color="gray.500">
                            - <strong>Enabled: </strong> You will receive an
                            email notification when a review is submitted below
                            a certain threshold.
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                            - <strong>Disabled: </strong> You will not receive
                            any email notifications for new reviews.
                        </Text>
                    </>
                }
                isChecked={(maxReviewRating ?? 0) > 0}
                onChange={e => {
                    if (e.target.checked) {
                        setMaxReviewRating(4)
                    } else {
                        setMaxReviewRating(0)
                    }
                }}
                size="md"
                showActiveLabel
                containerProps={{
                    display: 'flex',
                    flexDir: 'row',
                    w: 'full',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                }}
            />
            {(maxReviewRating ?? 0) > 0 && (
                <SharedInput
                    label="Send notification if review rating is less than or equal to"
                    labelProps={{ fontSize: 'sm', fontWeight: 'medium' }}
                    type="number"
                    min={1}
                    max={5}
                    size="sm"
                    maxW="240px"
                    value={maxReviewRating}
                    onChange={e => setMaxReviewRating(Number(e.target.value))}
                    containerProps={{
                        display: 'flex',
                        flexDir: 'row',
                        w: 'full',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                />
            )}
        </Box>
    )
}
