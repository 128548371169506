import React, { ReactNode, PropsWithChildren } from 'react'
import ErrorView from '../error-view'
import EmptyView from '../empty-view'
import LoadingView from '../loading-view'

interface Props {
    loading?: boolean
    empty?: boolean
    error?: boolean
    loadingComponent?: ReactNode
    errorComponent?: ReactNode
    emptyComponent?: ReactNode
    onAfterLoad?: () => void
}

export default function AsyncWrapper({
    loading,
    error,
    empty,
    loadingComponent,
    errorComponent,
    emptyComponent,
    onAfterLoad,
    children,
}: PropsWithChildren<Props>) {
    if (loading) {
        return <>{loadingComponent ?? <LoadingView py={16} />}</>
    }

    if (onAfterLoad) {
        onAfterLoad()
    }

    if (error) {
        return <>{errorComponent ?? <ErrorView />}</>
    }

    if (empty) {
        return <>{emptyComponent ?? <EmptyView />}</>
    }

    return <>{children}</>
}
