import React from 'react'
import { Box } from '@chakra-ui/layout'

import SharedInput from '~components/shared/form/shared-input'
import SharedSwitch from '~components/shared/form/shared-switch'
import { useReviewSettingsContext } from './review-settings-context'

export default function GoogleReviewLinkInput() {
    const { googleReviewLink, setGoogleReviewLink } = useReviewSettingsContext()

    return (
        <>
            <Box w="full">
                <SharedSwitch
                    label="Allow Google reviews?"
                    labelProps={{
                        fontSize: 'md',
                    }}
                    isChecked={isDefined(googleReviewLink)}
                    onChange={e => {
                        if (e.target.checked) {
                            setGoogleReviewLink('')
                        } else {
                            setGoogleReviewLink(undefined)
                        }
                    }}
                    size="md"
                    showActiveLabel
                    containerProps={{
                        display: 'flex',
                        flexDir: 'row',
                        w: 'full',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1,
                    }}
                />
                {isDefined(googleReviewLink) && (
                    <SharedInput
                        label="Link for Google Reviews"
                        labelProps={{
                            fontSize: 'sm',
                            fontWeight: 'medium',
                        }}
                        size="sm"
                        flex={1}
                        value={googleReviewLink || ''}
                        onChange={e => setGoogleReviewLink(e.target.value)}
                        placeholder="Link to your GoogleMyBusiness page"
                        containerProps={{
                            w: 'full',
                        }}
                    />
                )}
            </Box>
        </>
    )
}

function isDefined(input: string | null | undefined): boolean {
    return input !== null && input !== undefined
}
