import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import { Button } from '@chakra-ui/button'
import { Skeleton } from '@chakra-ui/skeleton'
import { Box, Text, VStack } from '@chakra-ui/layout'

import { UserRoutes } from '~config/routes'
import { useBookingSettingsContext } from './booking-settings-context'
import { useGetAllServicesByClinicIdQuery } from '~graphql/generated/graphql'
import { useClinic } from '~contexts/clinic-context'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import SettingSelect from '../form/setting-select'

export default function DetailedServicesSelect() {
    const { clinic } = useClinic()
    const { isUsingDetailedServices, setIsUsingDetailedServices } =
        useBookingSettingsContext()

    const { data: servicesData, loading: servicesLoading } =
        useGetAllServicesByClinicIdQuery({
            variables: { clinicId: clinic?.id || '' },
            skip: !clinic?.id,
        })

    const emptyServices = useMemo(
        () =>
            servicesData?.allServicesByClinicId?.length === 0 ||
            servicesData?.allServicesByClinicId === null ||
            !servicesData?.allServicesByClinicId.some(s => s.is_active),
        [servicesData]
    )

    return (
        <Skeleton isLoaded={!servicesLoading} w="full">
            <SettingSelect
                label="Would you like to use simplified services or detailed services?"
                moreInfo={
                    <>
                        <Text fontSize={'sm'} color="gray.500">
                            These selections determine how services appear when
                            clients are booking appointments.
                        </Text>
                        <Text fontSize={'sm'} color="gray.500">
                            - <strong>"Simple"</strong> services will only show
                            clients the options for a "Wellness" or
                            "Sick/Injured" visit.
                        </Text>
                        <Text fontSize={'sm'} color="gray.500">
                            - <strong>"Detailed" </strong>
                            services will show all of the services that you
                            specify in the{' '}
                            <u>
                                <Link to={UserRoutes.Services}>
                                    Manage Services
                                </Link>
                            </u>{' '}
                            page.
                        </Text>
                    </>
                }
                value={isUsingDetailedServices ? 'Detailed' : 'Simple'}
                setValue={(val: string) => {
                    setIsUsingDetailedServices(val === 'Detailed')
                }}
                options={['Simple', 'Detailed']}
                error={
                    emptyServices ? (
                        <VStack alignItems="flex-start" spacing={6}>
                            <Box>
                                <Text
                                    mb={2}
                                    fontWeight="bold"
                                    color="yellow.500"
                                >
                                    You don't have any active services.
                                </Text>
                                <Text fontSize="sm">
                                    Clients won't be able to select a service
                                    until you add some.
                                </Text>
                            </Box>
                            <Link to={UserRoutes.Services}>
                                <Button
                                    size="sm"
                                    alignSelf="flex-end"
                                    colorScheme="yellow"
                                    rightIcon={<ArrowForwardIcon />}
                                >
                                    Go to services
                                </Button>
                            </Link>
                        </VStack>
                    ) : undefined
                }
            />
        </Skeleton>
    )
}
