import React from 'react'
import { Image } from '@chakra-ui/image'
import { Center } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'

import Logo from '~images/logo/logo.svg'
import { CenterProps } from '@chakra-ui/react'

export default function LoadingView(props: CenterProps) {
    return (
        <Center flexDir="column" w="full" h="full" gridGap={6} {...props}>
            <Spinner
                size="xl"
                color="brand.500"
                thickness="4px"
                emptyColor="gray.200"
            />
            <Image src={Logo} width={32} objectFit="contain" />
        </Center>
    )
}
