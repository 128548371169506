import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function PrimateIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M25.5723 44.5895C20.3471 45.3854 16.0053 49.6212 15.1029 54.8291C14.7052 57.1238 14.9262 59.4719 15.6263 61.4531H14.9062C10.2464 61.4531 6.46869 65.2307 6.46869 69.8906L26.878 69.8907"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M33.7433 24.728C42.465 28.0643 48.7969 36.6523 48.7969 46.5468C48.7969 59.3615 38.2679 69.8907 25.4532 69.8907"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.1394 48.6563H6.32812C3.99811 48.6563 2.10938 46.7675 2.10938 44.4375C2.10938 42.1075 3.99811 40.2188 6.32812 40.2188H14.9062C17.2361 40.2188 19.125 38.33 19.125 36V31.4911"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M43.3526 61.4531H53.0157C55.3455 61.4531 57.2344 59.5644 57.2344 57.2344V40.2187C57.2344 35.4606 59.8732 31.3075 63.7646 29.1461C66.5117 27.6203 69.8907 29.79 69.8907 32.9324C69.8907 34.3758 69.2058 35.811 67.9292 36.4846C66.5882 37.1921 65.6719 38.6007 65.6719 40.2187V57.2344C65.6719 64.2243 60.0056 69.8906 53.0157 69.8906H27.5625V69.796"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.2187 12.6562C40.2187 9.1613 37.3856 6.32812 33.8906 6.32812C33.1838 6.32812 32.5164 6.47072 31.8817 6.68447C29.1937 3.87661 25.4284 2.10938 21.2344 2.10938C17.0404 2.10938 13.275 3.87661 10.5871 6.68447C9.95231 6.47072 9.28491 6.32812 8.57812 6.32812C5.08317 6.32812 2.10938 9.1613 2.10938 12.6562C2.10938 15.4735 3.96253 17.8329 6.50728 18.6528C7.39462 25.9591 13.6886 31.6406 21.2344 31.6406C28.7802 31.6406 34.9335 25.9591 35.8208 18.6528C38.3656 17.8329 40.2187 15.4735 40.2187 12.6562Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.7969 14.7656C12.7969 12.4356 14.6856 10.5469 17.0156 10.5469C18.5868 10.5469 19.9031 11.4438 20.6293 12.7176C20.8315 12.698 21.0271 12.6562 21.2344 12.6562C21.4417 12.6562 21.6373 12.698 21.8395 12.7176C22.5657 11.4438 23.8819 10.5469 25.4531 10.5469C27.7831 10.5469 29.6719 12.4356 29.6719 14.7656C29.6719 16.3368 28.775 17.6531 27.5012 18.3793C27.5207 18.5813 27.5625 18.7771 27.5625 18.9844C27.5625 22.4738 24.7238 25.3125 21.2344 25.3125C17.7449 25.3125 14.9062 22.4738 14.9062 18.9844C14.9062 18.7771 14.948 18.5813 14.9676 18.3793C13.6938 17.6531 12.7969 16.3368 12.7969 14.7656Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M4.39791 16.684C5.28525 23.9903 11.4386 29.6719 18.9844 29.6719C26.5302 29.6719 32.6835 23.9903 33.5708 16.684C36.1156 15.8642 37.9688 13.5048 37.9688 10.6875C37.9688 7.19255 35.1356 4.35938 31.6406 4.35938C30.9338 4.35938 30.2664 4.50197 29.6317 4.71572C26.9438 1.90786 23.1784 0 18.9844 0C14.7904 0 11.025 1.90786 8.33709 4.71572C7.70231 4.50197 7.03491 4.35938 6.32812 4.35938C2.83317 4.35938 0 7.19255 0 10.6875C0 13.5048 1.85316 15.8642 4.39791 16.684ZM14.7656 6.46875C16.36 6.46875 17.8432 7.07231 18.9844 8.14148C20.1255 7.07231 21.6087 6.46875 23.2031 6.46875C26.6926 6.46875 29.5312 9.30741 29.5312 12.7969C29.5312 14.6014 28.7567 16.2658 27.4116 17.4503C27.1849 21.9018 23.4915 25.4531 18.9844 25.4531C14.4772 25.4531 10.7838 21.9018 10.5571 17.4503C9.21206 16.2658 8.4375 14.6014 8.4375 12.7969C8.4375 9.30741 11.2762 6.46875 14.7656 6.46875Z"
            fill="currentColor"
        />
        <path
            d="M40.4 72H55.125C62.1041 72 67.7812 66.1822 67.7812 59.2031V42.3281C67.7812 40.7606 68.6464 39.3289 70.039 38.5955C71.2482 37.9548 72 36.5953 72 35.0421C72 33.5301 71.1823 32.1107 69.8597 31.3342C68.6188 30.6001 67.1399 30.5546 65.8737 31.2559C61.8466 33.4931 59.3438 37.7345 59.3438 42.3281V59.2031C59.3438 61.5288 57.4507 63.4219 55.125 63.4219H49.4535C47.0621 66.8226 43.9843 69.8503 40.4 72Z"
            fill="currentColor"
        />
        <path
            d="M4.21875 46.5469H12.8744C15.0321 44.436 17.8255 42.9719 20.896 42.5053C22.0558 42.3262 23.1228 43.1213 23.2979 44.2727C23.473 45.4241 22.682 46.4995 21.5305 46.6746C17.2626 47.324 13.5513 50.9906 12.8211 55.1904C12.5831 56.5618 12.6863 57.8964 12.9552 59.2031H12.8226C12.8309 59.2616 12.8264 59.3218 12.8355 59.3803C7.93898 60.2888 4.21875 64.7346 4.21875 69.8906C4.21875 71.0566 5.1622 72 6.32812 72H26.738C38.0919 72 50.625 61.2101 50.625 46.5469C50.625 36.6448 44.6842 27.6318 35.7473 23.5593C32.5815 29.7193 26.2339 33.8906 18.9844 33.8906C18.2668 33.8906 17.5736 33.7892 16.875 33.7101V36C16.875 37.1638 15.9294 38.1094 14.7656 38.1094H4.21875C1.88873 38.1094 0 39.9981 0 42.3281C0 44.6581 1.88873 46.5469 4.21875 46.5469Z"
            fill="currentColor"
        />
        <path
            d="M16.2188 13.1094C16.2188 14.2743 15.2743 15.2188 14.1094 15.2188C12.9444 15.2188 12 14.2743 12 13.1094C12 11.9444 12.9444 11 14.1094 11C15.2743 11 16.2188 11.9444 16.2188 13.1094Z"
            fill="currentColor"
        />
        <path
            d="M26.2188 13.1094C26.2188 14.2743 25.2743 15.2188 24.1094 15.2188C22.9444 15.2188 22 14.2743 22 13.1094C22 11.9444 22.9444 11 24.1094 11C25.2743 11 26.2188 11.9444 26.2188 13.1094Z"
            fill="currentColor"
        />
    </Icon>
)
