import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function TurtleIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M58.0472 47.9953L58.3003 53.4839C58.3636 54.8438 57.278 55.9814 55.9167 55.9814H52.6162C51.0272 55.9814 49.8825 54.457 50.3241 52.9313L50.7867 51.3309C51.2044 49.8895 50.1427 48.4383 48.6422 48.4088C43.9959 48.3159 39.728 47.7886 36.5667 46.9645C33.8484 46.2544 31.2202 45.3052 28.703 44.1422L28.9308 53.5359C28.9631 54.8761 27.8859 55.9814 26.5458 55.9814H22.8375C21.3609 55.9814 20.2387 54.6539 20.4834 53.1984L21.3848 47.8505C22.0781 43.7288 20.5537 39.5606 17.4037 36.8114C13.7588 33.6319 2.10938 25.4264 2.10938 25.4264C2.10938 25.4264 2.40469 14.3213 11.2247 16.2422C19.5061 18.0464 16.6837 23.94 22.5127 30.6478C31.1133 36.5934 38.6297 39.7786 49.0837 39.7786H63.5976L69.8906 43.1705C66.3314 44.505 62.2251 47.5327 58.0472 47.9953Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinejoin="round"
        />
        <path
            d="M63.5977 39.7786H49.0838C38.6297 39.7786 31.1133 36.5934 22.5127 30.6478C22.5127 30.6478 23.0386 27.3825 25.7653 24.1158C28.4906 20.8491 33.4181 17.5838 42.2227 17.5838C59.8303 17.5838 63.5977 39.7786 63.5977 39.7786Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinejoin="round"
        />
        <path
            d="M52.2296 20.5208L50.8144 27.8405L43.2486 29.2099L36.4532 25.0638L37.8419 17.8817"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinejoin="round"
        />
        <path
            d="M36.4532 25.0638L29.2521 34.8139"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinejoin="round"
        />
        <path
            d="M43.2486 29.2098L42.1278 39.2527"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinejoin="round"
        />
        <path
            d="M50.8144 27.8405L54.715 39.7782"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M70.8915 41.3114L69.49 40.556C66.355 41.1743 58.5375 42.5343 50.4246 42.5343C39.8551 42.5343 28.7844 38.8024 18.4089 31.7422C17.731 31.2809 17.3818 30.4684 17.5137 29.6591C17.5513 29.4289 18.1111 26.1757 20.2785 22.1832C19.0267 18.849 17.5992 15.471 11.6729 14.1798C8.01675 13.3833 5.59927 14.5098 4.21481 15.5952C0.289689 18.6721 0.0187049 24.6925 0.000704856 25.3688C-0.0179983 26.0744 0.317392 26.7428 0.894517 27.1494C1.01011 27.2308 12.4947 35.3274 16.0163 38.3998C18.6481 40.6958 19.8772 44.0975 19.3042 47.4992L18.4035 52.8459C18.1832 54.1531 18.5479 55.4835 19.4039 56.4958C20.2598 57.5082 21.5111 58.0887 22.8369 58.0887H26.5451C27.7668 58.0887 28.9094 57.6072 29.7626 56.7329C30.6159 55.8586 31.0694 54.7045 31.0397 53.4834L30.8905 47.3515C32.5787 47.9977 34.2996 48.5505 36.0346 49.0032C39.4103 49.8842 43.8725 50.4214 48.5993 50.516C48.6219 50.5164 48.6821 50.5177 48.7323 50.5861C48.7852 50.6579 48.7673 50.7199 48.7606 50.7433L48.2977 52.3425C47.9008 53.713 48.1649 55.1533 49.0222 56.2939C49.8794 57.4345 51.1895 58.0887 52.6162 58.0887H55.9167C57.1392 58.0887 58.3245 57.5816 59.1687 56.6973C60.0127 55.8132 60.4644 54.6057 60.4079 53.3848L60.2383 49.724C62.6161 49.1092 64.8747 47.9469 66.9184 46.8952C68.2432 46.2135 69.4946 45.5695 70.6312 45.1433C71.4057 44.8529 71.939 44.1364 71.9951 43.3111C72.0512 42.4858 71.6196 41.7039 70.8915 41.3114ZM11.224 25.1515C10.0415 25.1515 9.1658 24.185 9.11461 23.0421C9.06356 21.9029 10.1206 20.9327 11.224 20.9327C12.4065 20.9327 13.2822 21.8993 13.3334 23.0421C13.3844 24.1812 12.3273 25.1515 11.224 25.1515Z"
            fill="currentColor"
        />
        <path
            d="M46.7636 29.6539L45.8255 38.0597C47.3687 38.2287 48.9032 38.3157 50.4246 38.3157C52.0284 38.3157 53.6289 38.2577 55.1849 38.1611L52.0902 28.6899L46.7636 29.6539Z"
            fill="currentColor"
        />
        <path
            d="M21.9922 29.0593C23.8455 30.2651 25.7182 31.3556 27.6024 32.3319L35.0629 22.2307L36.2655 16.0108C36.222 16.0191 36.1789 16.0276 36.1357 16.036C29.5653 17.3303 24.2023 22.103 22.1673 28.483C22.1012 28.6897 22.0431 28.8824 21.9922 29.0593Z"
            fill="currentColor"
        />
        <path
            d="M55.6989 26.1506L59.5029 37.7924C61.607 37.5663 63.5288 37.2954 65.1357 37.0389C64.5373 34.7839 63.3796 31.2247 61.3489 27.6398C59.9646 25.1959 58.381 23.1074 56.6204 21.384L55.6989 26.1506Z"
            fill="currentColor"
        />
        <path
            d="M39.3922 22.0622L45.2552 25.6395L51.7272 24.468L52.8916 18.4462C49.7239 16.4836 46.1405 15.4731 42.2228 15.4731C41.6876 15.4731 41.1681 15.4869 40.659 15.5092L39.3922 22.0622Z"
            fill="currentColor"
        />
        <path
            d="M42.5963 28.959L37.5929 25.9062L31.4866 34.1738C34.8701 35.6301 38.2735 36.7125 41.6538 37.4041L42.5963 28.959Z"
            fill="currentColor"
        />
    </Icon>
)
