import React from 'react'
import SettingSwitch from '../form/setting-switch'
import { useMiscSettingsContext } from './misc-settings-context'

export default function MessagePrioritySwitch() {
    const { useMsgAutoPriority, setUseMsgAutoPriority } =
        useMiscSettingsContext()

    return (
        <SettingSwitch
            label="Use automatic message priority?"
            isChecked={useMsgAutoPriority}
            onChange={e => setUseMsgAutoPriority(e.target.checked)}
        />
    )
}
