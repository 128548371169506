import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function LionIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M36 49.5562V49.5574C37.3489 52.2551 36.8201 55.5142 34.6873 57.6471C33.056 59.2784 31.4812 60.8531 31.4812 60.8531H31.4836C35.2262 60.8531 38.2593 63.8875 38.2593 67.6291V69.8906H15.6656V60.8521C15.6656 53.8502 19.3609 47.368 25.3855 43.8005C28.1024 42.1919 31.0011 40.4747 33.526 38.979C37.8605 36.4123 40.5189 31.749 40.5189 26.7117"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47.2968 58.5937L38.2593 65.3718"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.6657 60.8531V58.5937C15.6657 57.3952 15.19 56.2463 14.3417 55.399C13.4944 54.5517 12.3455 54.075 11.1468 54.075C8.65145 54.075 6.62814 56.0983 6.62814 58.5937V65.3718C6.62814 67.8674 4.60483 69.8907 2.10944 69.8907"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.3343 49.5562V63.1125C57.7815 64.5597 58.5937 66.5208 58.5937 68.5666V69.8906H47.2968V49.5562"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M65.3718 14.9121V14.911C65.3718 11.5163 64.023 8.25932 61.6224 5.85885C59.222 3.45824 55.9648 2.10937 52.5703 2.10937H51.8133C47.6189 2.10937 43.5971 3.77563 40.6316 6.74113C37.6661 9.70649 36 13.7294 36 17.9228V17.925V17.9262C36 22.0472 38.4276 25.782 42.1941 27.4562C44.1134 28.3091 46.2586 29.2625 48.2853 30.1629C53.1803 32.3387 56.3342 37.1929 56.3342 42.5488V49.5562H56.3354C62.1207 43.7699 65.3718 35.9221 65.3718 27.7386V27.4844"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M69.8906 17.925C69.8906 17.925 63.6062 15.2319 58.9598 13.2402C57.8776 12.777 56.6338 12.8877 55.6497 13.5362C54.667 14.1847 54.075 15.2838 54.075 16.461V16.4632C54.075 19.2479 55.181 21.9185 57.15 23.8875C59.119 25.8565 61.7896 26.9626 64.5743 26.9626H69.8906V17.925Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M58.4438 49.119C58.4438 44.601 55.776 40.4958 51.6473 38.6606L39.6138 33.2428C38.7727 32.8681 37.9747 32.4271 37.2213 31.9313C36.1966 34.0784 34.5518 35.9203 32.4512 37.1642L24.3108 41.9856C19.9621 44.5607 16.6935 48.5546 14.9576 53.1724C13.8756 52.4116 12.5595 51.9657 11.1471 51.9657C7.49236 51.9657 4.51898 54.9391 4.51898 58.5938V65.3719C4.51884 66.7004 3.43786 67.7812 2.10938 67.7812C0.944437 67.7812 0 68.7257 0 69.8906C0 71.0556 0.944437 72 2.10938 72C5.76408 72 8.73759 69.0266 8.73759 65.3719V58.5938C8.73759 57.2653 9.81844 56.1845 11.1469 56.1845C12.4613 56.1845 13.5564 57.2473 13.5564 58.5938V69.8906C13.5564 71.0556 14.5008 72 15.6658 72H34.0407C35.2056 72 36.15 71.0556 36.15 69.8906V67.629C36.15 65.0558 34.0567 62.9625 31.4835 62.9625C30.6304 62.9625 29.86 62.4485 29.5336 61.6603C29.2071 60.8721 29.3864 59.9648 29.9897 59.3615L33.1958 56.1555C34.6891 54.6621 35.0578 52.3897 34.1134 50.5007C33.636 49.5464 33.948 48.3976 34.8206 47.8079C35.9357 47.0541 37.3957 47.5372 37.9185 48.6788C39.6361 52.1816 38.941 56.3763 36.1787 59.1387L35.5742 59.7433C38.0981 61.0577 39.914 63.5483 40.2937 66.4829L45.1873 62.8125V49.5562C45.1873 48.3913 46.1317 47.4469 47.2967 47.4469C48.4616 47.4469 49.4061 48.3913 49.4061 49.5562V69.8906C49.4061 71.0556 50.3505 72 51.5154 72H58.5937C59.7586 72 60.703 71.0556 60.703 69.8906V68.5666C60.703 66.2507 59.9067 64.0568 58.4436 62.2979V49.119H58.4438Z"
            fill="currentColor"
        />
        <path
            d="M47.7468 16.4632C47.7468 11.0381 52.1581 6.62428 57.5803 6.62428C58.9105 6.62428 60.2125 6.89301 61.4502 7.42275L66.504 9.58879C65.7654 7.65576 64.6211 5.88276 63.114 4.37569C58.3431 -0.395157 52.6589 0.00843619 51.8133 0.00843619C41.9151 0.00843619 33.8906 8.01647 33.8906 17.9346C33.8906 22.884 36.8137 27.3814 41.3376 29.3923L53.3609 34.8055C59.0113 37.3171 62.6625 42.9355 62.6625 49.119V50.2459C63.6933 48.698 64.5726 47.049 65.293 45.3095C66.745 41.8039 67.4813 38.1028 67.4813 34.309V33.2907H64.5744C55.2957 33.2906 47.7468 25.7418 47.7468 16.4632Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.7898 11.3012L70.7214 15.9863C71.4971 16.3185 72 17.0812 72 17.9251V26.9626C72 28.1275 71.0556 29.072 69.8906 29.072H64.5743C57.6104 29.072 51.9656 23.4385 51.9656 16.4633C51.9656 12.4241 56.0967 9.72014 59.7898 11.3012ZM61 20C62.1046 20 63 19.1046 63 18C63 16.8954 62.1046 16 61 16C59.8954 16 59 16.8954 59 18C59 19.1046 59.8954 20 61 20Z"
            fill="currentColor"
        />
    </Icon>
)
