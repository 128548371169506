import React from 'react'
import SettingSwitch from '../form/setting-switch'
import { useBookingSettingsContext } from './booking-settings-context'

export default function NewClientAddressSwitch() {
    const { requireNewClientAddress, setRequireNewClientAddress } =
        useBookingSettingsContext()

    return (
        <SettingSwitch
            label="Require new clients to enter their address?"
            isChecked={requireNewClientAddress}
            onChange={e => setRequireNewClientAddress(e.target.checked)}
        />
    )
}
