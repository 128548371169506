import {
    Box,
    BoxProps,
    Checkbox,
    Flex,
    FlexProps,
    Text,
    TextProps,
} from '@chakra-ui/react'
import React from 'react'
import { adjustArrayWithItem } from '~utils/helpers'
import SharedLabel from './shared-label'

interface Props<T> {
    options: T[]
    items: T[]
    setItems: (items: T[]) => void
    label?: string
    labelProps?: TextProps
    textModification?: (item: T) => string
    containerProps?: BoxProps
    listContainerProps?: FlexProps
    isDisabled?: boolean
}

const MultiItemSelect = <T extends object | string>({
    options,
    items,
    setItems,
    label,
    labelProps,
    textModification: labelProperty = item => String(item),
    containerProps,
    listContainerProps,
    isDisabled = false,
}: Props<T>) => {
    return (
        <Box {...containerProps}>
            {label && (
                <SharedLabel mb={3} {...labelProps}>
                    {label}
                </SharedLabel>
            )}
            <Flex flexWrap="wrap" gridGap={3} {...listContainerProps}>
                {options.map(i => (
                    <MultiSelectItem
                        key={i.toString()}
                        label={labelProperty(i)}
                        isSelected={items.includes(i)}
                        onClick={() => setItems(adjustArrayWithItem(items, i))}
                        isDisabled={isDisabled}
                    />
                ))}
            </Flex>
        </Box>
    )
}

interface ItemProps extends FlexProps {
    label: string
    isSelected: boolean
    onClick: () => void
    labelProps?: TextProps
    colorScheme?: string
    isDisabled?: boolean
}

export const MultiSelectItem: React.FC<ItemProps> = ({
    label,
    isSelected,
    onClick,
    labelProps,
    colorScheme = 'brand',
    isDisabled = false,
    ...props
}) => {
    const handleClick = () => {
        if (!isDisabled) {
            onClick()
        }
    }

    const getCursor = () => (isDisabled ? 'not-allowed' : 'pointer')

    const getBorderColor = () => {
        if (isDisabled) return 'gray.300'
        if (isSelected) return `${colorScheme}.200`
        return 'gray.200'
    }

    const getBackgroundColor = () => {
        if (isDisabled) return 'gray.100'
        if (isSelected) return `${colorScheme}.50`
        return 'initial'
    }

    const getTextColor = () => {
        if (isDisabled) return 'gray.500'
        if (isSelected) return `${colorScheme}.800`
        return 'initial'
    }

    return (
        <Flex
            onClick={handleClick}
            cursor={getCursor()}
            gridGap={2}
            border="1px solid"
            borderColor={getBorderColor()}
            w="fit-content"
            px={3}
            py={2}
            rounded="md"
            bg={getBackgroundColor()}
            userSelect="none"
            transition="0.05s"
            opacity={isDisabled ? 0.6 : 1}
            {...props}
        >
            <Checkbox
                colorScheme={colorScheme}
                pointerEvents="none"
                isChecked={isSelected}
                isDisabled={isDisabled}
            />
            <Text color={getTextColor()} {...labelProps}>
                {label}
            </Text>
        </Flex>
    )
}

export default MultiItemSelect
