import React from 'react'
import SettingSwitch from '../form/setting-switch'
import { useBookingSettingsContext } from './booking-settings-context'

export default function AllowFirstApptOnlineSwitch() {
    const { allowFirstApptPerDay, setAllowFirstApptPerDay } =
        useBookingSettingsContext()

    return (
        <SettingSwitch
            label="Allow for an online booking to be the first appointment scheduled on any given day?"
            isChecked={allowFirstApptPerDay}
            onChange={e => setAllowFirstApptPerDay(e.target.checked)}
        />
    )
}
