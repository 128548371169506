import React from 'react'
import { addMinutes, format } from 'date-fns'
import { Box, Text, HStack, Heading } from '@chakra-ui/layout'

import {
    AppointmentFieldsFragment,
    RescheduleFieldsFragment,
} from '~graphql/generated/graphql'
import { getPatientNameFromAppointment } from '~utils/appointment-helpers'
import { stringIfExists } from '~utils/helpers'

interface Props {
    appointment: AppointmentFieldsFragment
    reschedule?: RescheduleFieldsFragment
}

const AppointmentDetailsHeader: React.FC<Props> = ({
    appointment,
    reschedule,
}) => {
    const { service, service_name, clinician, patient, client, is_time_block } =
        appointment

    const serviceName = service?.name || service_name

    return (
        <Box w="full">
            {reschedule && (
                <Heading
                    size={{ base: 'xs', md: 'sm' }}
                    color="gray.600"
                    mb={2}
                >
                    Reschedule request for
                </Heading>
            )}
            <Heading size={{ base: 'sm', md: 'md' }} color="gray.700">
                {is_time_block ? (
                    <span>Time block for {clinician?.name}</span>
                ) : (
                    <span>
                        {stringIfExists(serviceName, {
                            posttext: ' appointment',
                        }) || 'Appointment'}{' '}
                        with {getPatientNameFromAppointment(appointment)}{' '}
                        {client?.last_name}
                    </span>
                )}
            </Heading>
            <Box fontSize={{ base: 'sm', md: 'md' }} color="gray.600" mt={1}>
                <Text
                    _before={reschedule ? { content: '"Original time: "' } : {}}
                    textDecor={reschedule ? 'line-through' : 'initial'}
                >{`${format(appointment.start_time, 'p')} to ${format(
                    addMinutes(appointment.start_time, appointment.minutes),
                    'p'
                )} on ${format(appointment.start_time, 'PPPP')}`}</Text>
                {reschedule && (
                    <Text
                        fontWeight="semibold"
                        color="brand.800"
                    >{`Requested time: ${format(
                        reschedule.requested_start_time || 0,
                        'p'
                    )} to ${format(
                        addMinutes(
                            reschedule.requested_start_time || 0,
                            appointment.minutes
                        ),
                        'p'
                    )} on ${format(
                        reschedule.requested_start_time || 0,
                        'PPPP'
                    )}`}</Text>
                )}
            </Box>
        </Box>
    )
}

export default AppointmentDetailsHeader
