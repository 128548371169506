import { IconType } from 'react-icons'
import { IoPaw } from 'react-icons/io5'
import {
    BiBell,
    BiBellPlus,
    BiClinic,
    BiCodeAlt,
    BiCoffee,
    BiCog,
    BiGroup,
    BiHomeAlt,
    BiInfoCircle,
    BiLibrary,
    BiMedal,
    BiMessageRounded,
    BiMoney,
    BiPieChartAlt2,
    BiPointer,
    BiRuler,
    BiStoreAlt,
    BiSync,
    BiStar,
    BiTime,
    BiUser,
    BiUserCircle,
    BiBuoy,
    BiCalendar,
    BiTable,
    BiBuildingHouse,
    BiGift,
    BiDetail,
    BiTerminal,
    BiDoorOpen,
    BiTrendingUp,
    BiAbacus,
    BiTargetLock,
    BiPalette,
    BiInfoSquare,
    BiFlag,
    BiBulb,
    BiCopyAlt,
    BiCategoryAlt,
    BiKey,
} from 'react-icons/bi'
import { FaKey } from 'react-icons/fa'
import { BsGenderAmbiguous } from 'react-icons/bs'
import { RiMegaphoneLine } from 'react-icons/ri'

import { useClinic } from '~contexts/clinic-context'
import { AdminRoutes, UserRoutes } from '~config/routes'
import { useActionItems } from '~contexts/action-item-provider'
import {
    ClinicFeatureFieldsFragment,
    LoyaltyRedemptionStatus,
    useCountLoyaltyRedemptionsQuery,
} from '~graphql/generated/graphql'
import { eqStringFilter } from '~utils/query-helpers'
import { SettingsTab } from '~components/settings/helpers'
import { useUnreadMessages } from '~contexts/unread-messages-provider'
import { useUserContext } from '~config/user-context'

export type SidebarItem = {
    label: string
    icon?: IconType
    to?: string
    badge?: number
    isNew?: boolean
    pages?: SidebarItem[]
    hideIf?: boolean
}

export function useSidebarContent(
    features: ClinicFeatureFieldsFragment | null | undefined
): {
    sidebarItems: SidebarItem[]
    clinicAdminItems: SidebarItem[]
    adminItems: SidebarItem[]
    enterpriseItems: SidebarItem[]
} {
    const { isAdmin } = useUserContext()
    const { clinic } = useClinic()
    const { unreadMessages } = useUnreadMessages()
    const { actionItems } = useActionItems()

    const usingRooms = clinic?.settings?.does_use_rooms_to_schedule
    const usingClinicians = clinic?.settings?.does_use_clinicians_to_schedule

    const { data } = useCountLoyaltyRedemptionsQuery({
        variables: {
            where: [
                eqStringFilter('clinic', clinic?.id),
                eqStringFilter('status', LoyaltyRedemptionStatus.Pending),
            ],
        },
        skip: !clinic,
    })

    const loyaltyRequestCount = data?.countLoyaltyRedemptions || 0

    const {
        huddle_enabled: isUsingHuddle,
        messaging_enabled: isUsingChat,
        payments_enabled: isUsingPayments,
        scheduling_enabled: isUsingScheduling,
        reviews_enabled: isUsingReviews,
        loyalty_enabled: isUsingLoyalty,
        notifs_enabled: isUsingNotifs,
        forms_enabled: isUsingForms,
        help_enabled: isUsingHelp,
        messaging_enabled: isUsingMessaging,
        bulk_messaging_enabled: isUsingBulkMessaging,
    } = features || {}

    const sidebarItems: SidebarItem[] = [
        {
            icon: BiHomeAlt,
            label: 'Home',
            to: UserRoutes.Home,
        },
        {
            icon: BiCoffee,
            label: 'Daily Huddle',
            to: UserRoutes.DailyHuddle,
            hideIf: !isUsingHuddle,
        },
        {
            icon: BiTime,
            label: 'Pending Actions',
            to: UserRoutes.PendingActions,
            badge: actionItems.length,
            hideIf: !isUsingScheduling,
        },
        {
            icon: BiMessageRounded,
            label: 'Messages',
            to: UserRoutes.Chats,
            badge: unreadMessages,
            hideIf: !isUsingChat,
        },
        {
            icon: BiLibrary,
            label: 'All Appointments',
            to: UserRoutes.AllAppointments,
            hideIf: !isUsingScheduling,
        },
        {
            icon: BiCalendar,
            label: 'Schedule',
            to: '/schedule',
            hideIf: !isUsingScheduling,
        },
    ].filter(i => !i.hideIf)

    const clinicAdminItems: SidebarItem[] = [
        {
            icon: BiPieChartAlt2,
            label: 'Analytics',
            pages: [
                // {
                //     icon: BiBulb,
                //     label: 'DataChat',
                //     to: UserRoutes.DataChat,
                //     hideIf: !isUsingHuddle,
                //     isNew: true,
                // },
                {
                    icon: BiTable,
                    label: 'Reporting',
                    to: UserRoutes.ClinicReporting,
                    hideIf: !isUsingHuddle,
                },
                {
                    icon: BiTrendingUp,
                    label: 'Trends',
                    to: UserRoutes.ClinicTrends,
                    hideIf: !isUsingHuddle,
                },
                {
                    icon: BiTargetLock,
                    label: 'Initiatives',
                    to: UserRoutes.ClinicInitiatives,
                    hideIf: true,
                },
                {
                    icon: BiMedal,
                    label: 'Goals',
                    to: UserRoutes.Goals,
                    hideIf: !isUsingHuddle,
                },
            ].filter(i => !i.hideIf),
        },
        {
            icon: BiPointer,
            label: 'Booking',
            pages: [
                {
                    icon: BiInfoSquare,
                    label: 'Appointment Statuses',
                    to: UserRoutes.AppointmentStatuses,
                },
                {
                    icon: BiPalette,
                    label: 'Branding',
                    to: UserRoutes.Branding,
                },
                {
                    icon: BiGroup,
                    label: 'Clinicians',
                    to: UserRoutes.Clinicians,
                    hideIf: !usingClinicians,
                },
                {
                    icon: BiDoorOpen,
                    label: 'Rooms',
                    to: UserRoutes.Rooms,
                    hideIf: !usingRooms,
                },
                {
                    icon: BiMoney,
                    label: 'Payments',
                    to: UserRoutes.Payments,
                    hideIf: !isUsingPayments,
                },
                {
                    icon: BiRuler,
                    label: 'Scheduling Rules',
                    to: UserRoutes.SchedulingRules,
                    hideIf: !isUsingScheduling,
                },
                {
                    icon: BiStoreAlt,
                    label: 'Services',
                    to: UserRoutes.Services,
                },
                {
                    icon: IoPaw,
                    label: 'Species',
                    to: UserRoutes.Species,
                },
                {
                    icon: BsGenderAmbiguous,
                    label: 'Genders',
                    to: UserRoutes.Sexes,
                },
                {
                    icon: BiCodeAlt,
                    label: 'Widgets',
                    to: UserRoutes.Widgets,
                    hideIf: !(isUsingScheduling || isUsingChat),
                },
            ].filter(i => !i.hideIf),
        },
        {
            icon: BiBell,
            label: 'Engagement',
            pages: [
                {
                    icon: BiFlag,
                    label: 'Campaigns',
                    to: UserRoutes.Campaigns,
                },
                {
                    icon: BiBellPlus,
                    label: 'Notifications',
                    to: UserRoutes.Notifications,
                    hideIf: !isUsingNotifs,
                },
                {
                    icon: RiMegaphoneLine,
                    label: 'Mass Comms',
                    to: UserRoutes.MassCommunication,
                    hideIf: !isUsingNotifs || !isUsingBulkMessaging,
                },
                {
                    icon: BiGroup,
                    label: 'Client Segments',
                    to: UserRoutes.ClientSegments,
                    hideIf: !isUsingNotifs,
                },
                {
                    icon: BiStar,
                    label: 'Reviews',
                    to: UserRoutes.Reviews,
                    hideIf: !isUsingReviews,
                },
                {
                    icon: BiGift,
                    label: 'Loyalty',
                    to: UserRoutes.Loyalty,
                    hideIf: !isUsingLoyalty,
                    badge: loyaltyRequestCount,
                },
                {
                    icon: BiDetail,
                    label: 'Forms',
                    to: UserRoutes.Forms,
                    hideIf: !isUsingForms,
                },
                {
                    icon: BiCategoryAlt,
                    label: 'Reminder Types',
                    to: UserRoutes.ReminderTypes,
                },
                {
                    icon: BiCopyAlt,
                    label: 'Message Templates',
                    to: UserRoutes.MessageTemplates,
                    hideIf: !isUsingMessaging,
                },
                {
                    icon: BiTerminal,
                    label: 'Logs',
                    to: UserRoutes.NotificationLogs,
                    hideIf: !isUsingNotifs,
                },
            ].filter(i => !i.hideIf),
        },
        {
            icon: BiClinic,
            label: 'Clinic',
            pages: [
                {
                    icon: BiUserCircle,
                    label: 'Users',
                    to: UserRoutes.ClinicUsers,
                    hideIf: false,
                },
                {
                    icon: BiInfoCircle,
                    label: 'Clinic Info',
                    to: UserRoutes.ClinicInfo,
                    badge: clinic?.onboarding?.has_set_hours ? 0 : 1,
                },
                {
                    icon: BiSync,
                    label: 'Data Sync',
                    to: UserRoutes.DataSync,
                    hideIf: !isAdmin,
                },
            ].filter(i => !i.hideIf),
        },
        {
            icon: BiCog,
            label: 'Settings',
            to: UserRoutes.SettingsTab(SettingsTab.Booking),
        },
        {
            icon: BiBuoy,
            label: 'Help',
            to: UserRoutes.Help,
            hideIf: !isUsingHelp,
        },
    ].filter((i: SidebarItem) => !i.hideIf)

    const adminItems: SidebarItem[] = [
        {
            icon: BiClinic,
            label: 'Clinics',
            to: AdminRoutes.Clinics,
        },
        {
            icon: BiBuildingHouse,
            label: 'Organizations',
            to: '/admin/organizations',
        },
        {
            icon: BiUser,
            label: 'Users',
            to: AdminRoutes.Users,
        },
        {
            icon: BiAbacus,
            label: 'Usage',
            to: AdminRoutes.Usage,
        },
    ]

    const enterpriseItems: SidebarItem[] = [
        // {
        //     icon: BiPieChartAlt2,
        //     label: 'Overview',
        //     to: '/enterprise',
        // },
        // {
        //     icon: BiTable,
        //     label: 'Reporting',
        //     to: '/enterprise/reporting',
        // },
        // {
        //     icon: BiCoffee,
        //     label: 'Daily Huddle',
        //     to: '/enterprise/huddle',
        // },
        {
            icon: BiTable,
            label: 'Reporting Dashboard',
            to: '/enterprise/reports',
        },
        {
            icon: BiKey,
            label: 'KPI Dashboard',
            to: '/enterprise/kpis',
            hideIf: process.env.NODE_ENV === 'production',
        },
        {
            icon: BiTrendingUp,
            label: 'Trends',
            to: '/enterprise/trends',
        },
        {
            icon: BiTargetLock,
            label: 'Initiatives',
            to: '/enterprise/initiatives',
            hideIf: process.env.NODE_ENV === 'production',
        },
        // {
        //     icon: BiBulb,
        //     label: 'Marketing',
        //     to: '/enterprise/marketing',
        // },
        {
            icon: BiBuildingHouse,
            label: 'Organization',
            to: '/enterprise/info',
        },
        {
            icon: BiPalette,
            label: 'Branding',
            to: UserRoutes.OrganizationBranding,
        },
        {
            icon: BiCodeAlt,
            label: 'Widgets',
            to: UserRoutes.OrganizationWidgets,
        },
    ].filter(i => !i.hideIf)

    return {
        sidebarItems,
        clinicAdminItems,
        adminItems,
        enterpriseItems,
    }
}
