import React from 'react'
import { Box } from '@chakra-ui/layout'

import SharedInput from '~components/shared/form/shared-input'
import SharedSwitch from '~components/shared/form/shared-switch'
import { useReviewSettingsContext } from './review-settings-context'

export default function FacebookReviewLinkInput() {
    const { facebookReviewLink, setFacebookReviewLink } =
        useReviewSettingsContext()

    return (
        <>
            <Box w="full">
                <SharedSwitch
                    label="Allow Facebook reviews?"
                    labelProps={{
                        fontSize: 'md',
                    }}
                    isChecked={isDefined(facebookReviewLink)}
                    onChange={e => {
                        if (e.target.checked) {
                            setFacebookReviewLink('')
                        } else {
                            setFacebookReviewLink(undefined)
                        }
                    }}
                    size="md"
                    showActiveLabel
                    containerProps={{
                        display: 'flex',
                        flexDir: 'row',
                        w: 'full',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 1,
                    }}
                />
                {isDefined(facebookReviewLink) && (
                    <SharedInput
                        label="Link for Facebook Reviews"
                        labelProps={{
                            fontSize: 'sm',
                            fontWeight: 'medium',
                        }}
                        size="sm"
                        value={facebookReviewLink || ''}
                        onChange={e => setFacebookReviewLink(e.target.value)}
                        placeholder="Link to your Facebook page"
                        containerProps={{ w: '100%' }}
                    />
                )}
            </Box>
        </>
    )
}

function isDefined(input: string | null | undefined): boolean {
    return input !== null && input !== undefined
}
