import React from 'react'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SettingSelect from '../form/setting-select'
import SettingSwitch from '../form/setting-switch'
import { useMiscSettingsContext } from './misc-settings-context'

const TIMEOUT_DURATION_OPTIONS = [
    1 * 60 * 1000, // 1 minute
    2 * 60 * 1000, // 2 minutes
    3 * 60 * 1000, // 3 minutes
    4 * 60 * 1000, // 4 minutes
    5 * 60 * 1000, // 5 minutes
    10 * 60 * 1000, // 10 minutes
    15 * 60 * 1000, // 15 minutes
    20 * 60 * 1000, // 20 minutes
    30 * 60 * 1000, // 30 minutes
    45 * 60 * 1000, // 45 minutes
    60 * 60 * 1000, // 1 hour
    90 * 60 * 1000, // 1.5 hours
    120 * 60 * 1000, // 2 hours
    180 * 60 * 1000, // 3 hours
    240 * 60 * 1000, // 4 hours
    300 * 60 * 1000, // 5 hours
    360 * 60 * 1000, // 6 hours
]

const TIMEOUT_DURATION_CONVERTER = {
    60000: '1 minute',
    120000: '2 minutes',
    180000: '3 minutes',
    240000: '4 minutes',
    300000: '5 minutes',
    600000: '10 minutes',
    900000: '15 minutes',
    1200000: '20 minutes',
    1800000: '30 minutes',
    2700000: '45 minutes',
    3600000: '1 hour',
    5400000: '1.5 hours',
    7200000: '2 hours',
    10800000: '3 hours',
    14400000: '4 hours',
    18000000: '5 hours',
    21600000: '6 hours',
}

export default function EditInactiveTimeoutDuration() {
    const { timeoutDurationMillis, setTimeoutDurationMillis } =
        useMiscSettingsContext()

    return (
        <SharedStack spacing={8}>
            <SettingSwitch
                label="Enable automatic logout after inactivity?"
                isChecked={timeoutDurationMillis !== -1}
                onChange={e =>
                    setTimeoutDurationMillis(
                        e.target.checked ? TIMEOUT_DURATION_OPTIONS[0] : -1
                    )
                }
            />
            <SettingSelect
                isDisabled={timeoutDurationMillis === -1}
                label="Automatically log out users after:"
                options={TIMEOUT_DURATION_OPTIONS.map(o => String(o))}
                value={String(timeoutDurationMillis)}
                setValue={v => setTimeoutDurationMillis(parseInt(v))}
                textModification={v => {
                    if (timeoutDurationMillis === -1) return 'Never'
                    return `${
                        TIMEOUT_DURATION_CONVERTER[
                            parseInt(
                                v
                            ) as keyof typeof TIMEOUT_DURATION_CONVERTER
                        ] || 'Unknown'
                    }`
                }}
            />
        </SharedStack>
    )
}
