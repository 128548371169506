import {
    AppointmentFieldsFragment,
    AppointmentStatus,
    ClientFieldsFragment,
    ClinicApptStatusFieldsFragment,
    PatientFieldsFragment,
} from '~graphql/generated/graphql'
import { formatPhoneNumber } from './helpers'

export const isAppointmentValidForSchedule = <
    T extends {
        status: AppointmentStatus
        clinic_appt_status?:
            | Pick<ClinicApptStatusFieldsFragment, 'status'>
            | null
            | undefined
        is_time_block?: boolean | null | undefined
        clinician?: any | undefined
        room?: any | undefined
    },
>(
    appointment: T,
    includePending?: boolean,
): boolean => {
    const status = getAppointmentStatusFromAppointment(appointment)

    const validStatuses = [
        AppointmentStatus.Booked,
        AppointmentStatus.Confirmed,
        AppointmentStatus.Completed,
    ]

    if (includePending) {
        validStatuses.push(AppointmentStatus.Pending)
    }

    return (
        !appointment.is_time_block &&
        !!(appointment.clinician || appointment.room) &&
        validStatuses.includes(status)
    )
}

export const filterActiveAppointments = <
    T extends {
        status: AppointmentStatus
        clinic_appt_status?:
            | Pick<ClinicApptStatusFieldsFragment, 'status'>
            | null
            | undefined
    },
>(
    appointments: T[],
    {
        includePending,
        includeNoShow,
    }: {
        includePending?: boolean
        includeNoShow?: boolean
    },
): T[] => {
    const activeStatuses = [
        AppointmentStatus.Booked,
        AppointmentStatus.Confirmed,
        AppointmentStatus.Completed,
    ]

    if (includePending) {
        activeStatuses.push(AppointmentStatus.Pending)
    }

    if (includeNoShow) {
        activeStatuses.push(AppointmentStatus.NoShow)
    }

    return appointments.filter(a => {
        const status = getAppointmentStatusFromAppointment(a)

        return activeStatuses.includes(status)
    })
}

export const getAppointmentStatusFromAppointment = (
    appointment: Pick<AppointmentFieldsFragment, 'status'> & {
        clinic_appt_status?:
            | Pick<ClinicApptStatusFieldsFragment, 'status'>
            | null
            | undefined
    },
): AppointmentStatus => {
    return appointment.clinic_appt_status?.status || appointment.status
}

export const getClientNameFromAppointment = (
    appointment: AppointmentFieldsFragment,
): string => {
    if (appointment.client?.first_name) {
        return `${appointment.client.first_name} ${appointment.client.last_name}`
    }

    if (appointment.client_pms_id) {
        return 'Client not synced'
    }

    return appointment.new_client_name || 'No client'
}

export const getClientPhoneFromAppointment = (
    appointment: AppointmentFieldsFragment,
    format?: boolean,
): string | null | undefined => {
    const rawPhone = appointment.client?.phone || appointment.new_client_phone
    return format && rawPhone ? formatPhoneNumber(rawPhone) : rawPhone
}

export const getPatientNameFromAppointment = (
    appointment: AppointmentFieldsFragment,
): string | null | undefined => {
    if (appointment.patient?.name) {
        return appointment.patient.name
    }

    if (appointment.patient_pms_id) {
        return 'Patient not synced'
    }

    return appointment.new_patient_name || 'No patient'
}

export const getSpeciesNameFromAppointment = (
    appointment: AppointmentFieldsFragment,
): string | null | undefined =>
    appointment.patient?.clinic_species?.description ||
    appointment.new_patient_species

export const getServiceNameFromAppointment = (
    appointment: AppointmentFieldsFragment,
): string | null | undefined =>
    appointment.service?.name || appointment.service_name

export const getBreedFromPatient = (
    patient: PatientFieldsFragment | null | undefined,
): string | null | undefined => patient?.clinic_breed?.description

export const getClientName = (
    client:
        | Pick<ClientFieldsFragment, 'first_name' | 'last_name'>
        | null
        | undefined,
) => `${client?.first_name} ${client?.last_name}`.trim()
