import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function SnakeIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M16.1719 53.0156H44.2969C48.9568 53.0156 52.7344 49.238 52.7344 44.5781C52.7344 39.9182 48.9568 36.1406 44.2969 36.1406H16.1719C11.512 36.1406 7.73438 39.9182 7.73438 44.5781C7.73438 49.238 11.512 53.0156 16.1719 53.0156Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5469 10.5469C10.5469 15.2068 14.3245 18.9844 18.9844 18.9844H21.7969V36.1406H38.6719V10.5469C38.6719 5.88699 34.8943 2.10938 30.2344 2.10938H18.9844C14.3245 2.10938 10.5469 5.88699 10.5469 10.5469Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5469 10.5469H6.32812"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.32812 10.5469L2.10938 6.32812"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.32812 10.5469L2.10938 14.7656"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M50.0625 61.4531C45.4025 61.4531 41.625 57.6756 41.625 53.0156H58.5C58.5 57.6756 54.7224 61.4531 50.0625 61.4531Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.9375 61.4531C26.5975 61.4531 30.375 65.2307 30.375 69.8906H13.5C13.5 65.2307 17.2776 61.4531 21.9375 61.4531Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M65.9461 54.3202L68.3815 50.1021C71.488 44.7213 69.6446 37.8408 64.2637 34.7344C63.4423 41.6215 60.3193 48.0295 55.4011 52.92L55.3049 53.0157"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5469 53.0156H61.4531C66.113 53.0156 69.8906 56.7932 69.8906 61.4531C69.8906 66.113 66.113 69.8906 61.4531 69.8906H10.5469C5.88698 69.8906 2.10938 66.113 2.10938 61.4531C2.10938 56.7932 5.88698 53.0156 10.5469 53.0156Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.73438 44.5781C7.73438 44.9214 7.7573 45.2593 7.79709 45.592C9.02419 46.2881 10.4417 46.6875 11.9531 46.6875C16.613 46.6875 20.3906 42.9099 20.3906 38.25C20.3906 37.5216 20.2984 36.8149 20.1247 36.1406H16.1719C11.512 36.1406 7.73438 39.9182 7.73438 44.5781Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M0.617697 14.1479C1.02959 14.5596 1.56931 14.7656 2.10917 14.7656C2.64903 14.7656 3.18888 14.5596 3.60063 14.1477L7.20162 10.5469H8.70307C9.63978 14.186 12.9432 16.875 16.8748 16.875H21.7967V29.8125H38.6717V8.4375C38.6717 3.77761 34.8941 0 30.2342 0H16.8748C12.9432 0 9.63978 2.68903 8.70307 6.32812H7.20162L3.60078 2.72728C2.77713 1.90364 1.44148 1.90364 0.617697 2.72728C-0.206084 3.55092 -0.206084 4.88658 0.617697 5.71036L3.34484 8.4375L0.617697 11.1648C-0.206225 11.9884 -0.206225 13.3241 0.617697 14.1479ZM17.5779 6.32812C18.7429 6.32812 19.6873 7.27256 19.6873 8.4375C19.6873 9.60244 18.7429 10.5469 17.5779 10.5469C16.413 10.5469 15.4685 9.60244 15.4685 8.4375C15.4685 7.27256 16.413 6.32812 17.5779 6.32812Z"
            fill="currentColor"
        />
        <path
            d="M69.4941 52.3872L70.2045 51.1567C71.9887 48.0663 72.4626 44.4663 71.539 41.0195C70.6155 37.5725 68.4049 34.6918 65.3145 32.9075C64.7004 32.553 63.9492 32.5306 63.3153 32.8482C62.6813 33.1657 62.2492 33.7804 62.1652 34.4845C61.7294 38.1382 60.5834 41.67 58.8285 44.873C58.3864 47.1625 57.3255 49.2341 55.827 50.9062H63.5586C65.7024 50.9062 67.7229 51.4427 69.4941 52.3872Z"
            fill="currentColor"
        />
        <path
            d="M50.0625 61.4531C53.5518 61.4531 56.3906 58.6143 56.3906 55.125H43.7344C43.7344 58.6145 46.5732 61.4531 50.0625 61.4531Z"
            fill="currentColor"
        />
        <path
            d="M21.9375 65.6719C18.4482 65.6719 15.6094 68.5107 15.6094 72H28.2656C28.2656 68.5107 25.427 65.6719 21.9375 65.6719Z"
            fill="currentColor"
        />
        <path
            d="M63.5625 55.125H60.6094C60.6094 60.9405 55.878 65.6719 50.0625 65.6719C44.247 65.6719 39.5156 60.9405 39.5156 55.125H8.4375C3.78506 55.125 0 58.9101 0 63.5625C0 68.2149 3.78506 72 8.4375 72H11.3906C11.3906 66.1845 16.122 61.4531 21.9375 61.4531C27.753 61.4531 32.4844 66.1845 32.4844 72H63.5625C68.2149 72 72 68.2149 72 63.5625C72 58.9101 68.2149 55.125 63.5625 55.125Z"
            fill="currentColor"
        />
        <path
            d="M16.1719 36.1406C16.1719 35.6036 16.1048 35.0707 15.9726 34.557L15.8373 34.0312H14.0625C9.88073 34.0312 6.40153 37.0898 5.74003 41.0871L6.72862 41.6479C7.67503 42.185 8.75236 42.4688 9.84375 42.4688C13.3332 42.4688 16.1719 39.6301 16.1719 36.1406Z"
            fill="currentColor"
        />
        <path
            d="M20.3906 36.1406C20.3906 41.9562 15.6593 46.6875 9.84374 46.6875C8.69062 46.6875 7.54664 46.4967 6.46115 46.1291C7.82676 48.9534 10.7208 50.9062 14.0625 50.9062H46.4062C51.0587 50.9062 54.8437 47.1212 54.8437 42.4688C54.8437 37.8163 51.0587 34.0312 46.4062 34.0312H20.1779C20.3183 34.723 20.3906 35.43 20.3906 36.1406Z"
            fill="currentColor"
        />
    </Icon>
)
