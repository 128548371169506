import React from 'react'
import { BookingMatchProcedure } from '~graphql/generated/graphql'
import { getCleanEnum } from '~utils/helpers'
import SettingSelect from '../form/setting-select'
import { useBookingSettingsContext } from './booking-settings-context'

export default function BookingMatchProcedureSelect() {
    const { bookingMatchProcedure, setBookingMatchProcedure } =
        useBookingSettingsContext()

    return (
        <SettingSelect
            label="Booking match procedure"
            value={bookingMatchProcedure}
            setValue={(val: string) => {
                setBookingMatchProcedure(val as BookingMatchProcedure)
            }}
            options={Object.values(BookingMatchProcedure)}
            textModification={getCleanEnum}
            requiresAdmin
        />
    )
}
