import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function SheepIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M9.56712 39.375C12.3519 50.1947 24.1944 55.7643 34.2222 51.3661C37.3587 53.377 41.3849 53.3812 44.5278 51.3661C46.7387 52.3357 49.3218 52.875 51.8906 52.875C61.8318 52.875 69.8906 44.8161 69.8906 34.875V27C69.8906 17.0589 61.8318 9 51.8906 9C49.2834 9 46.6654 9.55519 44.4285 10.5524C41.3944 8.48939 37.3787 8.47378 34.3215 10.5524C32.0847 9.55519 29.6073 9 27 9C18.4279 9 11.2553 14.992 9.44238 23.0161"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M30.375 52.875V63H22.5V52.875"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M48.375 52.875V63H56.25V52.875"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.25 37.125C56.25 39.6103 54.2353 41.625 51.75 41.625"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M32.625 37.125C32.625 39.6103 30.6103 41.625 28.125 41.625"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M55.125 20.25C55.125 22.7353 53.1103 24.75 50.625 24.75"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M27 22.5C27 24.9853 24.9853 27 22.5 27C20.0147 27 18 24.9853 18 22.5C18 20.0147 20.0147 18 22.5 18C24.9853 18 27 20.0147 27 22.5Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.875 21.375H14.625C9.65447 21.375 5.625 25.4043 5.625 30.375L2.10938 31.5V34.875C2.10938 37.3603 4.12411 39.375 6.60937 39.375H16.875C19.9815 39.375 22.5 36.8565 22.5 33.75V27"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M20.9531 26.2973C22.2734 26.2973 23.3438 25.227 23.3438 23.9067C23.3438 22.5864 22.2734 21.516 20.9531 21.516C19.6328 21.516 18.5625 22.5864 18.5625 23.9067C18.5625 25.227 19.6328 26.2973 20.9531 26.2973Z"
            fill="currentColor"
        />
        <path
            d="M52.7529 7.18579C49.965 7.18579 47.1656 7.77951 44.7739 8.84573C41.523 6.63553 37.2298 6.62709 33.9666 8.84573C24.7296 4.72795 13.6205 8.47265 8.93152 17.8009C10.8582 17.2998 12.1043 17.3843 14.392 17.3843C15.6308 17.3843 16.815 17.6195 17.9047 18.0453C22.2431 15.7795 27.5625 18.9155 27.5625 23.9067C27.5625 26.4153 26.1577 28.6017 24.0934 29.7211V33.6651C24.0934 39.0144 19.7414 43.3665 14.392 43.3665H8.87231C13.4993 52.7327 24.5931 56.5516 33.8603 52.4872C37.214 54.6375 41.519 54.6419 44.8798 52.4872C47.2438 53.524 50.006 54.1007 52.7527 54.1007C63.3825 54.1007 71.9997 45.4835 71.9997 34.8536C71.9997 32.9739 71.9997 28.3126 71.9997 26.433C72 15.803 63.3828 7.18579 52.7529 7.18579ZM28.125 43.4402C26.9601 43.4402 26.0156 42.4957 26.0156 41.3308C26.0156 40.1659 26.9601 39.2214 28.125 39.2214C29.4432 39.2214 30.5156 38.149 30.5156 36.8308C30.5156 35.6659 31.4601 34.7214 32.625 34.7214C33.7899 34.7214 34.7344 35.6659 34.7344 36.8308C34.7344 40.4752 31.7694 43.4402 28.125 43.4402ZM48.5156 24.4558C48.5156 23.2909 49.4601 22.3464 50.625 22.3464C51.9432 22.3464 53.0156 21.274 53.0156 19.9558C53.0156 18.7909 53.9601 17.8464 55.125 17.8464C56.2899 17.8464 57.2344 18.7909 57.2344 19.9558C57.2344 23.6002 54.2694 26.5652 50.625 26.5652C49.4601 26.5652 48.5156 25.6207 48.5156 24.4558ZM51.75 43.4402C50.5851 43.4402 49.6406 42.4957 49.6406 41.3308C49.6406 40.1659 50.5851 39.2214 51.75 39.2214C53.0682 39.2214 54.1406 38.149 54.1406 36.8308C54.1406 35.6659 55.0851 34.7214 56.25 34.7214C57.4149 34.7214 58.3594 35.6659 58.3594 36.8308C58.3594 40.4752 55.3944 43.4402 51.75 43.4402Z"
            fill="currentColor"
        />
        <path
            d="M20.3906 57.6085V62.7058C20.3906 63.8708 21.3351 64.8152 22.5 64.8152H28.2656C29.4306 64.8152 30.375 63.8708 30.375 62.7058V57.9385C27.1557 58.5263 23.7233 58.4481 20.3906 57.6085Z"
            fill="currentColor"
        />
        <path
            d="M48.375 57.9244V62.7058C48.375 63.8708 49.3194 64.8152 50.4844 64.8152H56.25C57.4149 64.8152 58.3594 63.8708 58.3594 62.7058V57.6409C55.0907 58.4452 51.6939 58.5279 48.375 57.9244Z"
            fill="currentColor"
        />
        <path
            d="M19.8747 33.6651V30.4267C15.7972 29.7545 13.311 25.5074 14.7535 21.616C14.4782 21.598 14.6486 21.6032 12.1989 21.6032C7.35413 21.6032 3.42661 25.5307 3.42661 30.3756L1.27097 31.0653C0.513844 31.3076 0 32.0114 0 32.8064V34.7616C0 37.1841 1.96369 39.1477 4.38609 39.1477H14.392C17.4201 39.1477 19.8747 36.6931 19.8747 33.6651ZM10.8281 31.0643C9.66319 31.0643 8.71875 30.1199 8.71875 28.955C8.71875 27.79 9.66319 26.8456 10.8281 26.8456C11.9931 26.8456 12.9375 27.79 12.9375 28.955C12.9375 30.1199 11.9931 31.0643 10.8281 31.0643Z"
            fill="currentColor"
        />
    </Icon>
)
