import React from 'react'
import { Text, Center } from '@chakra-ui/layout'
import type { CenterProps } from '@chakra-ui/layout'

import SharedHeader from './texts/shared-header'
import SharedInfoText from './texts/shared-info-text'

export default function ErrorView(props: CenterProps) {
    return (
        <Center flexDir="column" w="full" h="full" gridGap={6} {...props}>
            <SharedHeader>Something went wrong</SharedHeader>
            <SharedInfoText>
                If the problem persists, please contact support.
            </SharedInfoText>
        </Center>
    )
}
