import React from 'react'
import { TabPanel } from '@chakra-ui/tabs'

import AppointmentEntryMethodSelect from './appointment-entry-method'
import DetailedServicesSelect from './detailed-services'
import SchedulingMinutesAwaySelect from './scheduling-minutes-away'
import TimeSlotMinutesSelect from './time-slot-minutes'
import HideClinicianPreferenceSwitch from './hide-clinician-preference'
import AllowFirstApptOnlineSwitch from './allow-first-appt-online'
import BookingEntitySelect from './booking-entity-select'
import DefaultTriggerWordsInput from './trigger-words'
import GradientDivider from '~components/shared/gradient-divider'
import BookingSaveActions from './save-actions'
import BookingSettingsProvider from './booking-settings-context'
import { ClinicFieldsFragment } from '~graphql/generated/graphql'
import AllowNewClientsAndPatientsSwitch from './allow-new-clients-and-patients-switch'
import NewClientAddressSwitch from './new-client-address-switch'
import BookingMatchProcedureSelect from './booking-match-procedure-select'
import UrgencyTypeSelect from './urgency-type-select'
import PimsNotificationsSettingSwitches from './pims-notifications'
import CustomizationLink from './customization-link'
import CustomNotesInputs from './custom-notes-inputs'
import MaxBookingColumnsSelect from './max-booking-columns'
import SharedStack from '~components/shared/wrappers/shared-stack'

export default function BookingSettingsPanel({
    clinic,
}: {
    clinic: ClinicFieldsFragment
}) {
    return (
        <TabPanel pb={0}>
            <BookingSettingsProvider clinic={clinic}>
                <SharedStack
                    spacing={8}
                    pb={8}
                    pt={4}
                    divider={<GradientDivider />}
                >
                    <CustomizationLink />
                    <AppointmentEntryMethodSelect />
                    <DetailedServicesSelect />
                    <BookingEntitySelect />
                    <HideClinicianPreferenceSwitch />
                    <PimsNotificationsSettingSwitches />
                    <SchedulingMinutesAwaySelect />
                    <MaxBookingColumnsSelect />
                    <AllowFirstApptOnlineSwitch />
                    <NewClientAddressSwitch />
                    <AllowNewClientsAndPatientsSwitch />
                    <BookingMatchProcedureSelect />
                    <CustomNotesInputs />
                    <UrgencyTypeSelect />
                    <TimeSlotMinutesSelect />
                    <DefaultTriggerWordsInput />
                </SharedStack>
                <BookingSaveActions />
            </BookingSettingsProvider>
        </TabPanel>
    )
}
