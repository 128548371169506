import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function PocketPetIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M64.9565 43.0407H61.0435V35.9972C61.0435 24.3458 51.5644 14.8667 39.913 14.8667H32.6288C32.1867 12.6837 31.1207 10.6034 29.4313 8.9138C24.8542 4.33674 17.4067 4.33674 12.8296 8.9138C9.2655 12.4781 8.43736 17.9065 10.551 22.3081C7.58264 24.8825 5.1068 28.0337 3.32494 31.5221C1.11867 35.8414 0 40.5068 0 45.3886C0 46.6851 1.05117 47.7364 2.34787 47.7364H58.6958H64.9567C66.2513 47.7364 67.3045 48.7897 67.3045 50.0843C67.3045 51.3789 66.2513 52.4322 64.9567 52.4322H41.4783C37.5945 52.4322 34.4348 55.5919 34.4348 59.4757C34.4348 63.3594 37.5945 66.5191 41.4783 66.5191H54C55.2966 66.5191 56.3479 65.468 56.3479 64.1713C56.3479 62.8747 55.2967 61.8234 54 61.8234H41.4783C40.1837 61.8234 39.1305 60.7701 39.1305 59.4755C39.1305 58.1809 40.1837 57.1276 41.4783 57.1276H64.9565C68.8403 57.1276 72 53.9679 72 50.0842C72 46.2004 68.8403 43.0407 64.9565 43.0407ZM56.3479 43.0407H42.6648C43.634 40.3079 46.2437 38.3451 49.3044 38.3451C50.601 38.3451 51.6523 37.2939 51.6523 35.9972C51.6523 34.7005 50.6011 33.6493 49.3044 33.6493C43.6352 33.6493 38.8922 37.6888 37.8014 43.0407H23.8821C24.8512 40.3079 27.461 38.3451 30.5217 38.3451C31.8182 38.3451 32.8695 37.2939 32.8695 35.9972C32.8695 34.7005 31.8184 33.6493 30.5217 33.6493C24.8525 33.6493 20.1095 37.6888 19.0187 43.0407H4.79939C5.44641 35.7629 9.09267 29.1832 15.0256 24.726C15.524 24.3513 15.8531 23.7943 15.9401 23.1771C16.0273 22.5599 15.8655 21.9334 15.4904 21.4356C13.3978 18.6586 13.6811 14.7025 16.1498 12.2338C18.8958 9.48769 23.3644 9.48755 26.111 12.2338C28.8571 14.9801 28.8571 19.4487 26.111 22.195C25.1941 23.1119 25.1942 24.5984 26.111 25.5153C27.0277 26.4323 28.5144 26.432 29.4313 25.5153C31.1209 23.8257 32.1867 21.7453 32.6288 19.5623H39.9129C48.975 19.5623 56.3477 26.935 56.3477 35.9972V43.0407H56.3479Z"
            fill="currentColor"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M64.9565 43.0448H61.0435V36.0013C61.0435 24.3494 51.5649 14.8708 39.913 14.8708H32.6357C32.1832 12.6301 31.0843 10.5711 29.4303 8.91872C24.8448 4.33013 17.417 4.329 12.8303 8.91872C9.26592 12.4819 8.4375 17.9082 10.5498 22.3088C3.8198 28.1156 0 36.4139 0 45.3926C0 46.6888 1.05173 47.7405 2.34787 47.7405H18.7827V45.3926C18.7827 38.9194 24.0486 33.6535 30.5218 33.6535C31.818 33.6535 32.8697 34.7053 32.8697 36.0014C32.8697 37.2975 31.818 38.3493 30.5218 38.3493C26.6379 38.3493 23.4783 41.5088 23.4783 45.3928V47.7406H37.5652V45.3928C37.5652 38.9195 42.831 33.6537 49.3042 33.6537C50.6004 33.6537 51.6521 34.7054 51.6521 36.0015C51.6521 37.2977 50.6004 38.3494 49.3042 38.3494C45.4203 38.3494 42.2608 41.509 42.2608 45.3929V47.7408H58.6956H64.9565C66.2511 47.7408 67.3044 48.7941 67.3044 50.0887C67.3044 51.3833 66.2511 52.4365 64.9565 52.4365H41.4783C37.5944 52.4365 34.4348 55.5961 34.4348 59.48C34.4348 63.3639 37.5944 66.5235 41.4783 66.5235H54C55.2961 66.5235 56.3479 65.4718 56.3479 64.1756C56.3479 62.8795 55.2961 61.8278 54 61.8278H41.4783C40.1837 61.8278 39.1305 60.7745 39.1305 59.4799C39.1305 58.1853 40.1837 57.132 41.4783 57.132H64.9565C68.8404 57.132 72 53.9724 72 50.0885C72 46.2043 68.8404 43.0448 64.9565 43.0448ZM14.087 38.3491C12.7904 38.3491 11.7391 37.298 11.7391 36.0013C11.7391 34.7046 12.7903 33.6534 14.087 33.6534C15.3835 33.6534 16.4348 34.7046 16.4348 36.0013C16.4348 37.298 15.3835 38.3491 14.087 38.3491Z"
            fill="currentColor"
        />
    </Icon>
)
