import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function GoatIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M22.5 50.625C22.5 50.625 24.75 52.875 27 52.875C29.25 52.875 32.625 50.625 32.625 50.625"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M54 50.625C54 50.625 57.375 52.875 59.625 52.875C61.875 52.875 64.125 50.625 64.125 50.625"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M60.75 30.375C60.75 30.375 58.5 29.25 55.125 29.25H32.6554C31.2218 29.25 29.8741 28.5671 29.0265 27.4112L19.3484 14.2138C18.5009 13.0579 17.1532 12.375 15.7196 12.375H12.375L6.75 18L2.10938 20.25V23.625C2.10938 26.1103 4.12411 28.125 6.60937 28.125H12.151C14.345 28.125 16.3388 29.4006 17.2582 31.3927L21.9822 41.6282C22.3234 42.3672 22.5 43.1715 22.5 43.9854V67.5H30.375V57.375L32.625 50.625C32.625 50.625 37.125 52.875 42.75 52.875C48.375 52.875 54 50.625 54 50.625L56.25 57.375V67.5H64.125V38.25C64.125 38.25 69.8906 34.875 69.8906 25.875C69.8906 25.875 63.2353 30.375 60.75 30.375Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.75 28.125V32.625"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M30.375 12.375C30.375 8.02575 26.8492 4.5 22.5 4.5C18.1507 4.5 14.625 8.02575 14.625 12.375"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M20.3906 57.6734V67.5C20.3906 68.6649 21.3351 69.6094 22.5 69.6094H28.2656C29.4306 69.6094 30.375 68.6649 30.375 67.5V58.1458C28.9917 58.4833 27.2656 58.7707 25.3922 58.7707C23.6268 58.7707 21.8669 58.2716 20.3906 57.6734Z"
            fill="currentColor"
        />
        <path
            d="M68.7094 24.1273C65.7478 26.1283 62.3988 27.9107 61.0779 28.2187C60.0733 27.8201 57.9464 27.1406 55.125 27.1406H31.2491C30.4905 27.1406 29.7698 26.7754 29.3213 26.1638L19.6433 12.9665C18.9454 12.0146 18.0091 11.2788 16.95 10.8172C17.6314 8.39278 19.8606 6.60938 22.5 6.60938C25.6791 6.60938 28.2656 9.19589 28.2656 12.375C28.2656 13.5399 29.2101 14.4844 30.375 14.4844C31.5399 14.4844 32.4844 13.5399 32.4844 12.375C32.4844 6.86967 28.0053 2.39062 22.5 2.39062C17.7183 2.39062 13.7129 5.77012 12.7419 10.2656H10.9688C10.4093 10.2656 9.87286 10.4878 9.47728 10.8835L5.22872 16.679L1.18913 18.352C0.461813 18.7047 0 19.4418 0 20.25V22.2188C0 25.1778 1.95469 27.6888 4.64062 28.5286V32.625C4.64062 33.7899 5.58506 34.7344 6.75 34.7344C7.91494 34.7344 8.85938 33.7899 8.85938 32.625V28.8281H12.151C13.5174 28.8281 14.7704 29.6298 15.343 30.8704L20.067 41.106C20.2787 41.5647 20.3906 42.074 20.3906 42.5791V53.022C21.7353 53.7379 23.6835 54.552 25.3922 54.552C28.7752 54.552 31.645 53.3403 31.8658 53.2448L31.867 53.2409C31.8686 53.2413 31.8703 53.2417 31.8718 53.2422C31.9064 53.2274 37.541 54.9844 42.75 54.9844C46.8945 54.9844 51.9919 53.864 54.7357 53.1747C54.7871 53.1973 57.7315 54.4875 61.2224 54.4875C62.9356 54.4875 64.8894 53.6693 66.2344 52.9521V39.3175C68.1477 37.7485 72 33.6268 72 25.875C72 24.1875 70.1092 23.1822 68.7094 24.1273ZM10.6875 20.2493C9.52256 20.2493 8.57812 19.3049 8.57812 18.1399C8.57812 16.975 9.52256 16.0305 10.6875 16.0305C11.8524 16.0305 12.7969 16.975 12.7969 18.1399C12.7969 19.3049 11.8524 20.2493 10.6875 20.2493Z"
            fill="currentColor"
        />
        <path
            d="M56.25 58.0839V67.5C56.25 68.6649 57.1944 69.6094 58.3594 69.6094H64.125C65.2899 69.6094 66.2344 68.6649 66.2344 67.5V57.6046C64.756 58.2048 62.992 58.7063 61.2224 58.7063C59.3537 58.7063 57.6315 58.4203 56.25 58.0839Z"
            fill="currentColor"
        />
    </Icon>
)
