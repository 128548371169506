import React, { useContext } from 'react'
import {
    Button,
    HStack,
    Text,
    Avatar,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    MenuDivider,
    Icon,
    Tooltip,
    Box,
    AvatarBadge,
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import { Clinic, UserFieldsFragment } from '~graphql/generated/graphql'
import { UserContext } from '~config/user-context'
import { BiExit, BiUserCircle } from 'react-icons/bi'
import { formatPhoneNumber } from '~utils/helpers'
import { UserRoutes } from '~config/routes'
import UrgentIcon from '../../icons/urgent-icon'

interface Props {
    user: UserFieldsFragment
    setClinic: (e: Clinic | undefined) => void
}

const UserHeaderButton: React.FC<Props> = ({ user, setClinic }) => {
    const { signOut, isEmailVerified } = useContext(UserContext)
    // const { colorMode, toggleColorMode } = useColorMode()
    // const colorModeIcon = useColorModeValue(BiMoon, BiSun)

    return (
        <Menu>
            <Tooltip
                maxW={'256px'}
                placement="bottom-end"
                noOfLines={1}
                label={`Logged in as ${
                    user?.first_name + ' ' + user?.last_name
                }`}
            >
                <MenuButton
                    as={Button}
                    bg="none"
                    color="black"
                    borderWidth={1}
                    p={1}
                    h="fit-content"
                    w="fit-content"
                    rounded="full"
                >
                    <Avatar
                        size="sm"
                        src={user?.avatar_url || ''}
                        name={user?.first_name + ' ' + user?.last_name || ''}
                    >
                        {!isEmailVerified && (
                            <AvatarBadge
                                borderColor="pink.100"
                                bg="pink.500"
                                boxSize="1.25em"
                            />
                        )}
                    </Avatar>
                </MenuButton>
            </Tooltip>
            <MenuList p={0} maxW={'256px'}>
                <HStack px={4} py={2} spacing={4}>
                    <Avatar
                        size="sm"
                        src={user.avatar_url || ''}
                        name={user.first_name + ' ' + user.last_name}
                    />
                    <Box w="100%" maxW="152px">
                        <Text fontSize="sm" fontWeight="bold" noOfLines={1}>
                            {user.first_name + ' ' + user.last_name}
                        </Text>
                        <Text fontSize="xs" color="gray.500" noOfLines={1}>
                            {user?.phone
                                ? formatPhoneNumber(user.phone)
                                : user.email}
                        </Text>
                    </Box>
                </HStack>
                <Box
                    h="1px"
                    w="full"
                    bgGradient="linear(to-r, gray.50, gray.200, gray.50)"
                    mt={1}
                />
                <Link to={UserRoutes.Profile}>
                    <MenuItem minH="48px" justifyContent="space-between">
                        <HStack>
                            <Icon
                                as={BiUserCircle}
                                boxSize="20px"
                                color="gray.900"
                            />
                            <Text fontWeight="medium" color="gray.900">
                                Profile
                            </Text>
                        </HStack>
                        {!isEmailVerified && <UrgentIcon />}
                    </MenuItem>
                </Link>
                {/* <MenuDivider my={0} />
                <MenuItem minH="48px" onClick={toggleColorMode}>
                    <HStack>
                        <Icon
                            as={colorModeIcon}
                            boxSize="20px"
                            color="gray.900"
                        />
                        <Text fontWeight="medium" color="gray.900">
                            Toggle {colorMode === 'light' ? 'Dark' : 'Light'}{' '}
                            Mode
                        </Text>
                    </HStack>
                </MenuItem> */}
                <MenuDivider my={0} />
                <MenuItem minH="48px" color="red.500" onClick={() => signOut()}>
                    <HStack>
                        <Icon as={BiExit} boxSize="20px" color="red.500" />
                        <Text fontWeight="medium" color="red.500">
                            Sign Out
                        </Text>
                    </HStack>
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default UserHeaderButton
