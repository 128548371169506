import React from 'react'
import { TabPanel } from '@chakra-ui/tabs'
import { VStack } from '@chakra-ui/layout'

import UsePrivateReviewsSwitch from './use-private-reviews-switch'
import GoogleReviewLinkInput from './google-review-link'
import ReviewNotificationSettings from './review-notification-inputs'
import GradientDivider from '~components/shared/gradient-divider'
import FacebookReviewLinkInput from './facebook-review-link'
import ReviewSaveActions from './save-actions'
import ReviewSettingsProvider from './review-settings-context'
import { ClinicFieldsFragment } from '~graphql/generated/graphql'

export default function ReviewSettingsPanel({
    clinic,
}: {
    clinic: ClinicFieldsFragment
}) {
    return (
        <TabPanel>
            <ReviewSettingsProvider clinic={clinic}>
                <VStack
                    w="full"
                    align="flex-start"
                    spacing={8}
                    pt={4}
                    pb={8}
                    divider={<GradientDivider />}
                >
                    <UsePrivateReviewsSwitch />
                    <GoogleReviewLinkInput />
                    <FacebookReviewLinkInput />
                    <ReviewNotificationSettings />
                </VStack>
                <ReviewSaveActions />
            </ReviewSettingsProvider>
        </TabPanel>
    )
}
