import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { Link, Text } from '@chakra-ui/layout'
import { Tooltip } from '@chakra-ui/tooltip'
import { Menu, MenuButton, MenuList } from '@chakra-ui/menu'
import { FiCopy, FiExternalLink, FiShield } from 'react-icons/fi'
import { Button, IconButton, ButtonProps } from '@chakra-ui/button'

import { useUserContext } from '~config/user-context'
import MenuItem from '~components/shared/menu/menu-item'
import { getSupabaseLink, SupabaseEntities } from '~config/constants'
import ActionButton from './action-button'

interface Props {
    entity: SupabaseEntities
    id: string
    type?: 'icon' | 'text'
}

export default function AdminIdButton({ entity, id, type = 'text' }: Props) {
    const { isAdmin } = useUserContext()

    if (!isAdmin) return <></>

    return (
        <Menu placement="bottom-end">
            <ButtonComponent type={type} />
            <MenuList>
                <MenuItem
                    icon={FiCopy}
                    onClick={() => navigator.clipboard.writeText(id)}
                >
                    Copy {entity} ID
                </MenuItem>
                <Link
                    variant="unstyled"
                    href={getSupabaseLink(entity, id)}
                    isExternal
                >
                    <MenuItem icon={FiExternalLink}>View in Supabase</MenuItem>
                </Link>
            </MenuList>
        </Menu>
    )
}

function ButtonComponent({ type }: Pick<Props, 'type'>) {
    const sharedProps: ButtonProps = {
        size: 'sm',
        bg: 'white',
        shadow: 'sm',
        variant: 'outline',
        borderColor: 'blackAlpha.300',
    }

    if (type === 'icon') {
        return (
            <MenuButton
                as={ActionButton}
                icon={<AdminIcon />}
                tooltipLabel="Admin Options"
            />
        )
    }

    return (
        <MenuButton as={Button} leftIcon={<Text>🤫</Text>} {...sharedProps}>
            Admin Options
        </MenuButton>
    )
}

function AdminIcon() {
    return <Icon as={FiShield} w="16px" h="16px" />
}
