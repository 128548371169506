import React from 'react'
import { Box, Text } from '@chakra-ui/layout'
import { StripeAccountRequirements } from '~graphql/generated/graphql'

interface Props {
    isOnboarded: boolean
    requirements?: StripeAccountRequirements | null
}

export default function StripeAccountStatusText(props: Props) {
    return (
        <Box mb={4}>
            <Text fontWeight="semibold" color="gray.900">
                {getStatusText(props)}
            </Text>
            <Text fontSize="sm" color="gray.500">
                {getStatusDescription(props)}
            </Text>
        </Box>
    )
}

function getStatusText({ isOnboarded, requirements }: Props) {
    const { pending_verification, currently_due } = requirements || {}

    if (!isOnboarded) {
        if (pending_verification && pending_verification.length > 0) {
            if (currently_due && currently_due.length > 0) {
                return 'Finish setting up your account'
            }
            return 'Pending verification from Stripe'
        }

        return 'Payments are disabled'
    }

    return 'Payments enabled'
}

function getStatusDescription({ isOnboarded, requirements }: Props) {
    const { pending_verification, currently_due } = requirements || {}

    if (!isOnboarded) {
        if (pending_verification && pending_verification.length > 0) {
            if (currently_due && currently_due.length > 0) {
                return 'You still have some fields that need to be filled out to complete your onboarding.'
            }
            return 'The information you submitted is being reviewed and payments should be enabled shortly.'
        } else {
            return 'Visit the Stripe dashboard to setup your account.'
        }
    } else {
        return 'Your account with Stripe is active and you can accept payments!'
    }
}
