import React from 'react'
import { Tooltip } from '@chakra-ui/tooltip'
import { Box, BoxProps } from '@chakra-ui/react'

interface Props extends BoxProps {
    hoverColor?: string
    isDisabled?: boolean
    tooltip?: string
}

export default function Clickable({
    hoverColor,
    isDisabled,
    tooltip,
    children,
    ...props
}: Props) {
    return (
        <Tooltip
            label={tooltip}
            isDisabled={!tooltip}
            placement="bottom"
            hasArrow
        >
            <Box
                _hover={isDisabled ? {} : { bg: hoverColor || 'gray.100' }}
                cursor={isDisabled ? 'initial' : 'pointer'}
                transition="0.1s"
                userSelect="none"
                {...props}
                onClick={isDisabled ? undefined : props.onClick}
            >
                {children}
            </Box>
        </Tooltip>
    )
}
