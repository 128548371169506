import React from 'react'
import SharedHelpText from '~components/shared/form/shared-help-text'
import { UrgencyType } from '~graphql/generated/graphql'
import { getCleanEnum } from '~utils/helpers'
import SettingSelect from '../form/setting-select'
import { useBookingSettingsContext } from './booking-settings-context'

export default function UrgencyTypeSelect() {
    const { urgencyType, setUrgencyType } = useBookingSettingsContext()

    return (
        <SettingSelect
            label="Clinic Urgency Type"
            moreInfo={
                <SharedHelpText fontSize="sm" mb={0}>
                    Urgent Care and Emergency clinics will show times first
                    during the booking process, and ignore scheduling rules.
                </SharedHelpText>
            }
            value={urgencyType}
            setValue={(val: string) => {
                setUrgencyType(val as UrgencyType)
            }}
            options={Object.values(UrgencyType)}
            textModification={getCleanEnum}
        />
    )
}
