import React from 'react'
import { TabPanel } from '@chakra-ui/tabs'
import { VStack } from '@chakra-ui/layout'

import GradientDivider from '~components/shared/gradient-divider'
import LoyaltySettingsProvider, {
    useLoyaltySettingsContext,
} from './loyalty-settings-context'
import { ClinicFieldsFragment } from '~graphql/generated/graphql'
import SaveActionsBtn from '../save-changes-btn'
import InvoiceLoyaltySettings from './invoice-loyalty-settings'

export default function LoyaltySettingsPanel({
    clinic,
}: {
    clinic: ClinicFieldsFragment
}) {
    return (
        <TabPanel pb={0}>
            <LoyaltySettingsProvider clinic={clinic}>
                <VStack
                    w="full"
                    align="flex-start"
                    spacing={8}
                    pb={8}
                    pt={4}
                    divider={<GradientDivider />}
                    minH="100dvh"
                >
                    <InvoiceLoyaltySettings />
                </VStack>
                <LoyaltySaveActions />
            </LoyaltySettingsProvider>
        </TabPanel>
    )
}

function LoyaltySaveActions() {
    const { canSave, isUpdateLoading, handleUpdateSettings } =
        useLoyaltySettingsContext()

    return (
        <SaveActionsBtn
            canSave={canSave}
            isUpdateLoading={isUpdateLoading}
            handleUpdateSettings={handleUpdateSettings}
        />
    )
}
