import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle(props => ({
    tab: {},
    tablist: {
        gap: 2,
    },
}))

export const Tabs = defineMultiStyleConfig({
    baseStyle,
})
