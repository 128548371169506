import React from 'react'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { useUserContext } from '~config/user-context'
import SettingSwitch from '../form/setting-switch'
import { useBookingSettingsContext } from './booking-settings-context'

export default function AllowNewClientsAndPatientsSwitch() {
    const {
        shouldAllowNewPatients,
        shouldAllowNewClients,
        setShouldAllowNewPatients,
        setShouldAllowNewClients,
        shouldCreateClients,
        shouldCreatePatients,
        setShouldCreateClients,
        setShouldCreatePatients,
    } = useBookingSettingsContext()

    return (
        <SharedStack spacing={4}>
            <SettingSwitch
                label="Allow new patients to book online?"
                isChecked={shouldAllowNewPatients}
                onChange={e => setShouldAllowNewPatients(e.target.checked)}
            />
            <SettingSwitch
                label="Allow new clients to book online?"
                isChecked={shouldAllowNewClients}
                onChange={e => setShouldAllowNewClients(e.target.checked)}
            />
            <SettingSwitch
                label="Create a new patient when a new appointment is booked online?"
                isChecked={shouldCreatePatients}
                onChange={e => setShouldCreatePatients(e.target.checked)}
                requiresAdmin
            />
            <SettingSwitch
                label="Create a new client when a new appointment is booked online?"
                isChecked={shouldCreateClients}
                onChange={e => setShouldCreateClients(e.target.checked)}
                requiresAdmin
            />
        </SharedStack>
    )
}
