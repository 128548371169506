import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

interface Props extends BoxProps {
    isDisabled: boolean
}

export default function DisableWrapper({
    isDisabled,
    children,
    ...props
}: Props) {
    return (
        <Box
            w="full"
            pointerEvents={isDisabled ? 'none' : 'initial'}
            opacity={isDisabled ? 0.5 : 1}
            userSelect={isDisabled ? 'none' : 'initial'}
            transition="0.2s"
            {...props}
        >
            {children}
        </Box>
    )
}
