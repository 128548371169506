import React from 'react'
import { Box, Text } from '@chakra-ui/layout'

import {
    minTimeOptions,
    getMinutesFromMap,
    maxTimeOptionsClinic,
} from '../constants'
import { useBookingSettingsContext } from './booking-settings-context'
import SettingSelect from '../form/setting-select'

export default function SchedulingMinutesAwaySelect() {
    const {
        minMinutesAway,
        setMinMinutesAway,
        maxMinutesAway,
        setMaxMinutesAway,
    } = useBookingSettingsContext()

    return (
        <Box display="flex" flexDir="column" w="full" gap={8}>
            <SettingSelect
                label="How far in advance are appointments required to be scheduled?"
                moreInfo={
                    <Text fontSize="sm" color="gray.500">
                        Restrict how close to the appointment time a client can
                        book online.
                    </Text>
                }
                value={minTimeOptions[minMinutesAway]}
                setValue={(val: string) => {
                    setMinMinutesAway(getMinutesFromMap(val, minTimeOptions))
                }}
                options={Object.values(minTimeOptions)}
            />
            <SettingSelect
                label="What is the furthest out appointments are allowed to be scheduled?"
                moreInfo={
                    <Text fontSize="sm" color="gray.500">
                        Restrict how far out a client can book an appointment
                        online.
                    </Text>
                }
                value={maxTimeOptionsClinic[maxMinutesAway]}
                setValue={(val: string) => {
                    setMaxMinutesAway(
                        getMinutesFromMap(val, maxTimeOptionsClinic)
                    )
                }}
                options={Object.values(maxTimeOptionsClinic)}
            />
        </Box>
    )
}
