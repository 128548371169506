import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const subtle = defineStyle((props: any) => ({
    shadow: 'none',
    bg:
        props.colorMode === 'dark'
            ? `${props.colorScheme}.700`
            : `${props.colorScheme}.100`,
    color:
        props.colorMode === 'dark'
            ? `${props.colorScheme}.100`
            : `${props.colorScheme}.800`,
    _hover: {
        filter: 'brightness(0.95)',
    },
}))

const withShadow = defineStyle((props: any) => ({
    bg: `${props.colorScheme}.500`,
    color: 'white',
    shadow: `${props.colorScheme}.lg`,
    transition: 'all 0.2s',
    _hover: {
        bg: `${props.colorScheme}.600`,
        shadow: `${props.colorScheme}.xl`,
    },
}))

export const Button = defineStyleConfig({
    variants: {
        subtle,
        withShadow,
    },
})
