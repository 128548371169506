import React from 'react'
import { Text } from '@chakra-ui/layout'
import { AppointmentEntryMethod } from '~graphql/generated/graphql'
import { useBookingSettingsContext } from './booking-settings-context'
import { getCleanEnum } from '~utils/helpers'
import SettingSelect from '../form/setting-select'

export default function AppointmentEntryMethodSelect() {
    const { appointmentEntry, setAppointmentEntry } =
        useBookingSettingsContext()

    return (
        <SettingSelect
            label="Which method of appointment entry do you want to use?"
            moreInfo={
                <>
                    <Text fontSize={'sm'} color="gray.500">
                        These selections determine how an appointment is entered
                        into your practice management system.
                    </Text>
                    <Text fontSize={'sm'} color="gray.500">
                        - <strong>"Instant"</strong> appointments are entered
                        automatically without any confirmation.
                    </Text>
                    <Text fontSize={'sm'} color="gray.500">
                        - <strong>"Confirmation Required"</strong> appointments
                        are entered automatically after your staff clicks to
                        confirm it.
                    </Text>
                    <Text fontSize={'sm'} color="gray.500">
                        - <strong>"Manual"</strong> appointments are entered
                        manually by your staff into your practice management
                        system.
                    </Text>
                </>
            }
            options={Object.values(AppointmentEntryMethod)}
            value={appointmentEntry}
            setValue={s => setAppointmentEntry(s as AppointmentEntryMethod)}
            textModification={(s: string) => getCleanEnum(s)}
        />
    )
}
