import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/button'

type Props = {
    canSave: boolean
    isUpdateLoading: boolean
    handleUpdateSettings: () => void
}

export default function SaveActionsBtn({
    canSave,
    isUpdateLoading,
    handleUpdateSettings,
}: Props) {
    if (!canSave) return null

    return (
        <Flex w="full" left={0} bottom={0} position="sticky">
            <Box flex={1} px={8} py={4} bg="white" shadow="blue.top">
                <Button
                    colorScheme="brand"
                    onClick={() => handleUpdateSettings()}
                    isLoading={isUpdateLoading}
                >
                    Save Changes
                </Button>
            </Box>
        </Flex>
    )
}
