import React from 'react'
import { Box, Text } from '@chakra-ui/layout'

import { maxBookingColumnsOptions } from '../constants'
import { useBookingSettingsContext } from './booking-settings-context'
import SettingSelect from '../form/setting-select'

export default function MaxBookingColumnsSelect() {
    const { maxBookingColumns, setMaxBookingColumns } =
        useBookingSettingsContext()

    return (
        <Box display="flex" flexDir="column" w="full" gap={8}>
            <SettingSelect
                label="What is the maximum number of columns you want to show on time selection?"
                moreInfo={
                    <Text fontSize="sm" color="gray.500">
                        This is useful for Urgent Care or Emergency clinics
                        where clients are generally booking within 48-72 hours.
                    </Text>
                }
                value={maxBookingColumnsOptions[maxBookingColumns]}
                setValue={(val: string) => {
                    const res = parseInt(val)

                    if (isNaN(res)) return
                    if (!Object.keys(maxBookingColumnsOptions).includes(val))
                        return

                    setMaxBookingColumns(res)
                }}
                options={Object.values(maxBookingColumnsOptions)}
            />
        </Box>
    )
}
