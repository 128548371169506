import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function HorseIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M11.1469 38.2594V45.0375C11.1469 46.235 10.6724 47.385 9.82527 48.2323C8.978 49.0795 7.82558 49.5562 6.62816 49.5562H2.10931V38.2594C2.10931 33.2672 6.15369 29.2219 11.1469 29.2219H13.4063"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.7031 42.7782C24.7031 46.9321 22.3534 50.7289 18.6367 52.5872C16.9987 53.4084 15.6656 54.075 15.6656 54.075L13.4063 58.5937C14.8523 60.0397 15.6656 62.002 15.6656 64.0478V65.3719H6.62817V51.8157L11.1469 47.2969V35.3368C11.1469 32.0912 12.8302 29.0773 15.5865 27.3703C18.3429 25.6645 21.7999 25.5086 24.7031 26.9602V26.9625"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.5187 29.2218C40.5187 29.2218 42.2133 23.5554 43.9192 17.899C45.9186 11.2091 52.0756 6.62805 59.0569 6.62805H65.3718V13.4063C65.3718 13.4063 68.4221 19.5076 69.5066 21.6835C69.7551 22.1827 69.8907 22.7341 69.8907 23.2921V23.3904C69.8907 25.5233 68.5236 27.4166 66.5016 28.0911V28.0922C64.4795 28.7666 62.2428 28.0707 60.9662 26.3649C60.2882 25.4658 59.7235 24.7031 59.7235 24.7031H58.5937C56.0972 24.7031 54.075 22.681 54.075 20.1867V20.1843"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M56.3344 24.7031C56.3344 24.7031 57.0687 30.9989 57.7014 36.3819C58.2437 40.8972 55.8827 45.2623 51.8157 47.2958V47.2969L49.5563 58.5937C51.0023 60.0398 51.8157 62.0021 51.8157 64.0479V65.3719H42.7781V42.7782"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.5187 47.2969H37.2765C34.9268 47.2969 32.5885 47.0089 30.3064 46.4394C27.7534 45.7999 24.7031 45.0375 24.7031 45.0375"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.7031 24.7031V33.7407C24.7031 33.7407 26.9625 35.9999 33.7407 35.9999C38.2594 35.9999 40.5188 33.7407 40.5188 33.7407V24.7031C40.5188 24.7031 38.2594 26.9625 33.7407 26.9625C29.2218 26.9625 24.7031 24.7031 24.7031 24.7031Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M33.7406 36.5389V46.8257"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M38.9718 28.4088C38.9718 28.4029 38.9718 28.397 38.9718 28.3911C38.9718 27.7066 38.3206 27.2039 37.6613 27.388C36.4719 27.7201 35.07 27.947 33.4593 27.947C31.0545 27.947 28.6785 27.4431 26.7446 26.8656C26.0771 26.6662 25.4062 27.164 25.4062 27.8606V32.3505C26.4763 32.9283 28.938 33.8908 33.4593 33.8908C36.2557 33.8908 38.0811 33.0038 38.9718 32.428V28.4088Z"
            fill="currentColor"
        />
        <path
            d="M5.9438 35.2543C5.9438 33.6205 6.25205 32.0404 6.83002 30.5802C2.96775 31.1712 0 34.5158 0 38.5405V47.025C0 48.1899 0.944437 49.1344 2.10938 49.1344H2.97183C4.60406 49.1344 5.94366 47.8115 5.94366 46.1624L5.9438 35.2543Z"
            fill="currentColor"
        />
        <path
            d="M21.1875 33.4595V27.2468C18.8577 26.496 16.2593 26.7881 14.1554 28.0897C11.6927 29.6149 10.1624 32.3602 10.1624 35.2543V48.7031C10.1624 49.2626 9.94021 49.7991 9.54449 50.1946L5.49295 54.2461C5.22927 54.5098 5.08105 54.8675 5.08105 55.2405V65.5123C5.08105 66.2889 5.71063 66.9185 6.4873 66.9185H9.1987C10.2157 66.9185 10.8875 65.8761 10.485 64.942C10.162 64.1925 9.70115 63.502 9.12191 62.9176C8.47546 62.2654 8.29616 61.2842 8.70693 60.4629L11.2475 55.3815C11.4516 54.9733 11.7827 54.6421 12.1909 54.4381C12.1913 54.4378 13.6893 53.6891 15.5295 52.7662C19.0201 51.0209 21.1873 47.5173 21.1873 43.6217C21.1873 42.4343 22.1684 41.4761 23.3643 41.5135C24.5251 41.5498 25.4143 42.5536 25.4057 43.715C25.4038 43.9809 25.3935 44.2454 25.3777 44.5085L31.3498 45.9712V38.0433C24.671 37.6173 22.0992 35.245 21.8052 34.9509C21.4099 34.5555 21.1875 34.0189 21.1875 33.4595Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.1414 11.5369L71.7891 20.8409C71.9347 21.1338 72 21.4837 72 21.8569C72 25.0203 67.8177 26.6472 65.7586 23.8958C65.0109 22.9042 64.3781 22.05 64.3719 22.0414C63.9741 21.5045 63.3452 21.1877 62.6768 21.1877H61.4066C59.7718 21.1877 58.4218 19.8674 58.4346 18.2326C58.4442 17.0163 57.4242 16.0372 56.194 16.1115C55.0474 16.181 54.199 17.1831 54.2168 18.3317C54.2505 20.495 55.2457 22.4287 56.7923 23.7257L58.3088 36.681C58.8102 40.8569 56.6131 44.9295 52.8414 46.8152C52.2467 47.1126 51.8342 47.6691 51.7126 48.3079L49.1756 60.9927C49.0372 61.6843 49.2538 62.3993 49.7524 62.8979C50.3528 63.4982 50.8274 64.2119 51.1547 64.9875C51.5422 65.905 50.8459 66.9187 49.8499 66.9187H47.1376C46.361 66.9187 45.7314 66.2891 45.7314 65.5124V43.6815C45.7314 42.5457 44.8585 41.5683 43.724 41.5149C42.5126 41.4578 41.5126 42.423 41.5126 43.6219V46.6381C41.5126 46.6381 37.8079 46.6743 35.5688 46.5355V37.9742C40.0857 37.3854 42.4603 35.0634 42.5728 34.9509C42.9684 34.5555 43.1907 34.019 43.1907 33.4595V28.6869L46.926 16.2499C47.898 12.9978 49.8513 10.2073 52.5749 8.17988C55.298 6.15277 58.5319 5.08134 61.9272 5.08134H64.8094C65.9744 5.08134 66.9188 6.02578 66.9188 7.19072V10.5937C66.9188 10.9211 66.995 11.2441 67.1414 11.5369ZM65.0013 13.5C65.0376 14.3101 64.2859 15 63.5013 15C62.6604 15 62.0377 14.3127 62.0013 13.5C61.965 12.6899 62.7167 12 63.5013 12C64.3422 12 64.9649 12.6873 65.0013 13.5Z"
            fill="currentColor"
        />
    </Icon>
)
