import React from 'react'
import { Icon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/button'
import { Tooltip } from '@chakra-ui/tooltip'
import { Box, Text } from '@chakra-ui/layout'
import { FaBell, FaMobile, FaPhone } from 'react-icons/fa6'

import {
    GetClientDocument,
    ClientFieldsFragment,
    ClientPhoneFieldsFragment,
    useMakeClientPhonePrimaryMutation,
} from '~graphql/generated/graphql'
import InfoItem from '~components/shared/info-item'
import { formatPhoneIfValid } from '~utils/helpers'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SharedHelpText from '~components/shared/form/shared-help-text'

interface Props {
    client: ClientFieldsFragment
}

export default function ClientPhonesInfo({ client }: Props) {
    const clientPhones = client.client_phones ?? []

    const primaryPhone =
        clientPhones.find(phone => phone.is_chckup_primary) || null

    const [makePhonePrimary, { loading }] = useMakeClientPhonePrimaryMutation({
        refetchQueries: [
            {
                query: GetClientDocument,
                variables: { id: client.id },
            },
        ],
        awaitRefetchQueries: true,
    })

    async function handleMakePrimary(phone: ClientPhoneFieldsFragment) {
        await makePhonePrimary({
            variables: {
                clinicId: client.clinic?.id || '',
                clientId: client.id,
                phoneId: phone.id,
            },
        })
    }

    return (
        <InfoItem
            label="Phones"
            info={
                <SharedStack spacing={2}>
                    {clientPhones.length > 0 ? (
                        clientPhones.map(phone => (
                            <PhoneItem
                                key={phone.id}
                                phone={phone}
                                isPrimary={
                                    primaryPhone
                                        ? primaryPhone?.id === phone.id
                                        : phone.is_primary
                                }
                                isLoading={loading}
                                onClick={handleMakePrimary}
                            />
                        ))
                    ) : (
                        <SharedHelpText>
                            No phones synced from your Practice Management
                            System
                        </SharedHelpText>
                    )}
                </SharedStack>
            }
        />
    )
}

interface PhoneItemProps {
    phone: ClientPhoneFieldsFragment
    isPrimary?: boolean
    isLoading?: boolean
    onClick: (phone: ClientPhoneFieldsFragment) => void
}

function PhoneItem({ phone, isPrimary, isLoading, onClick }: PhoneItemProps) {
    function getPhoneIcon() {
        if (phone.is_mobile) {
            return FaMobile
        }
        return FaPhone
    }

    return (
        <SharedStack row spacing={4}>
            <SharedStack row fit spacing={2}>
                <Tooltip
                    hasArrow
                    label={phone.is_mobile ? 'Mobile Phone' : 'Home Phone'}
                >
                    <Box>
                        <Icon
                            as={getPhoneIcon()}
                            color="gray.400"
                            fontSize="sm"
                        />
                    </Box>
                </Tooltip>
                <Text fontWeight="medium" fontSize="sm">
                    {formatPhoneIfValid(phone.phone)}
                </Text>
            </SharedStack>
            {isPrimary ? (
                <Tooltip label="Primary" hasArrow>
                    <Box>
                        <Icon as={FaBell} fontSize="sm" color="brand.600" />
                    </Box>
                </Tooltip>
            ) : (
                <Button
                    size="xs"
                    isDisabled={isLoading}
                    colorScheme="brand"
                    variant="subtle"
                    onClick={() => onClick(phone)}
                >
                    Make Primary
                </Button>
            )}
        </SharedStack>
    )
}
