import { auth } from '../config/firebase'
import { setContext } from '@apollo/client/link/context'

const getToken = async () => {
    let token = await auth.currentUser?.getIdToken()

    return token
}

export const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await getToken()
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    }
})
