import React from 'react'

import SettingSwitch from '../form/setting-switch'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SharedHelpText from '~components/shared/form/shared-help-text'
import { useBookingSettingsContext } from './booking-settings-context'

export default function PimsNotificationsSettingSwitches() {
    const {
        shouldDefaultPimsEmailsOn,
        setShouldDefaultPimsEmailsOn,
        shouldDefaultPimsSmsOn,
        setShouldDefaultPimsSmsOn,
    } = useBookingSettingsContext()

    return (
        <SharedStack spacing={4}>
            <SettingSwitch
                label="Turn on PIMS email notifications?"
                moreInfo={
                    <SharedHelpText fontSize="sm" mb={0}>
                        If yes, we'll turn on any settings in your PIMS to send
                        email notifications for appointments booked through
                        Chckup.
                    </SharedHelpText>
                }
                isChecked={shouldDefaultPimsEmailsOn}
                onChange={e => setShouldDefaultPimsEmailsOn(e.target.checked)}
            />
            <SettingSwitch
                label="Turn on PIMS SMS notifications?"
                moreInfo={
                    <SharedHelpText fontSize="sm" mb={0}>
                        If yes, we'll turn on any settings in your PIMS to send
                        SMS notifications for appointments booked through
                        Chckup.
                    </SharedHelpText>
                }
                isChecked={shouldDefaultPimsSmsOn}
                onChange={e => setShouldDefaultPimsSmsOn(e.target.checked)}
            />
        </SharedStack>
    )
}
