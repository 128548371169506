import React, { useContext, useEffect, useRef, useState } from 'react'

import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/modal'
import { Box } from '@chakra-ui/layout'
import { navigate } from 'gatsby-link'
import { useMatch, useLocation } from '@reach/router'

import Header from './header'
import Sidebar from './sidebar/sidebar'
import { useUserContext } from '~config/user-context'
import { useClinic } from '~contexts/clinic-context'
import TutorialModal from './tutorial-modal'
import { UserRoutes } from '~config/routes'
import LoadingView from '../loading-view'
import DetailDrawers from './detail-drawers'
import MobileSidebar from './mobile-drawer'

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(true)
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const [submenu, setSubmenu] = useState<string>('none')
    const { pathname } = useLocation()
    const matchAuth = pathname.includes('/auth')
    const matchClinicSelect = useMatch(UserRoutes.SelectClinic)
    const matchRhapsodyWidget = useMatch(UserRoutes.RhapsodyWidget)
    const { user, userId, isLoading } = useUserContext()
    const { loading } = useClinic()

    const firstLoad = useRef(true)

    useEffect(() => {
        if (!user && !userId && !isLoading && !firstLoad.current) {
            navigate(UserRoutes.Auth)
        }
        firstLoad.current = false
    }, [user, isLoading, userId])

    useEffect(() => {
        setDrawerOpen(false)
    }, [pathname])

    if (isLoading || loading) return <LoadingView h="100dvh" w="100dvw" />

    if (matchAuth || matchClinicSelect || matchRhapsodyWidget)
        return <Box>{children}</Box>

    return (
        <>
            <Box
                minH="100dvh"
                h="100dvh"
                transition="0.4s"
                pt={{ base: '56px', lg: '58px' }}
                pl={{
                    base: '0px',
                    lg: isOpen
                        ? '276px'
                        : submenu !== 'none'
                          ? '128px'
                          : '64px',
                }}
                w="100%"
            >
                <Header
                    isOpen={isOpen}
                    onOpenSidebar={() => setDrawerOpen(true)}
                    submenu={submenu}
                />
                <Box display={{ lg: 'none' }}>
                    <Drawer
                        isOpen={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                    >
                        <DrawerOverlay>
                            <DrawerContent>
                                <MobileSidebar />
                            </DrawerContent>
                        </DrawerOverlay>
                    </Drawer>
                </Box>
                {children}
                <TutorialModal />
                <Box
                    position="fixed"
                    top={0}
                    bottom={0}
                    left={0}
                    zIndex={2}
                    display={{ base: 'none', lg: 'initial' }}
                >
                    <Sidebar
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        submenu={submenu}
                        setSubmenu={setSubmenu}
                    />
                </Box>
            </Box>
            <DetailDrawers />
        </>
    )
}

export default Layout
