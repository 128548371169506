import React from 'react'
import { Box } from '@chakra-ui/layout'
import { Icon } from '@chakra-ui/icons'
import { Tooltip } from '@chakra-ui/tooltip'
import { Collapse } from '@chakra-ui/transition'

import {
    GetClientDocument,
    ClientFieldsFragment,
    useUpdateClientMutation,
} from '~graphql/generated/graphql'
import SharedLabel from '~components/shared/form/shared-label'
import SharedSwitch from '~components/shared/form/shared-switch'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SharedHelpText from '~components/shared/form/shared-help-text'
import { FaCheck, FaCircleCheck, FaCircleXmark } from 'react-icons/fa6'

interface Props {
    client: ClientFieldsFragment
}

export default function ClientNotificationSettings({ client }: Props) {
    const { settings } = client

    const [updateClient, { loading }] = useUpdateClientMutation({
        refetchQueries: [
            {
                query: GetClientDocument,
                variables: { id: client.id },
            },
        ],
        awaitRefetchQueries: true,
    })

    async function handleNotificationsEnabled() {
        await updateClient({
            variables: {
                id: client.id,
                data: {
                    settings: {
                        clinic_chckup_notifications_enabled:
                            !settings?.clinic_chckup_notifications_enabled,
                    },
                },
            },
        })
    }

    async function handleSmsEnabled() {
        await updateClient({
            variables: {
                id: client.id,
                data: {
                    settings: {
                        clinic_chckup_sms_enabled:
                            !settings?.clinic_chckup_sms_enabled,
                    },
                },
            },
        })
    }

    async function handleEmailEnabled() {
        await updateClient({
            variables: {
                id: client.id,
                data: {
                    settings: {
                        clinic_chckup_email_enabled:
                            !settings?.clinic_chckup_email_enabled,
                    },
                },
            },
        })
    }

    const areNotificationsEnabled =
        settings?.clinic_chckup_notifications_enabled
    const isSmsEnabled = settings?.clinic_chckup_sms_enabled
    const isEmailEnabled = settings?.clinic_chckup_email_enabled

    const isPmsSmsEnabled = client?.sms_enabled
    const isPmsEmailEnabled = client?.email_enabled

    const notificationsDisabledMessage =
        !settings?.client_chckup_notifications_enabled
            ? `${client.first_name} has disabled notifications from the client dashboard`
            : undefined
    const smsDisabledMessage = !settings?.client_chckup_sms_enabled
        ? `${client.first_name} has disabled SMS notifications from the client dashboard`
        : undefined
    const emailDisabledMessage = !settings?.client_chckup_email_enabled
        ? `${client.first_name} has disabled email notifications from the client dashboard`
        : undefined

    return (
        <SharedStack spacing={8}>
            <SharedStack spacing={4}>
                <SharedLabel fontSize="md">Notification Settings</SharedLabel>
                <SharedStack spacing={6}>
                    <SwitchItem
                        label="Send Notifications"
                        helpText="Enable or disable notifications for this client."
                        isChecked={areNotificationsEnabled}
                        onChange={handleNotificationsEnabled}
                        isLoading={loading}
                        disabledMessage={notificationsDisabledMessage}
                    />
                    <Collapse
                        in={
                            !!areNotificationsEnabled &&
                            !notificationsDisabledMessage
                        }
                    >
                        <SharedStack spacing={6} pl={6}>
                            <SwitchItem
                                label="Send SMS"
                                helpText="Enable or disable notifications sent through SMS for this client."
                                isChecked={isSmsEnabled}
                                onChange={handleSmsEnabled}
                                isLoading={loading}
                                disabledMessage={smsDisabledMessage}
                            />
                            <SwitchItem
                                label="Send Email"
                                helpText="Enable or disable notifications sent through email for this client."
                                isChecked={isEmailEnabled}
                                onChange={handleEmailEnabled}
                                isLoading={loading}
                                disabledMessage={emailDisabledMessage}
                            />
                        </SharedStack>
                    </Collapse>
                </SharedStack>
            </SharedStack>
            <SharedStack spacing={4}>
                <SharedLabel fontSize="sm">
                    PIMS Notification Settings
                </SharedLabel>
                <SharedStack spacing={6}>
                    <PmsSettingItem
                        label={isPmsSmsEnabled ? 'SMS Enabled' : 'SMS Disabled'}
                        isActive={isPmsSmsEnabled}
                        helpText="Synced from your Practice Management System (PIMS)."
                    />
                    <PmsSettingItem
                        label={
                            isPmsEmailEnabled
                                ? 'Email Enabled'
                                : 'Email Disabled'
                        }
                        isActive={isPmsEmailEnabled}
                        helpText="Synced from your Practice Management System (PIMS)."
                    />
                </SharedStack>
            </SharedStack>
        </SharedStack>
    )
}

function SwitchItem({
    isChecked,
    helpText,
    label,
    onChange,
    isLoading,
    disabledMessage,
}: {
    isChecked?: boolean
    helpText?: string
    label: string
    onChange: () => void
    isLoading: boolean
    disabledMessage?: string
}) {
    return (
        <Tooltip label={disabledMessage} placement="start">
            <Box>
                <SharedStack
                    spacing={4}
                    row
                    fit
                    filter={!!disabledMessage ? 'grayscale(1)' : 'none'}
                    opacity={!!disabledMessage ? 0.8 : 1}
                >
                    <SharedSwitch
                        size="sm"
                        isDisabled={
                            isChecked === undefined ||
                            isLoading ||
                            !!disabledMessage
                        }
                        isChecked={isChecked}
                        onChange={onChange}
                    />
                    <Box>
                        <SharedLabel>{label}</SharedLabel>
                        {helpText && (
                            <SharedHelpText mb={0}>{helpText}</SharedHelpText>
                        )}
                    </Box>
                </SharedStack>
            </Box>
        </Tooltip>
    )
}

function PmsSettingItem({
    isActive,
    helpText,
    label,
}: {
    isActive?: boolean
    helpText?: string
    label: string
}) {
    return (
        <SharedStack spacing={4} row fit>
            <Icon
                as={isActive ? FaCircleCheck : FaCircleXmark}
                fontSize="xl"
                color={isActive ? 'brand.500' : 'red.500'}
            />
            <Box>
                <SharedLabel>{label}</SharedLabel>
                {helpText && <SharedHelpText mb={0}>{helpText}</SharedHelpText>}
            </Box>
        </SharedStack>
    )
}
