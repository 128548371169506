import React, {
    useState,
    useEffect,
    useContext,
    createContext,
    PropsWithChildren,
} from 'react'

import {
    ClinicFieldsFragment,
    useUpdateClinicMutation,
} from '~graphql/generated/graphql'
import { SetStateFn } from '~config/constants'
import { handleMergeClinicSettings } from '../helpers'
import { useToastFeedback } from '~utils/hooks/use-toast-feedback'

export default function LoyaltySettingsProvider({
    children,
    clinic,
}: PropsWithChildren<{ clinic: ClinicFieldsFragment }>) {
    return (
        <LoyaltySettingsContext.Provider
            value={{ ...useLoyaltySettingsValues(clinic) }}
        >
            {children}
        </LoyaltySettingsContext.Provider>
    )
}

export function useLoyaltySettingsContext() {
    return useContext(LoyaltySettingsContext)
}

function useLoyaltySettingsValues(
    clinic: ClinicFieldsFragment
): LoyaltySettingsContextType {
    const [shouldAwardLoyaltyForInvoices, setShouldAwardLoyaltyForInvoices] =
        useState<boolean>(
            clinic.settings?.should_award_loyalty_for_invoices ?? false
        )
    const [invoiceLoyaltyPointsPerDollar, setInvoiceLoyaltyPointsPerDollar] =
        useState<number>(
            clinic.settings?.invoice_loyalty_points_per_dollar ?? 0
        )

    useEffect(() => {
        if (!clinic.settings) return
        const { settings } = clinic

        setShouldAwardLoyaltyForInvoices(
            settings.should_award_loyalty_for_invoices ?? false
        )
        setInvoiceLoyaltyPointsPerDollar(
            settings.invoice_loyalty_points_per_dollar ?? 0
        )
    }, [clinic])

    const settings = clinic.settings

    const canSave = settings
        ? shouldAwardLoyaltyForInvoices !==
              settings.should_award_loyalty_for_invoices ||
          invoiceLoyaltyPointsPerDollar !==
              settings.invoice_loyalty_points_per_dollar
        : false

    const toasts = useToastFeedback(
        'Successfully updated settings',
        'Error updating settings'
    )

    const [handleUpdate, { loading }] = useUpdateClinicMutation({
        variables: {
            id: clinic?.id || '',
            data: {
                settings: {
                    should_award_loyalty_for_invoices:
                        shouldAwardLoyaltyForInvoices,
                    invoice_loyalty_points_per_dollar:
                        invoiceLoyaltyPointsPerDollar,
                },
            },
        },
        update: (cache, res) => {
            handleMergeClinicSettings(clinic, cache, res)
        },
        ...toasts,
    })

    return {
        // Context Values
        canSave,
        isUpdateLoading: loading,

        // Actions
        handleUpdateSettings: handleUpdate,

        // Setting Values
        shouldAwardLoyaltyForInvoices,
        setShouldAwardLoyaltyForInvoices,
        invoiceLoyaltyPointsPerDollar,
        setInvoiceLoyaltyPointsPerDollar,
    }
}

type LoyaltySettingsContextType = {
    // Context Values
    canSave: boolean
    isUpdateLoading: boolean

    // Actions
    handleUpdateSettings: () => Promise<any>

    // Setting Values
    shouldAwardLoyaltyForInvoices: boolean
    setShouldAwardLoyaltyForInvoices: SetStateFn<boolean>
    invoiceLoyaltyPointsPerDollar: number
    setInvoiceLoyaltyPointsPerDollar: SetStateFn<number>
}

const LoyaltySettingsContext = createContext<LoyaltySettingsContextType>(
    {} as LoyaltySettingsContextType
)
