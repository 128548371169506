import React from 'react'
import { Switch } from '@chakra-ui/switch'
import { Button, type ButtonProps } from '@chakra-ui/button'

interface Props extends ButtonProps {
    isChecked?: boolean
}

export default function SwitchButton({
    isChecked,
    colorScheme,
    children,
    ...props
}: Readonly<Props>) {
    return (
        <Button
            size="sm"
            minW="fit-content"
            {...props}
            variant={isChecked ? 'solid' : 'outline'}
            colorScheme={isChecked ? colorScheme || 'brand' : 'gray'}
        >
            {children}
            <Switch
                ml={2}
                isChecked={isChecked}
                pointerEvents="none"
                size="sm"
                colorScheme="blackAlpha"
                minW="fit-content"
            />
        </Button>
    )
}
