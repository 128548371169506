import React from 'react'

import { useBookingSettingsContext } from './booking-settings-context'
import { getMinutesFromMap, timeSlotOptions } from '../constants'
import SettingSelect from '../form/setting-select'

export default function TimeSlotMinutesSelect() {
    const { timeSlotMinutes, setTimeSlotMinutes } = useBookingSettingsContext()

    return (
        <SettingSelect
            label="How long are your time slots?"
            value={timeSlotOptions[timeSlotMinutes]}
            setValue={(val: string) => {
                setTimeSlotMinutes(getMinutesFromMap(val, timeSlotOptions))
            }}
            options={Object.values(timeSlotOptions)}
        />
    )
}
