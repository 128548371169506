import { defineStyleConfig } from '@chakra-ui/react'

export const Link = defineStyleConfig({
    baseStyle: {
        fontWeight: 600,
    },
    variants: {
        primary: {
            color: 'brand.500',
            _hover: {
                color: 'brand.400',
                textDecoration: 'underline',
            },
        },
        ghost: {
            color: 'gray.800',
            _hover: {
                color: 'gray.600',
                textDecoration: 'underline',
            },
        },
    },
    defaultProps: {
        variant: 'ghost',
    },
})
