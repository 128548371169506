import React from 'react'
import { Box, Text } from '@chakra-ui/layout'
import type { TextProps } from '@chakra-ui/layout'

import { Address } from '~graphql/generated/graphql'

interface Props extends TextProps {
    address: Address
}

export default function AddressDisplay({ address, ...rest }: Props) {
    const { street_a, street_b, city, state, zip } = address

    return (
        <Box>
            <Text {...rest}>{street_a}</Text>
            {street_b && <Text {...rest}>{street_b}</Text>}
            <Text {...rest}>
                {city}, {state} {zip}
            </Text>
        </Box>
    )
}
