import React, {
    createContext,
    PropsWithChildren,
    useContext,
    useState,
} from 'react'
import { useTriggerSyncForClinicMutation } from '~graphql/generated/graphql'
import { SyncEndpointType } from '~rest/constants'
import { performSync } from '~rest/sync.rest'
import useProgressToast from '~utils/hooks/use-progress-toast'
import { isCloudPMS } from '~utils/pms-helpers'
import { useClinic } from './clinic-context'

type SyncContextType = {
    performSync: (label: string, endpoint: SyncEndpointType) => void
    isSyncing: boolean
    currentSyncEndpoint: SyncEndpointType | null
}

const SyncContext = createContext<SyncContextType>({
    performSync: () => {},
    isSyncing: false,
    currentSyncEndpoint: null,
})

export default function SyncProvider({ children }: PropsWithChildren) {
    return (
        <SyncContext.Provider value={{ ...useSyncValues() }}>
            {children}
        </SyncContext.Provider>
    )
}

function useSyncValues() {
    const { clinic } = useClinic()
    const { onBegin, onSuccess, onError } = useProgressToast()
    const [triggerSync] = useTriggerSyncForClinicMutation()

    const [isSyncing, setIsSyncing] = useState(false)
    const [currentSyncEndpoint, setCurrentSyncEndpoint] =
        useState<SyncEndpointType | null>(null)

    async function handleSync(label: string, endpoint: SyncEndpointType) {
        if (!clinic) {
            return
        }

        const toastProps = { entity: label, action: 'sync' }

        setIsSyncing(true)
        setCurrentSyncEndpoint(endpoint)
        onBegin(toastProps)

        try {
            if (isCloudPMS(clinic.pms)) {
                await performSync(clinic.id, endpoint)
                onSuccess({
                    ...toastProps,
                    successDescription:
                        'Sync complete! You can go to data sync to sync another item.',
                })
            } else {
                await triggerSync({
                    variables: {
                        clinicId: clinic.id,
                        isFullSync: false,
                    },
                })
            }
        } catch (error) {
            console.error(error)
            onError(toastProps)
        }
        setIsSyncing(false)
        setCurrentSyncEndpoint(null)
    }

    return {
        performSync: handleSync,
        isSyncing,
        currentSyncEndpoint,
    }
}

export function useSyncContext(): SyncContextType {
    return useContext(SyncContext)
}
