import React from 'react'
import { Flex, VStack } from '@chakra-ui/layout'

import AppointmentInfoSection from './info-section'
import AppointmentClientSection from './client-section'
import AppointmentPatientSection from './patient-section'
import {
    AppointmentFieldsFragment,
    RescheduleFieldsFragment,
} from '~graphql/generated/graphql'
import AppointmentDetailsHeader from './details-header'
import AppointmentFormsSection from './forms-section'

interface Props {
    appointment: AppointmentFieldsFragment
    reschedule?: RescheduleFieldsFragment
}

const AppointmentDetails: React.FC<Props> = ({ appointment, reschedule }) => {
    return (
        <VStack w="full" spacing={6} flex={1} px={{ base: 2, md: 0 }}>
            <AppointmentDetailsHeader
                appointment={appointment}
                reschedule={reschedule}
            />
            <Flex
                w="full"
                gridGap={{ base: 4, md: 8 }}
                flexDir={{ base: 'column', md: 'row' }}
            >
                <AppointmentPatientSection appointment={appointment} />
                <AppointmentClientSection appointment={appointment} />
            </Flex>
            <AppointmentFormsSection appointment={appointment} />
            <AppointmentInfoSection
                appointment={appointment}
                reschedule={reschedule}
            />
        </VStack>
    )
}

export default AppointmentDetails
