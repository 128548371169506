import React from 'react'
import { Text, SimpleGrid, GridItem, Box } from '@chakra-ui/layout'

import EmptyState from '~components/shared/empty-state'
import SharedStack from '~components/shared/wrappers/shared-stack'
import {
    PatientFieldsFragment,
    PatientVaccinationRecordFieldsFragment,
} from '~graphql/generated/graphql'
import { format } from 'date-fns'
import GradientDivider from '~components/shared/gradient-divider'

interface Props {
    patient: PatientFieldsFragment
}

export default function PatientVaccines({ patient }: Props) {
    const { patient_vaccination_records: vaccines } = patient

    if (!vaccines || vaccines.length === 0) {
        return (
            <Box w="full" bg="gray.50" px={4} py={3} rounded="xl">
                <EmptyState title="No vaccines" subtitle=" " py={6} />
            </Box>
        )
    }

    return (
        <Box w="full" bg="gray.50" px={4} py={3} rounded="xl">
            <SimpleGrid columns={4} mb={2} w="full">
                <GridItem colSpan={2}>
                    <Text
                        fontSize="xs"
                        textTransform="uppercase"
                        fontWeight="medium"
                    >
                        Vaccine
                    </Text>
                </GridItem>
                <Text
                    fontSize="xs"
                    textTransform="uppercase"
                    fontWeight="medium"
                    textAlign="right"
                >
                    Administered
                </Text>
                <Text
                    fontSize="xs"
                    textTransform="uppercase"
                    fontWeight="medium"
                    textAlign="right"
                >
                    Next Due
                </Text>
            </SimpleGrid>
            {vaccines.map(vaccine => (
                <VaccineRecord key={vaccine.id} vaccine={vaccine} />
            ))}
        </Box>
    )
}

interface VaccineProps {
    vaccine: PatientVaccinationRecordFieldsFragment
}

function VaccineRecord({ vaccine }: VaccineProps) {
    const { vaccine_name, administered_date, due_date } = vaccine

    const isOverdue = due_date && due_date < Date.now()

    return (
        <SimpleGrid columns={4} mb={2} w="full">
            <GridItem colSpan={2}>
                <Text fontWeight="semibold" fontSize="sm">
                    {vaccine_name}
                </Text>
            </GridItem>
            <Text textAlign="right" fontSize="sm" color="gray.500">
                {administered_date ? format(administered_date, 'P') : 'N/A'}
            </Text>
            <Text
                color={isOverdue ? 'red.500' : 'gray.500'}
                fontSize="sm"
                textAlign="right"
            >
                {due_date ? format(due_date, 'P') : 'N/A'}
            </Text>
        </SimpleGrid>
    )
}
