import React from 'react'

import SharedInput from '~components/shared/form/shared-input'
import SharedStack from '~components/shared/wrappers/shared-stack'
import { useClinic } from '~contexts/clinic-context'
import SettingSwitch from '../form/setting-switch'
import { useMiscSettingsContext } from './misc-settings-context'

export default function AutoReplySettings() {
    const { clinic } = useClinic()
    const {
        isAutoReplyEnabled,
        setIsAutoReplyEnabled,
        autoReplyMessage,
        setAutoReplyMessage,
    } = useMiscSettingsContext()

    return (
        <SharedStack spacing={8}>
            <SettingSwitch
                label="Enable auto-reply for incoming SMS messages?"
                isChecked={isAutoReplyEnabled}
                onChange={e => setIsAutoReplyEnabled(e.target.checked)}
            />
            <SharedInput
                isDisabled={!isAutoReplyEnabled}
                label="Custom auto-reply message"
                labelProps={{
                    fontSize: 'md',
                }}
                value={autoReplyMessage}
                onChange={e => setAutoReplyMessage(e.target.value)}
                placeholder={`Thank you for reaching out to ${
                    clinic?.name || 'us'
                }. This number is not monitored. Please call ${
                    clinic?.phone || 'our main number'
                } for assistance.`}
                containerProps={{ width: '100%' }}
            />
        </SharedStack>
    )
}
