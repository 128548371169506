import React from 'react'
import SharedStack from '~components/shared/wrappers/shared-stack'
import SettingSelect from '../form/setting-select'
import { useSyncSettingsContext } from './sync-settings-context'

export default function SyncIntervalsInputs() {
    const {
        syncIntervalMinutes,
        setSyncIntervalMinutes,
        longSyncIntervalHours,
        setLongSyncIntervalHours,
    } = useSyncSettingsContext()

    return (
        <SharedStack spacing={4}>
            <SettingSelect
                label="Sync interval minutes"
                value={String(syncIntervalMinutes)}
                setValue={v => setSyncIntervalMinutes(+v)}
                options={[1, 5, 10, 15, 30, 60].map(String)}
                requiresAdmin
            />
            <SettingSelect
                label="Long sync interval hours"
                value={String(longSyncIntervalHours)}
                setValue={v => setLongSyncIntervalHours(+v)}
                options={[1, 2, 3, 4, 6, 12, 24].map(String)}
                requiresAdmin
            />
        </SharedStack>
    )
}
