import { tagAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(tagAnatomy.keys)

const xs = defineStyle({
    minW: 4,
    minH: 4,
    fontSize: '11px',
    px: 1.5,
})

const sizes = {
    xs: definePartsStyle({ container: xs }),
}

export const Tag = defineMultiStyleConfig({ sizes })
