import React from 'react'
import SharedHelpText from '~components/shared/form/shared-help-text'

import SettingSwitch from '../form/setting-switch'
import { useBookingSettingsContext } from './booking-settings-context'

export default function HideClinicianPreferenceSwitch() {
    const { hideClinicianSelect, setHideClinicianSelect } =
        useBookingSettingsContext()

    return (
        <SettingSwitch
            label="Hide clinician preference select on booking form?"
            moreInfo={
                <SharedHelpText fontSize="sm" mb={0}>
                    NOTE: If rooms are selected, there will be no clinician
                    preference select.
                </SharedHelpText>
            }
            isChecked={hideClinicianSelect}
            onChange={e => setHideClinicianSelect(e.target.checked)}
        />
    )
}
