import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function PigIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M65.4741 23.4935C67.9996 22.9402 69.8906 20.6915 69.8906 18C69.8906 14.8934 67.3721 12.375 64.2656 12.375"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M53.0156 18H39.375C33.8673 18 29.25 19.2729 29.25 19.2729C29.25 19.2729 27 13.5 20.25 11.25V20.25C13.4153 20.25 12.375 28.125 9 31.5C6.75 33.75 2.10938 34.875 2.10938 34.875L4.5 43.875H15.75C20.25 43.875 22.5 47.25 22.5 50.625V60.75H31.5L32.625 49.5C32.625 49.5 40.5 50.625 46.125 50.625C51.75 50.625 58.5 49.5 58.5 49.5L59.625 60.75H68.625C68.625 60.75 69.8906 41.625 69.8906 37.125V34.875C69.8906 25.5552 62.3354 18 53.0156 18Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M72 18.0009C72 13.7362 68.5304 10.2665 64.2657 10.2665C63.1007 10.2665 62.1563 11.211 62.1563 12.3759C62.1563 13.5408 63.1007 14.4853 64.2657 14.4853C66.2042 14.4853 67.7813 16.0624 67.7813 18.0009C67.7813 19.2776 67.0756 20.4332 66.0091 21.0483C62.6104 17.8526 58.038 15.8915 53.0157 15.8915H39.3752C35.6187 15.8915 32.2792 16.4481 30.3691 16.8466C29.0865 14.6938 26.2224 11.018 20.9174 9.24983C19.5533 8.79533 18.141 9.81162 18.141 11.2509V18.3638C11.6175 19.7947 10.2643 27.254 7.50901 30.0091C6.01769 31.5005 2.74551 32.5484 1.61293 32.8257C0.464875 33.1041 -0.23276 34.2731 0.0712686 35.4173L2.46187 44.4172C2.7074 45.3415 3.54412 45.985 4.5005 45.985H15.7504C18.9561 45.985 20.391 48.3157 20.391 50.6256V60.7505C20.391 61.9154 21.3354 62.8599 22.5004 62.8599H29.3909C30.4746 62.8599 31.382 62.0388 31.4899 60.9605L32.3986 51.8736C35.1786 52.2135 42.1931 52.735 46.1252 52.735C49.9092 52.735 56.2256 52.253 58.7307 51.9117L59.6356 60.9605C59.7435 62.0388 60.6509 62.8599 61.7346 62.8599H68.6251C69.7359 62.8599 70.6564 61.9983 70.73 60.8897C70.7817 60.106 72.0001 41.6434 72.0001 37.1257V34.8757C72.0001 30.9561 70.8059 27.3102 68.7625 24.2825C70.7358 22.8584 72 20.5364 72 18.0009ZM15.891 32.2046C14.7261 32.2046 13.7817 31.2601 13.7817 30.0952C13.7817 28.9303 14.7261 27.9859 15.891 27.9859C17.056 27.9859 18.0004 28.9303 18.0004 30.0952C18.0004 31.2601 17.056 32.2046 15.891 32.2046Z"
            fill="currentColor"
        />
    </Icon>
)
