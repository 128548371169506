import React, {
    useMemo,
    useState,
    useEffect,
    useContext,
    createContext,
    PropsWithChildren,
} from 'react'

import {
    ClinicFieldsFragment,
    NotifyClinicOn,
    useUpdateClinicMutation,
} from '~graphql/generated/graphql'
import { SetStateFn } from '~config/constants'
import { handleMergeClinicSettings } from '../helpers'
import { useToastFeedback } from '~utils/hooks/use-toast-feedback'

export default function MiscSettingsProvider({
    children,
    clinic,
}: PropsWithChildren<{ clinic: ClinicFieldsFragment }>) {
    return (
        <MiscSettingsContext.Provider
            value={{ ...useMiscSettingsValues(clinic) }}
        >
            {children}
        </MiscSettingsContext.Provider>
    )
}

export function useMiscSettingsContext() {
    return useContext(MiscSettingsContext)
}

function useMiscSettingsValues(
    clinic: ClinicFieldsFragment
): MiscSettingsContextType {
    const [reminderSendTimeMinutes, setReminderSendTimeMinutes] =
        useState<number>(480)
    const [notifyClinicOn, setNotifyClinicOn] = useState<NotifyClinicOn[]>([])
    const [useMsgAutoPriority, setUseMsgAutoPriority] = useState<boolean>(false)
    const [isAutoReplyEnabled, setIsAutoReplyEnabled] = useState<boolean>(false)
    const [autoReplyMessage, setAutoReplyMessage] = useState<string>('')
    const [timeoutDurationMillis, setTimeoutDurationMillis] =
        useState<number>(-1)

    useEffect(() => {
        if (!clinic.settings) return
        const { settings } = clinic

        setReminderSendTimeMinutes(settings.reminder_send_time_minutes ?? 480)
        setNotifyClinicOn(settings.notify_clinic_on || [])
        setUseMsgAutoPriority(settings.msgs_auto_priority ?? false)
        setIsAutoReplyEnabled(settings.auto_reply_enabled ?? false)
        setAutoReplyMessage(settings.auto_reply_message ?? '')
        setTimeoutDurationMillis(settings.max_inactivity_duration ?? -1)
    }, [clinic])

    const canSave = useMemo(() => {
        if (!clinic.settings) return false
        const { settings } = clinic

        return (
            settings.notify_clinic_on !== notifyClinicOn ||
            reminderSendTimeMinutes !== settings.reminder_send_time_minutes ||
            useMsgAutoPriority !== settings.msgs_auto_priority ||
            isAutoReplyEnabled !== settings.auto_reply_enabled ||
            autoReplyMessage !== settings.auto_reply_message ||
            timeoutDurationMillis !== settings.max_inactivity_duration
        )
    }, [
        clinic,
        notifyClinicOn,
        reminderSendTimeMinutes,
        useMsgAutoPriority,
        isAutoReplyEnabled,
        autoReplyMessage,
        timeoutDurationMillis,
    ])

    const toasts = useToastFeedback(
        'Successfully updated settings',
        'Error updating settings'
    )

    const [handleUpdate, { loading }] = useUpdateClinicMutation({
        variables: {
            id: clinic?.id || '',
            data: {
                settings: {
                    reminder_send_time_minutes: reminderSendTimeMinutes,
                    notify_clinic_on: notifyClinicOn,
                    msgs_auto_priority: useMsgAutoPriority,
                    auto_reply_enabled: isAutoReplyEnabled,
                    auto_reply_message: autoReplyMessage,
                    max_inactivity_duration: timeoutDurationMillis,
                },
            },
        },
        update: (cache, res) => {
            handleMergeClinicSettings(clinic, cache, res)
        },
        ...toasts,
    })

    return {
        // Context Values
        canSave,
        isUpdateLoading: loading,

        // Actions
        handleUpdateSettings: handleUpdate,

        // Setting Values
        reminderSendTimeMinutes,
        setReminderSendTimeMinutes,
        notifyClinicOn,
        setNotifyClinicOn,
        useMsgAutoPriority,
        setUseMsgAutoPriority,
        isAutoReplyEnabled,
        setIsAutoReplyEnabled,
        autoReplyMessage,
        setAutoReplyMessage,
        timeoutDurationMillis,
        setTimeoutDurationMillis,
    }
}

type MiscSettingsContextType = {
    // Context Values
    canSave: boolean
    isUpdateLoading: boolean

    // Actions
    handleUpdateSettings: () => Promise<any>

    // Setting Values
    reminderSendTimeMinutes: number
    setReminderSendTimeMinutes: SetStateFn<number>
    notifyClinicOn: NotifyClinicOn[]
    setNotifyClinicOn: SetStateFn<NotifyClinicOn[]>
    useMsgAutoPriority: boolean
    setUseMsgAutoPriority: SetStateFn<boolean>
    isAutoReplyEnabled: boolean
    setIsAutoReplyEnabled: SetStateFn<boolean>
    autoReplyMessage: string
    setAutoReplyMessage: SetStateFn<string>
    timeoutDurationMillis: number
    setTimeoutDurationMillis: SetStateFn<number>
}

const MiscSettingsContext = createContext<MiscSettingsContextType>(
    {} as MiscSettingsContextType
)
