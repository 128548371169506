import { extendTheme } from '@chakra-ui/react'

import { components as customComponents } from './components'
import { customShadows, customColors } from './foundations'

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

const theme = {
    fonts: {
        heading: 'Inter, sans-serif',
        body: 'Inter, sans-serif',
    },
    shadows: customShadows,
    colors: customColors,
    components: customComponents,
    config,
    styles: {
        global: {
            html: {
                overflow: 'hidden',
                height: '100%',
            },
            body: {
                height: '100%',
                overflow: 'auto',
            },
        },
    },
}

export default extendTheme(theme)
