import React from 'react'
import {
    Box,
    BoxProps,
    Text,
    HStack,
    Switch,
    SwitchProps,
    TextProps,
} from '@chakra-ui/react'
import SharedLabel from './shared-label'

export interface SharedSwitchProps extends SwitchProps {
    label?: string
    labelProps?: TextProps
    moreInfo?: React.ReactElement
    containerProps?: BoxProps
    showActiveLabel?: boolean
}

const SharedSwitch: React.FC<SharedSwitchProps> = ({
    label,
    labelProps,
    moreInfo,
    containerProps,
    showActiveLabel = false,
    ...props
}) => {
    return (
        <Box {...containerProps}>
            {(label || moreInfo) && (
                <Box mb={1}>
                    {label && (
                        <SharedLabel mb={1} {...labelProps}>
                            {label}
                        </SharedLabel>
                    )}
                    {!!moreInfo && moreInfo}
                </Box>
            )}
            <HStack spacing={4}>
                {showActiveLabel && (
                    <Text fontSize="sm" fontWeight="semibold">
                        {props.isChecked ? 'Yes' : 'No'}
                    </Text>
                )}
                <Switch size="lg" colorScheme="brand" {...props} />
            </HStack>
        </Box>
    )
}

export default SharedSwitch
