import React from 'react'
import { Text } from '@chakra-ui/layout'

import SharedSwitch from '~components/shared/form/shared-switch'
import { useReviewSettingsContext } from './review-settings-context'

export default function UsePrivateReviewsSwitch() {
    const { shouldUsePrivateReviews, setShouldUsePrivateReviews } =
        useReviewSettingsContext()

    return (
        <SharedSwitch
            label="Allow private reviews?"
            labelProps={{
                fontSize: 'md',
            }}
            moreInfo={
                <>
                    <Text fontSize="sm" color="gray.500">
                        - <strong>Enabled: </strong> Clients will have the
                        option to submit internal reviews that are only visible
                        to your staff.
                    </Text>
                    <Text fontSize="sm" color="gray.500">
                        - <strong>Disabled: </strong> Clients will only be able
                        to submit public reviews on Google or Facebook (if you
                        have those links enabled).
                    </Text>
                </>
            }
            isChecked={shouldUsePrivateReviews}
            onChange={e => setShouldUsePrivateReviews(e.target.checked)}
            size="md"
            showActiveLabel
            containerProps={{
                display: 'flex',
                flexDir: 'row',
                w: 'full',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        />
    )
}
