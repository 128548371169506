import { Link } from './link'
import { Button } from './button'
import { Skeleton } from './skeleton'
import { Heading } from './heading'
import { Text } from './text'
import { Input } from './input'
import { Tabs } from './tabs'
import { Tag } from './tag'
import { Textarea } from './input'

export const components = {
    Button,
    Skeleton,
    Link,
    Heading,
    Text,
    Input,
    Tabs,
    Tag,
    Textarea,
    Popover: {
        variants: {
            responsive: {
                popper: {
                    maxWidth: 'unset',
                    width: 'unset',
                },
                content: {
                    maxWidth: 'unset',
                    width: 'unset',
                },
            },
        },
    },
}
