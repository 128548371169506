import React from 'react'
import { Icon, IconProps } from '@chakra-ui/icon'
import { useColorModeValue } from '@chakra-ui/color-mode'

export function DogIcon(props: IconProps) {
    return useColorModeValue(<Outline {...props} />, <Fill {...props} />)
}

const Outline: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M15.6657 69.8906H54.075V65.3718C54.075 62.8763 52.0517 60.8531 49.5563 60.8531V51.8157C53.7903 48.9926 56.3343 44.2399 56.3343 39.1506V20.1843H63.1124C66.8563 20.1843 69.8907 17.1496 69.8907 13.4062V8.88751H60.8532L54.075 2.10938H47.2969H47.2947C44.2986 2.10938 41.426 3.29935 39.3077 5.41744C37.1896 7.53568 36.0001 10.4085 36.0001 13.404V13.4062V13.4068C36.0001 19.1087 33.3679 24.4911 28.8672 27.992C27.5601 29.008 26.1797 30.0814 24.8241 31.1361C19.0456 35.6306 15.6657 42.5415 15.6657 49.8621V69.8906Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M42.7781 69.8906C42.7781 64.8993 38.7317 60.8531 33.7406 60.8531V58.5914C33.7406 56.1952 32.7883 53.8972 31.0937 52.2027C29.4003 50.5081 27.1014 49.5562 24.7053 49.5562H24.7032"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M47.2968 2.10937H47.2946C44.2987 2.10937 41.4259 3.29934 39.3076 5.41744C37.1895 7.53567 36 10.4085 36 13.404V19.0547C36 22.1743 38.5293 24.7032 41.6485 24.7032C44.7675 24.7032 47.2968 22.1743 47.2968 19.0547V11.1469"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M2.10938 63.1123C2.10938 64.9099 2.82333 66.6339 4.09416 67.905C5.36625 69.1763 7.09017 69.8904 8.8875 69.8904H15.6656V67.6311C15.6656 65.8333 14.9517 64.1094 13.6807 62.8383C12.4087 61.5671 10.6848 60.853 8.8875 60.853H2.10938V63.1123Z"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.5188 63.1125V51.8156"
            stroke="currentColor"
            strokeWidth="4.21875"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
)

const Fill: React.FC<IconProps> = props => (
    <Icon viewBox="0 0 72 72" fill="none" {...props}>
        <path
            d="M9.33728 58.7549C9.18842 58.7477 9.03845 58.7439 8.88739 58.7439H2.10938C0.944275 58.7439 0 59.6882 0 60.8533V63.1126C0 68.029 3.9798 72 8.88739 72H9.70038C9.46637 71.3397 9.33728 70.63 9.33728 69.8906V58.7549Z"
            fill="currentColor"
        />
        <path
            d="M39.5387 19.5222C42.6533 19.5222 45.1873 16.9882 45.1873 13.8735L45.1868 0H43.6498C38.2511 0 33.8906 4.36871 33.8906 9.75916V13.8735C33.8906 16.9882 36.4246 19.5222 39.5387 19.5222Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.7267 6.77802H69.8906C71.0557 6.77802 72 7.72229 71.9995 8.88739V13.4061C71.9995 18.3065 68.0131 22.2935 63.1121 22.2935H58.4434V39.1503C58.4434 44.571 55.9298 49.6285 51.6654 52.8986V59.0878C54.2895 59.9711 56.1841 62.454 56.1841 65.3719V69.8906C56.1841 71.0557 55.2398 72 54.0747 72H44.8874V69.8906C44.8874 67.3676 44.0436 65.0385 42.6253 63.1681C42.6255 63.1601 42.6261 63.1521 42.6266 63.1442C42.6273 63.1337 42.6281 63.1233 42.6281 63.1126V51.8159C42.6281 50.6508 41.6838 49.7065 40.5187 49.7065C39.3536 49.7065 38.4093 50.6508 38.4093 51.8159V59.7722C37.6029 59.3987 36.746 59.1163 35.85 58.9438V58.5912C35.85 52.502 30.9326 47.4467 24.7028 47.4467C23.5382 47.4467 22.595 48.3915 22.595 49.556C22.595 50.7211 23.5404 51.6654 24.7055 51.6654C28.4672 51.6654 31.6313 54.7064 31.6313 58.5912V60.8533C31.6313 62.0178 32.5756 62.9626 33.7407 62.9626C37.5606 62.9626 40.6686 66.0707 40.6686 69.8906V72H15.6654C14.5009 72 13.556 71.0557 13.556 69.8906V49.862C13.556 38.5104 20.5411 31.795 23.5289 29.4708C24.8846 28.4161 26.265 27.3428 27.5724 26.3265C29.5999 24.7495 31.1902 22.7675 32.2685 20.5367C34.0735 22.5044 36.6647 23.7409 39.5387 23.7409C44.9797 23.7409 49.4061 19.3145 49.4061 13.873L49.4055 0H54.0747C54.6345 0 55.1711 0.221924 55.5667 0.617981L61.7267 6.77802ZM58.6958 8.34787C58.6958 9.64457 57.6446 10.6958 56.3479 10.6958C55.0512 10.6958 54 9.64457 54 8.34787C54 7.05118 55.0512 6 56.3479 6C57.6446 6 58.6958 7.05118 58.6958 8.34787Z"
            fill="currentColor"
        />
    </Icon>
)
