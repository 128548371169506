import React from 'react'
import { Heading, HStack, Icon, Tag, Text } from '@chakra-ui/react'
import { FaFileAlt } from 'react-icons/fa'

import SharedStack from '~components/shared/wrappers/shared-stack'
import {
    AppointmentFieldsFragment,
    ClientFormFieldsFragment,
} from '~graphql/generated/graphql'
import OutlineBox from '~components/shared/wrappers/outline-box'
import { Link } from 'gatsby'
import { UserRoutes } from '~config/routes'
import { EditFormProvider } from '~components/engagement/forms/edit-form/edit-form-provider'

const APPOINTMENT_FORMS_SECTION_HEADER = 'Forms'

export default function AppointmentFormsSection({
    appointment,
}: {
    appointment: AppointmentFieldsFragment
}) {
    if (!appointment.clients_forms?.length) return null

    return (
        <SharedStack>
            <HStack spacing={4} px={2} py={1}>
                <Icon as={FaFileAlt} color="gray.900" />
                <Text fontWeight="semibold">
                    {APPOINTMENT_FORMS_SECTION_HEADER}
                </Text>
            </HStack>
            <SharedStack row flexWrap="wrap">
                {appointment.clients_forms?.map(form => (
                    <AppointmentFormCard key={form.id} clientForm={form} />
                ))}
            </SharedStack>
        </SharedStack>
    )
}

function AppointmentFormCard({
    clientForm,
}: {
    clientForm: ClientFormFieldsFragment
}) {
    const { form } = clientForm

    const isCompleted = !!clientForm.client_completed_at

    return (
        <Link to={UserRoutes.ViewFormResponse(clientForm.id)}>
            <OutlineBox w="200px">
                <SharedStack>
                    <Heading size="xs" noOfLines={1}>
                        <Text>{form.name}</Text>
                    </Heading>
                    <Tag size="sm" colorScheme={isCompleted ? 'green' : 'gray'}>
                        {isCompleted ? 'Completed' : 'Incomplete'}
                    </Tag>
                </SharedStack>
            </OutlineBox>
        </Link>
    )
}
