import React from 'react'
import {
    Drawer,
    ModalProps,
    DrawerBody,
    DrawerOverlay,
    DrawerHeader,
    DrawerContent,
} from '@chakra-ui/modal'
import { IconButton } from '@chakra-ui/button'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useBreakpointValue } from '@chakra-ui/media-query'

import EmptyState from '~components/shared/empty-state'
import DetailedClientInfo from './detailed-client-info'
import { ClientFieldsFragment } from '~graphql/generated/graphql'
import AsyncWrapper from '~components/shared/wrappers/async-wrapper'

interface Props extends Omit<ModalProps, 'children'> {
    client?: ClientFieldsFragment
    loading?: boolean
}

export default function ClientDetailsDrawer({
    client,
    loading,
    ...props
}: Props) {
    const modalSize = useBreakpointValue({ base: 'full', md: 'lg' })

    return (
        <Drawer
            placement="right"
            size={modalSize}
            blockScrollOnMount={false}
            {...props}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader
                    pb={4}
                    shadow="blue.md"
                    display="flex"
                    justifyContent="space-between"
                >
                    <IconButton
                        size="sm"
                        icon={<ChevronLeftIcon fontSize="xl" />}
                        variant="outline"
                        rounded="full"
                        aria-label="Close Modal"
                        onClick={props.onClose}
                    />
                </DrawerHeader>
                <DrawerBody px={{ base: 2, md: 8 }} py={{ base: 4, md: 6 }}>
                    <AsyncWrapper
                        loading={loading}
                        empty={!client}
                        emptyComponent={<EmptyState title="Client Not Found" />}
                    >
                        <DetailedClientInfo client={client!} />
                    </AsyncWrapper>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
