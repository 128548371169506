import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Icon } from '@chakra-ui/icons'
import { Image } from '@chakra-ui/image'
import { FaHospital } from 'react-icons/fa6'
import { BiLogIn, BiLogOut } from 'react-icons/bi'
import { Box, Text, HStack, Flex, Heading } from '@chakra-ui/layout'

import Logo from '~images/logo/logo.svg'
import { UserRoutes } from '~config/routes'
import SidebarTab from '../sidebar/sidebar-tab'
import { SidebarContent } from '../sidebar/sidebar'
import { useClinic } from '~contexts/clinic-context'
import { useUserContext } from '~config/user-context'

export default function MobileSidebar() {
    const { clinic } = useClinic()
    const [submenu, setSubmenu] = useState<string>('none')

    return (
        <Flex
            h="100dvh"
            flexDir="column"
            bg="gray.900"
            justifyContent="space-between"
        >
            <Box pt={4} pb={3} px={3}>
                <HStack spacing={4}>
                    <Image
                        src={Logo}
                        alt="Check Up Logo"
                        borderWidth={1}
                        borderColor="black"
                        h={9}
                    />
                    <Heading size="sm" fontWeight={300} color="brand.500">
                        Clinic Manager
                    </Heading>
                </HStack>
            </Box>
            <Box h="full" overflow="auto">
                {clinic && (
                    <SidebarTab
                        icon={FaHospital}
                        to={UserRoutes.SelectClinic}
                        label={'Switch Clinic'}
                        isOpen={true}
                        setSubmenu={setSubmenu}
                    />
                )}
                <SidebarContent
                    isOpen={true}
                    submenu={submenu}
                    setSubmenu={setSubmenu}
                />
            </Box>
            <AuthButton />
        </Flex>
    )
}

function AuthButton() {
    const { user, signOut } = useUserContext()

    return (
        <Flex
            w="100%"
            position="relative"
            textAlign={'left'}
            px={3}
            py={3}
            align="center"
            justify="flex-start"
            cursor="pointer"
            transition="all 0.2s"
            bg="gray.800"
            onClick={() => {
                if (user) {
                    signOut()
                } else {
                    navigate(UserRoutes.Auth)
                }
            }}
        >
            <Flex
                minW={8}
                boxSize={8}
                justify="center"
                align="center"
                ml={1}
                mr={2}
            >
                <Icon
                    as={user ? BiLogOut : BiLogIn}
                    boxSize="20px"
                    color={user ? 'red.400' : 'brand.500'}
                />
            </Flex>
            <Text color={user ? 'red.400' : 'brand.500'}>
                {user ? 'Sign Out' : 'Sign In'}
            </Text>
        </Flex>
    )
}
