import { Avatar, AvatarProps } from '@chakra-ui/react'
import React from 'react'
import { ClinicianFieldsFragment } from '~graphql/generated/graphql'

interface Props extends AvatarProps {
    clinician:
        | Pick<ClinicianFieldsFragment, 'name' | 'avatar_url' | 'initials'>
        | null
        | undefined
}

export default function ClinicianAvatar({ clinician, ...props }: Props) {
    return (
        <Avatar
            src={clinician?.avatar_url || ''}
            name={clinician?.name}
            getInitials={
                clinician?.initials ? () => clinician.initials || '' : undefined
            }
            {...props}
        />
    )
}
